import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../helpers/helper";

import ApiCalls from "../api/index";
import "../App.css";
import Select from "react-select";

export default function HomePage(props) {
  const [customerBoards, setCustomerBoards] = React.useState([]);
  const [Jobboards, setBoards] = React.useState([]);
  const [customerEmail, setCustomerEmail] = useState();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [alert, setAlert] = useState({ status: "", message: "" });

  const [customer, setCustomer] = useState({
    username: "",
    domain: "",
    password: "",
    confirm_password: "",
    api_key: "",
    subscription_end_date: "",
  });
  const params = useParams();

  const options = [
    { value: 1, label: "Chocolate" },
    { value: 2, label: "Strawberry" },
    { value: 3, label: "Vanilla" },
  ];

  const [boardOptions, setBoardOptions] = useState([]);

  const [selectedOption, setSelectedOption] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [tempData, setTempData] = useState([]);

  const userRole = localStorage.getItem("is_admin");
  useEffect(() => {
    getCustomer();
  }, []);

  useEffect(() => {
    //alert(1);
    //if(dataChanged === true){
    setSelectedOption(tempData);
    console.log("datachanged", tempData);
    //}
    //setSelectCity({ label: "Kolkata", value: "Kolkata" });
    //setCityOptions([{ label: "Kolkata", value: "Kolkata" }, { label: "New Delhi", value: "New Delhi" }, { label: "Chennai", value: "Chennai" }, { label: "Mumbai", value: "Mumbai" }])
  }, [tempData]);

  const getCustomer = async () => {
    let mounted = true;
    ApiCalls.get(`/api/customers/view/${params.customer_secret}`).then(
      (res) => {
        if (mounted) {
          const { customer, boards } = res.data;
          setCustomer({
            username: customer.username,
            domain: customer.domain,
            api_key: customer.api_key,
            subscription_end_date: formatDate(customer.subscription_end_date),
          });
          setCustomerEmail(customer.email);
          ApiCalls.get(`/api/job-boards`).then((res) => {
            console.log(res.data.data);
            if (res) {
              const output = res.data.data.map((_board) => {
                //const found =  boards.some(a => a.board_id === _board.id);
                //return found ? {..._board, isSelected: true } : _board; // return the ones with equal id
                return { value: _board.id, label: _board.name }; // return the ones with equal id
              });
              setBoardOptions(output);
              //setBoards(output);
              const outputSelectedBoards = boards.map((_board) => {
                //const found =  boards.some(a => a.board_id === _board.id);
                //return found ? {..._board, isSelected: true } : _board; // return the ones with equal id
                return { value: _board.board_id, label: _board.name }; // return the ones with equal id
              });
              setSelectedOption(outputSelectedBoards);
              //setDataChanged(true);
              //setTempData(outputSelectedBoards);
              console.log(outputSelectedBoards);
              forceUpdate();
            }
          });
          setCustomerBoards(boards);
        }
      }
    );
    return () => (mounted = false);
  };

  const handleChangeCustomer = (event, fieldName) => {
    if (fieldName === "username") {
      setCustomer((prevState) => {
        return { ...prevState, username: event.target.value };
      });
    }
    if (fieldName === "password") {
      setCustomer((prevState) => {
        return { ...prevState, password: event.target.value };
      });
    }
    if (fieldName === "confirm_password") {
      setCustomer((prevState) => {
        return { ...prevState, confirm_password: event.target.value };
      });
    }
    if (fieldName === "domain") {
      setCustomer((prevState) => {
        return { ...prevState, domain: event.target.value };
      });
    }
    if (fieldName === "api_key") {
      setCustomer((prevState) => {
        return { ...prevState, api_key: event.target.value };
      });
    }
    if (fieldName === "subscription_end_date") {
      setCustomer((prevState) => {
        return { ...prevState, subscription_end_date: event.target.value };
      });
    }
  };

  function handleChangeBoardCheckbox(event, board) {
    setBoards(
      Jobboards.map((_board) => {
        return _board.id === board.id
          ? { ..._board, isSelected: event.target.checked, api_key: "" }
          : _board;
      })
    );
  }

  const navigate = useNavigate();

  // save customer and boards access
  const updateCustomer = () => {
    console.log(customer);
    console.log(Jobboards);
    const outputSelectedBoards = selectedOption.map((_board) => {
      //const found =  boards.some(a => a.board_id === _board.id);
      //return found ? {..._board, isSelected: true } : _board; // return the ones with equal id
      return { id: _board.value, name: _board.label }; // return the ones with equal id
    });
    customer["boards"] = outputSelectedBoards;
    ApiCalls.post(`/api/customers/update/${params.customer_secret}`, customer)
      .then((res) => {
        if (
          localStorage.getItem("token") &&
          !localStorage.getItem("consumer_id")
        ) {
          navigate("/admin/customers");
        } else {
          navigate(`/admin/customers/${params.customer_secret}`);
        }
        setAlert({ status: "success", message: res.data.message });
      })
      .catch((err) => {
        setAlert({ status: "error", message: err.response.data.error });
      });
  };

  const subscriptionDatePickerClick = () => {
    const inputDateElement = document.querySelector("#subscription_end_date");
    inputDateElement.showPicker();
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="panel panel-primary">
            <div className="panel-heading">
              <h3 className="panel-title">
                {localStorage.getItem("token") &&
                !localStorage.getItem("consumer_id")
                  ? "Edit Customer "
                  : "Customer Profile"}
              </h3>
            </div>
            <div className="panel-body">
              <div
                style={{ display: "none" }}
                className={`
                      ${
                        alert.status == "success"
                          ? "alert alert-success show"
                          : ""
                      }
                      ${
                        alert.status == "error" ? "alert alert-danger show" : ""
                      }`}
              >
                <span>{alert.message}</span>
              </div>

              <form role="form">
                <div className="form-group">
                  <label htmlFor="customer_name">Customer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="customer_name"
                    value={customer.username}
                    onChange={(event) =>
                      handleChangeCustomer(event, "username")
                    }
                    placeholder="Customer Name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="customer_name">Customer Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="customer_name"
                    value={customerEmail}
                    disabled={true}
                    placeholder="Email"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="domain">Domain</label>
                  <input
                    type="text"
                    className="form-control"
                    id="domain"
                    placeholder="Domain"
                    value={customer.domain}
                    onChange={(event) => handleChangeCustomer(event, "domain")}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="api_key">API Access Key</label>
                  <input
                    type="text"
                    className="form-control"
                    id="api_key"
                    placeholder="API Access Key"
                    disabled={true}
                    value={customer.api_key}
                    onChange={(event) => handleChangeCustomer(event, "api_key")}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="domain">Subscription End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    style={{ maxWidth: "300px", cursor: "pointer" }}
                    disabled={
                      !localStorage.getItem("consumer_id") ? false : true
                    }
                    id="subscription_end_date"
                    placeholder="Subscription End Date"
                    value={customer.subscription_end_date}
                    onClick={subscriptionDatePickerClick}
                    onChange={(event) =>
                      handleChangeCustomer(event, "subscription_end_date")
                    }
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="domain">Job Boards</label>
                  <Select
                    isMulti={true}
                    value={selectedOption}
                    onChange={setSelectedOption}
                    options={boardOptions}
                  />

                  {Jobboards.map((board) => {
                    return (
                      <>
                        <span
                          style={{ width: "150px", display: "inline-block" }}
                        >
                          <input
                            type="checkbox"
                            checked={board.isSelected}
                            value={board.isSelected}
                            onChange={(event) =>
                              handleChangeBoardCheckbox(event, board)
                            }
                          />{" "}
                          <span style={{ textTransform: "capitalize" }}>
                            {board?.name}
                          </span>
                        </span>
                      </>
                    );
                  })}
                </div>
                {/*JSON.stringify(selectedOption)*/}
                <button
                  type="button"
                  onClick={updateCustomer}
                  className="btn btn-info waves-effect waves-light pull-right"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
