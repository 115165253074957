import React from "react";
import "./App.css";
import RootRoutes from "./routes/RootRoutes";
import ReactGA from 'react-ga';
import { toast } from "react-toastify";
import ErrorBoundary from "./ErrorBoundary";
import Loader from "./Loader";


const TRACKING_ID = "G-MSDCVRV497"; // YOUR_OWN_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

document.addEventListener('click', function (e) {
  toast?.dismiss();
});

function App() {
  if (process.env.REACT_APP_ORIGIN  === "neurodiversify") {
    document.documentElement.style.setProperty("--primary-color", "#055094", "important");
    document.documentElement.style.setProperty("--secondary-color", "#EAF4FF", "important");
    document.documentElement.style.setProperty("--font-family", "Lato", "important");
    document.documentElement.style.setProperty("--font-size-10", "12px", "important");
    document.documentElement.style.setProperty("--font-size-12", "14px", "important");
    document.documentElement.style.setProperty("--font-size-14", "16px", "important");
    document.documentElement.style.setProperty("--font-size-16", "18px", "important");
    document.documentElement.style.setProperty("--font-size-18", "20px", "important");
    document.documentElement.style.setProperty("--font-size-20", "24px", "important");
    document.documentElement.style.setProperty("--font-size-24", "26px", "important");
    document.documentElement.style.setProperty("--font-size-28", "30px", "important");
    document.documentElement.style.setProperty("--font-size-36", "38px", "important");
    document.documentElement.style.setProperty("--gray6", "#344054", "important");
    document.documentElement.style.setProperty("--gray7", "#344054", "important");
    document.documentElement.style.setProperty("--gray8", "#1D2939", "important");
    document.documentElement.style.setProperty("--gray9", "#1D2939", "important");
    document.documentElement.style.setProperty("--gray10", "#1D2939", "important");
    document.documentElement.style.setProperty("--gray11", "#1D2939", "important");
  } else {
    document.documentElement.style.setProperty("--primary-color", "#0071E3", "important");
    document.documentElement.style.setProperty("--secondary-color", "#E5f0FC", "important");
  }
  if (localStorage.getItem("primaryColor")) {
    document.documentElement.style.setProperty("--primary-color", localStorage.getItem("primaryColor"), "important");
  }
  if (localStorage.getItem("secondaryColor")) {
    document.documentElement.style.setProperty("--secondary-color", localStorage.getItem("secondaryColor"), "important");
  }
  if (localStorage.getItem("hoverButtonColor")) {
    document.documentElement.style.setProperty("--hover-button-color", localStorage.getItem("hoverButtonColor"), "important");
  }
  if (localStorage.getItem("hoverButtonTextColor")) {
    document.documentElement.style.setProperty("--hover-button-text-color", localStorage.getItem("hoverButtonTextColor"), "important");
  }
  return (
    <React.Suspense
      fallback={
        <>
          <Loader />
        </>
      }
    >
      <ErrorBoundary>
        <RootRoutes />
      </ErrorBoundary>
    </React.Suspense>
  );
}

export default App;
