import React from 'react';
import noJobstFound from "../assets/images/no-job-found.png";
import calenderIcon from "../assets/images/assessment-calendar.svg";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    Grid,
    Box,
    Select,
    MenuItem,
    Typography,
  } from "@mui/material";
import 'date-fns';
import DateFnsUtils from "@date-io/date-fns";
import c3 from "c3";
import { useEffect, useState, useRef } from "react";
import ApiCalls from "../api/index";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import { getBoardImage } from '../helpers/helper.js';
import Loader from "../Loader";
var fileDownload = require('js-file-download');

export default function JobBoardsPerformancePage() {
    const [jobBoardsPerformance, setJobBoardsPerformance] = useState([]);
    const [jobBoards, setJobBoards] = useState([]);
    const [toJobBoardsFirstRow, setTopJobBoardsFirstRow] = useState([]);
    const [toJobBoardsSecondRow, setTopJobBoardsSecondRow] = useState([]);
    const [toJobBoardsThirdRow, setTopJobBoardsThirdRow] = useState([]);
    const [jobBoard, setJobBoard] = useState('');
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [totalCount, setTotalCount] = useState(0);
    let appOrigin = localStorage.getItem("appOrigin");

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = `Job Board Performance | ${ appOrigin == 'H' ? ("Direct Health") : appOrigin == 'N' ? ("Neurodiversify") : ("SimplifyHire") }`;
        getJobPerformanceReportData();
        getBoards();
        window.scrollTo({ top: 0 });
    }, [jobBoard, startDate, endDate]);

    const ref = useRef();

    useEffect(() => {
        ref?.current?.scrollIntoView();
    }, [ref?.current]);

    const getJobPerformanceReportData = () => {
        setIsLoading(true);
        const st_date = moment(startDate).format('YYYY-MM-DD');
        const ed_date = moment(endDate).format('YYYY-MM-DD');
        let url = `/api/v1/reports/job-board/performance?job_board=${jobBoard}&start_date=${st_date}&end_date=${ed_date}`;
        ApiCalls.get(url)
        .then((res) => {
            const { data } = res.data;
            barDatagenerate(data.top_boards.slice(0, 10));
            setJobBoardsPerformance(data.listing.slice(0, 10));
            setTotalCount(data.top_boards.length);
            setTopJobBoardsFirstRow(data.top_boards.slice(0, 5));
            setTopJobBoardsSecondRow(data.top_boards.slice(5, 10));
            setTopJobBoardsThirdRow(data.top_boards.slice(10, 15));
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    };

    function handleDownloadCSV() {
        setIsLoading(true);
        const file_name = moment(startDate).format('MM-DD-YYYY') + " - " + moment(endDate).format('MM-DD-YYYY') + '.csv';
        let url = `/api/v1/reports/job-board/performance?job_board=${jobBoard}&start_date=${startDate}&end_date=${endDate}&csv=true`;
        ApiCalls.get(url)
        .then((res) => {
            fileDownload(res.data, file_name);
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }

    const chart_render = (name_arrays, performance_arrays) => {
        performance_arrays.unshift('Your Performance');
        // industry_performance_arrays.unshift('Industry Performance')
        var chart = c3.generate({
            bindto: '#chart',
            data: {
                columns: [
                    performance_arrays,
                    // industry_performance_arrays
                ],
                type: 'bar',
                colors: {
                    'Your Performance': '#445BC7',
                    // 'Industry Performance': '#b4bde9'
                }
            },
            bar: {
                width: 34
            },
            axis: {
                x: {
                    type: 'category',
                    categories: name_arrays,
                    tick: {
                        culling: false,
                        rotate: 50,
                        multiline: false
                    },
                },
                y: {
                    label: {
                        text: 'Performance',
                        position: 'outer-middle'
                    },
                    min: 0,
                    padding: {
                        top: 0,
                        bottom: 0
                    }
                }
            },
            legend: {
                show: true,
                position: 'inset',
                inset: {
                    anchor: 'top-right',
                    x: 10,
                    y: -60,
                    step: 2
                }
            },
            size: {
                height: 390
            },
            padding: {
                top: 100,
                right: window.innerWidth < 380 ? 40 : window.innerWidth <= 390 ? 40 : 100,
                left: window.innerWidth <= 380 ? 60 : 70,
            },
        });
    };

    const barDatagenerate = (data) => {
        let performance_arrays = [];
        // let industry_performance_arrays = [];
        let name_arrays = [];
        for (let i = 0; i < data.length; i++) {
            name_arrays.push(data[i].name);
        }
        for (let i = 0; i < data.length; i++) {
            performance_arrays.push(data[i].performance);
        }
        // for (let i = 0; i < data.length; i++) {
        //     industry_performance_arrays.push(data[i].industry_performance);
        // }
        chart_render(name_arrays, performance_arrays);
    };

    const getBoards = () => {
        ApiCalls.get(`/api/v1/job-boards/all`).then((res) => {
            const { data } = res.data;
            setJobBoards(data);
        }).catch(function (error) {
            console.log(error);
        });
    };

    const fromDatePickerClick = () => {
        const inputDateElement = document.querySelector('#datepicker-autoclose1');
        inputDateElement.showPicker();
    };
    const toDatePickerClick = () => {
        const inputDateElement = document.querySelector('#datepicker-autoclose2');
        inputDateElement.showPicker();
    };
    const start_calenderClick = () => {
        document.getElementById("datepicker1")?.click();
    };
    const end_calenderClick = () => {
        document.getElementById("datepicker2")?.click();
    };

    return (
        <>
            <div className="dash_content dash_per_content" ref={ref} id="job-board-performance">
                {isLoading && <Loader />}
                <div className="performance_head row">
                    <div className="col-md-10 performance_head_9">
                        <h4><span className='heading-text-color'>Job Board Performance</span></h4>
                        <div className="">
                            <Typography className="margin_right">
                                <Box >
                                    <Select className="all_boards"
                                        name="job_board"
                                        id="board-select"
                                        displayEmpty
                                        value={jobBoard}
                                        onChange={(event) => setJobBoard(event.target.value) }>
                                        <MenuItem value=''>All Job Boards</MenuItem>
                                        {jobBoards && jobBoards.map((jobBoard) => <MenuItem key={jobBoard.job_board_id} value={jobBoard.job_board_id} >{jobBoard.name}</MenuItem>)}
                                    </Select>
                                </Box>
                            </Typography>
                        </div>
                        <div>
                            <Typography className="margin_right">
                            <Typography className="job_boards_start_date align-item-center d-flex cursor-pointer st-date">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Box className="w-100">
                                        <DatePicker
                                            id="datepicker1"
                                            className="date_picker"
                                            value={startDate}
                                            onChange={ (newValue) => setStartDate(newValue)}
                                            format="MMM dd, yyyy"
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            emptyLabel=""
                                        />
                                    </Box>
                                    <Box>
                                        <img onClick={() => start_calenderClick()}
                                            style={{ width: "15px" }}
                                            src={calenderIcon}
                                            alt="img"
                                        />
                                    </Box>
                                </MuiPickersUtilsProvider>
                            </Typography>
                            </Typography>
                        </div>
                        <div>
                            <Typography className="margin_right">
                            <Typography className="job_boards_start_date align-item-center d-flex cursor-pointer st-date">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Box className="w-100">
                                        <DatePicker
                                            id="datepicker2"
                                            className="date_picker"
                                            value={endDate}
                                            onChange={ (newValue) => setEndDate(newValue) }
                                            format="MMM dd, yyyy"
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            emptyLabel=""
                                        />
                                    </Box>
                                    <Box>
                                        <img onClick={() => end_calenderClick()}
                                            style={{ width: "15px" }}
                                            src={calenderIcon}
                                            alt="img"
                                        />
                                    </Box>
                                </MuiPickersUtilsProvider>
                            </Typography>
                            </Typography>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <button onClick={ handleDownloadCSV } className="btn btn_download text-center outline-none" style={{ background: "var(--primary-color)", border: '1px solid var(--primary-color)' }}>
                            Download as CSV
                        </button>
                    </div>
                </div>
                <div className="performance_level">
                    <div id="chart" className="bar-chart p-inherit"></div>
                </div>
                <div className="table-wrapper">
                    <div className="row job-head">
                        <div className="col-md-9">
                            <div className="per_head">
                            </div>
                        </div>
                    </div>
                    <div className="job_listing_table">
                        <div className="row">
                            <div className="col-md-12 p-inherit">
                                <div className="table_inner table-responsive analytic-table">
                                <table className="table dash_table ">
                                        {totalCount === 0
                                            ?
                                                <tr className="tr_target">
                                                    <td colSpan={6} className="th_target center_heading file-upload" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>
                                                    <img src={noJobstFound} alt="img" />
                                                        <p className='no-data-found'style={{ paddingLeft: "30px" }}>No job boards found</p>
                                                    </td>
                                                </tr>
                                            :
                                            <thead>
                                                <tr className="table-head-color">
                                                    <th width="2%" scope="col"></th>
                                                    <th scope="col">Job Board Name</th>
                                                    <th scope="col">Jobs Posted</th>
                                                    <th scope="col" className="">Jobs Viewed</th>
                                                    <th scope="col" className="">Jobs Applied</th>
                                                    {/* <th scope="col">Saved</th>
                                                    <th scope="col" className="">Shared</th> */}
                                                    <th scope="col" className="">Performance (%)</th>
                                                    {/* <th scope="col">Industry Performance(%)</th> */}
                                                </tr>
                                            </thead>
                                            }
                                    <tbody>
                                    {jobBoardsPerformance.map( (boardPerformance, index) => {
                                        return (
                                                <>
                                                    <tr className="list-hover-color" key={index}>
                                                        <td  className="td_target">
                                                            <span className="target_span">{index+1}. &nbsp;</span>
                                                        </td>
                                                        <td className="td_target job-boards-list">
                                                            {boardPerformance.name}
                                                        </td>
                                                        <td className="td_target job-boards-list">{boardPerformance.posted}</td>
                                                        <td className="td_target job-boards-list">{boardPerformance.viewed}</td>
                                                        <td className="td_target job-boards-list">{boardPerformance.applied}</td>
                                                        {/* <td className="td_target">12</td>
                                                        <td className="td_target">12</td> */}
                                                        <td className="td_target job-boards-list">{boardPerformance.performance}</td>
                                                        {/* <td className="td_target">{boardPerformance.industry_performance}</td> */}
                                                    </tr>
                                                </>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {totalCount === 0
                    ?
                        ''
                    :
                    <div className="img_section" style={{ marginBottom: '10px' }}>
                        <h3>
                            Top Job Boards
                        </h3>
                        <div className="row img_section_row_1">
                            {toJobBoardsFirstRow.map( (board, index) => {
                                return (
                                    <>
                                        <div className="col-md-2 img_sec_2 text-overlap logos_alignment" key={index}>
                                            {/* <span style={{ fontSize : "14px"}}>{index+1}.</span> */}
                                            {getBoardImage(board.slug)}
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                        <div className="row img_section_row_2">
                            {toJobBoardsSecondRow.map( (board, index) => {
                                return (
                                    <>
                                        <div className="col-md-2 img_sec_2 text-overlap logos_alignment" key={index}>
                                            {/* <span style={{ fontSize : "14px"}}>{index+6}.</span> */}
                                            {getBoardImage(board.slug)}
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                        <div className="row img_section_row_3">
                            {toJobBoardsThirdRow.map( (board, index) => {
                                return (
                                    <>
                                        <div className="col-md-2 img_sec_3 text-overlap logos_alignment" key={index}>
                                            {/* <span style={{ fontSize : "14px"}}>{index+6}.</span> */}
                                            {getBoardImage(board.slug)}
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                    }
                </div>
            </div>
        </>
    );
}
