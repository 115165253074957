import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";

import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import BeanBag from "../../assets/images/Beanbag.svg";

import TwitterIconsvg from "../../assets/images/twitter-icon.svg";
import FacebookIconsvg from "../../assets/images/FacebookIconFooter.svg";
import LinkedIconsvg from "../../assets/images/LinkedinIconFooter.svg";

export default function NewFooter(props) {
  let baseURL = process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN;

  const termToUseLink = () => {
    window.open(baseURL + `/terms-and-policy`, "_blank", "noopener,noreferrer");
  };

  const [date, setDate] = useState();

  const getYear = () => setDate(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);

  const termsOfuse = (e) => {
    e.preventDefault();
    window.open("../terms-and-policy");
  };

  return (
    <>
      <Grid className="">
        <Grid
          sx={{ py: 3 }}
          className="posting-job-align"
          style={{ background: "F9FAFB" }}
        >
          <Grid className="pb-100" style={{ background: "F9FAFB" }}>
            <Grid
              container
              item
              lg={12}
              md={12}
              xs={12}
              sx={{ px: 2 }}
              spacing={0}
              className="footerNav mt-10 justify-center launch-align body-container w-100 "
            >
              <Grid
                item
                lg={4}
                md={4}
                xs={12}
                sx={{ px: 0 }}
                className="pl-0 pt-0"
              >
                <img
                  onClick={(e) =>{
                    // props.homeRef?.current?.scrollIntoView({
                    //   behavior: "smooth",
                    // })
                    e.preventDefault();
                    window.location.replace("/#home");
                  }}
                  src={BeanBag}
                  alt="Logo"
                />
                <Typography className="f-20 font-weight-400 footer-subtext p-l-10">
                  A place where you can post jobs like a Novice, and manage like
                  a Pro
                </Typography>
                <Box className="mt-50 p-l-10">
                  <a
                    href="https://www.linkedin.com/company/11181300/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="LinkedIn link"
                  >
                    <img
                      src={LinkedIconsvg}
                      className="footerIcon"
                      width="35"
                      height="35"
                      aria-label="LinkedIn icon"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/simplifyworkforce/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook link"
                  >
                    <img
                      src={FacebookIconsvg}
                      className="footerIcon"
                      width="35"
                      height="35"
                      aria-label="Facebook icon"
                    />
                  </a>

                  <a
                    href="https://twitter.com/simplifychatter"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Twitter link"
                  >
                    <img
                      src={TwitterIconsvg}
                      className="footerIcon pb-5"
                      width="35"
                      height="35"
                      aria-label="Twitter icon"
                    />
                  </a>
                  {/* <GoogleIcon style={styles.footerIcon} />*/}
                </Box>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                xs={12}
                sx={{ px: 20, marginTop: 6 }}
                className="footer-block"
              >
                <Typography
                  className="f-20 font-weight-600 text-black"
                  variant="strong"
                >
                  Menu
                </Typography>
                <Typography
                  className="f-18 font-weight-400 mt-20 text-black footer-subtext cursor-hover decorationcolor"
                  onClick={(e) => {
                    // props.homeRef?.current?.scrollIntoView({ behavior: "smooth" })}
                    e.preventDefault();
                    window.location.replace("/#home");
                  }}
                >
                  Home
                </Typography>
                <Typography
                  className="f-18 font-weight-400 mt-20 text-black footer-subtext cursor-hover decorationcolor"
                  onClick={(e) =>{
                    // props.howItWorksRef?.current?.scrollIntoView({
                    //   behavior: "smooth",
                    // })
                    e.preventDefault();
                    window.location.replace("/#howItWorks");
                  }}
                >
                  How It Works
                </Typography>
                <Typography
                  className="f-18 font-weight-400 mt-20 text-black footer-subtext cursor-hover decorationcolor"
                  onClick={(e) =>{
                    // props.ourClients?.current?.scrollIntoView({
                    //   behavior: "smooth",
                    // })
                    e.preventDefault();
                    window.location.replace("/#ourClients");
                  }}
                >
                  Our Clients
                </Typography>
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                xs={12}
                sx={{ px: 5, marginTop: 6 }}
                className="footer-block"
              >
                <Typography
                  className="f-20 font-weight-600 text-black"
                  variant="strong"
                >
                  Legal
                </Typography>
                <Typography
                  className="f-18 font-weight-400 mt-20 text-black footer-subtext cursor-hover decorationcolor"
                  onClick={termsOfuse}
                >
                  Terms of Use
                </Typography>
                <Typography
                  className="f-18 font-weight-400 mt-20 text-black footer-subtext cursor-hover decorationcolor"
                  onClick={termsOfuse}
                >
                  Privacy Policy
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
