import React from 'react';
import Toggle from 'react-styled-toggle';
import { useEffect, useState, useRef } from "react";
import ApiCalls from "../api/index";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '@mui/material/Pagination';
import ClearIcon from "@mui/icons-material/Clear";
import PaginationItem from "@mui/material/PaginationItem";

import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import noJobsFound from "../assets/images/no-job-found.png";
import { ReactComponent as ArrowBackIcon } from "../../src/assets/images/arrow-left.svg";
import { ReactComponent as ArrowForwardIcon } from "../../src/assets/images/arrow-right.svg";
import { ReactComponent as SearchIcon } from "../../src/assets/images/search-icon.svg";

import Loader from "../Loader";
import { Switch, InputAdornment, Tooltip, Link } from "@mui/material";
import {
    Button,
    MenuItem,
    Select,
    Typography, Stack
  } from "@mui/material";
import Modal from "@mui/material/Modal";
import InfoIcon from "@mui/icons-material/Info";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from 'copy-to-clipboard';
import { success } from "../helpers/message-box";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
        color: "#aaa"
    }
}));
const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
};

export default function BoardsAccessesPage() {
    const [jobBoards, setJobBoards] = useState([]);
    const [boardsAccesses, setBoardsAccesses] = useState([]);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [job_count, setJob_count] = useState('');
    const [jobLimit, setJobLimit] = useState('');
    const [boardAccessStatus, setBoardAccessStatus] = useState('A');
    const prevStatus = usePrevious(boardAccessStatus);
    const [jobBoardId, setJobBoardId] = useState('');
    const [modeOfOperation, setModeOfOperation] = useState('');
    const [accessId, setAccessId] = useState('');
    const [inactivestatusId, setInactivestatusId] = useState('');
    const [searchJobBoard, setSearchJobBoard] = useState('');

    const [nameError, setNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [apiKeyError, setApiKeyError] = useState('');

    const [pageNumber, setPageNumber] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [rowsperpage, setRowsPerPage] = React.useState(10);

    const [popup, setPopup] = useState({ show: false,  access_id: null });

    const [open, setOpen] = useState(false);
    const [openInactiveDialog, setOpenInactiveDialog] = useState(false);
    const [openConfirmBoxDialog, setConfirmBoxDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const handleNewOpen = () => {
        setOpen(true);
        openBoardModal();
    };
    const handleEditOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setIsEditJobBoard(false);
    };
    const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

    const [isLoading, setIsLoading] = useState(false);
    let appOrigin = localStorage.getItem("appOrigin");

    useEffect(() => {
        document.title = `Job Boards | ${ appOrigin == 'H' ? ("Direct Health") : appOrigin == 'N' ? ("Neurodiversify") : ("SimplifyHire") }`;
        getBoardsAccesses();
        getBoards();
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    const ref = useRef();

    useEffect(() => {
        ref?.current?.scrollIntoView();
    }, [ref?.current, pageNumber]);

    const openBoardModal = () => {
        setNameError('');
        setPasswordError('');
        setApiKeyError('');
        setUserName('');
        setPassword('');
        setApiKey('');
        setJobLimit('');
        setJobBoardId('');
        setModeOfOperation('');
        setAccessId('');
    };

    const getBoards = () => {
        setIsLoading(true);
        ApiCalls.get(`/api/v1/job-boards/all`).then((res) => {
            const { data } = res.data;
            setJobBoards(data);
            setIsLoading(false);
        }).catch(function (error) {
            console.log(error);
            setIsLoading(false);
        });
    };

    const getBoardsAccesses = () => {
        setIsLoading(true);
        ApiCalls.get(`/api/v1/job-boards-access?search=${searchJobBoard}&page=${pageNumber}`).then((res) => {
            const { data } = res.data.data;
            setBoardsAccesses(data);

            setPageNumber(res.data.data.page_number);
            setTotalCount(res.data.data.total_count);
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    };

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        }, [value]);
        return ref.current;
      }

    const setInactivemodel =()=>{
        setOpenInactiveDialog(false);
        var formData = new FormData();

        formData.append("status", inactivestatusId.status==='A'?'I':'A');
        ApiCalls.post(`/api/v1/job-boards-access/update/status/${inactivestatusId.id}`, formData )
        .then((res) => {
            setBoardsAccesses(
            boardsAccesses.map((_boardAccess) => {
                return _boardAccess.access_id === inactivestatusId.id ? { ..._boardAccess, status: inactivestatusId.status==='A'?'I':'A' } : _boardAccess;
            })
            );
            toast.success("Job board status updated successfully.");
        }).catch(function (error) {
            toast.success(error.data.message);
        });
    };

    const clearSearch = () => {
        setIsLoading(true);
        setSearchJobBoard('');
        ApiCalls.get(`/api/v1/job-boards-access`).then((res) => {
            const { data } = res.data.data;
            setBoardsAccesses(data);

           setPageNumber(res.data.data.page_number);
           setTotalCount(res.data.data.total_count);
           setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    };

    const saveBoardAccess = (e) => {
        setIsLoading(true);
        e.preventDefault();

        var formData = new FormData();
        formData.append("user_name", userName);
        formData.append("password", password);
        formData.append("api_key", apiKey);
        formData.append("job_limit", jobLimit);
        formData.append("job_board_id", jobBoardId);
        formData.append("mode_of_operation", modeOfOperation);
        formData.append("status", boardAccessStatus);
        formData.append("consumer_id", localStorage.getItem('consumer_id'));

        ApiCalls.post(`/api/v1/job-boards-access`, formData )
        .then((res) => {
            handleClose();
            toast.success("Job board created successfully.");
            setBoardsAccesses([...boardsAccesses, res.data.data]);
            getBoardsAccessesFirstPage();
            // getBoardsAccesses();
            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false);
            if (err.response.data.data.non_field_errors[0]) {
                toast.error(<span style={{ fontSize: "14px" }}>This job board is already added</span>);
            }
        });
    };
    const [iseditjobboard, setIsEditJobBoard] = useState(false);

    const editBoardAccess = async (boardAccess) => {
        setIsLoading(true);
        setIsEditJobBoard(true);
        await ApiCalls.get(`/api/v1/job-boards-access/${boardAccess.access_id}`)
        .then((res) => {
            handleEditOpen();
            setNameError('');
            setPasswordError('');
            setApiKeyError('');
            setJobLimit('');

            const { data } = res.data;

            setAccessId(boardAccess.access_id);
            setUserName(data.user_name ? data.user_name : '');
            setPassword(data.password ? data.password : '');
            setApiKey(data.api_key ? data.api_key : '');
            setJobLimit(data.job_limit ? data.job_limit : 0);
            setJob_count(boardAccess.job_count);
            setJobBoardId(data.job_board_id);
            setModeOfOperation(data.mode_of_operation);
            setBoardAccessStatus(data.status);
            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false);
            toast.error(err);
        });
    };

    const updateBoardAccess = (e) => {
        setIsLoading(true);
        e.preventDefault();

        var formData = new FormData();
        formData.append("user_name", userName ? userName : '');
        formData.append("password", password ? password : '');
        formData.append("api_key", apiKey ? apiKey : '');
        formData.append("job_limit", jobLimit ? jobLimit : 0);
        formData.append("job_board_id", jobBoardId);
        formData.append("mode_of_operation", modeOfOperation);
        formData.append("status", boardAccessStatus);

        ApiCalls.put(`/api/v1/job-boards-access/${accessId}`, formData)
        .then((res) => {
            handleClose();
            toast.success("Job board updated successfully.");
            // setBoardsAccesses([...boardsAccesses, res.data.data])
            getBoardsAccesses();
            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false);
            if (err.response.data.data.non_field_errors[0]) {
                toast.error('This job board is already added');
            }
        });
    };

    const openInactiveConfirmBox = (e) => {
        handleClose();
        setConfirmBoxDialog(true);
    };

    const updateFormData = () => {
        setIsLoading(true);

        var formData = new FormData();
        formData.append("user_name", userName ? userName : '');
        formData.append("password", password ? password : '');
        formData.append("api_key", apiKey ? apiKey : '');
        formData.append("job_limit", jobLimit ? jobLimit : 0);
        formData.append("job_board_id", jobBoardId);
        formData.append("mode_of_operation", modeOfOperation);
        formData.append("status", boardAccessStatus);

        ApiCalls.put(`/api/v1/job-boards-access/${accessId}`, formData)
        .then((res) => {
            handleClose();
            toast.success("Job board updated successfully.");
            setConfirmBoxDialog(false);
            getBoardsAccesses();
            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false);
            if (err.response.data.data.non_field_errors[0]) {
                toast.error('This job board is already added');
            }
        });
    };

    const handleDeleteBoardAccess = (access_id) => {
        setOpenConfirmDialog(true);
        setPopup({
          show: true,
          access_id: access_id
        });
    };

    const getBoardsAccessesFirstPage = () => {
        setIsLoading(true);
        ApiCalls.get(`/api/v1/job-boards-access?search=${searchJobBoard}&page=${1}`).then((res) => {
            const { data } = res.data.data;
            setBoardsAccesses(data);

            setPageNumber(res.data.data.page_number);
            setTotalCount(res.data.data.total_count);
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    };

    const handleDeleteTrue = () => {
        if (popup.show && popup.access_id) {
            setIsLoading(true);
            ApiCalls.delete(`/api/v1/job-boards-access/${popup.access_id}`)
            .then((res) => {
                setBoardsAccesses(boardsAccesses.filter( (boardAccess) => boardAccess.access_id !== popup.access_id));
                handleCloseConfirmDialog();
                // getBoardsAccesses();
                setPopup({
                    show: false,
                    access_id: null,
                });
                toast.success("Job board deleted successfully.");
                setIsLoading(false);
            }).catch(function (error) {
                setIsLoading(false);
                console.log(error);
            });
        }
    };

    const handleDeleteFalse = () => {
        handleCloseConfirmDialog();
        setPopup({
          show: false,
          access_id: null,
        });
    };

    function SubmitButton() {
        if (!accessId) {
            if (jobBoardId && jobBoardId !="default" && modeOfOperation && modeOfOperation !="default" && nameError === '' && passwordError === '' && apiKeyError === '') {
                return <button type="submit" onClick={saveBoardAccess} className="btn model-post-btn btn-primary outline-none">Add Job Board</button>;
            } else {
                return <button type="submit" style={{ pointerEvents: 'none' }} disabled onClick={saveBoardAccess} className="btn model-post-disable-btn btn-disable outline-none">Add Job Board</button>;
            }
        }
        if (accessId) {
            if (jobBoardId && modeOfOperation !="default" && nameError === '' && passwordError === '' && apiKeyError === '') {
                if (prevStatus == "A" && boardAccessStatus =="I" && job_count>=1) {
                    return <button type="submit" onClick={openInactiveConfirmBox} className="btn model-post-btn btn-primary outline-none">Update Job Board</button>;
                  } else {
                    return <button type="submit" onClick={updateBoardAccess} className="btn model-post-btn btn-primary outline-none">Update Job Board</button>;
                }
            } else {
                return <button disabled style={{ pointerEvents: 'none' }} type="submit" onClick={updateBoardAccess} className="btn model-post-disable-btn btn-disable outline-none">Update Job Board</button>;
            }
        }
    };

    const handlePageChange = (event, value) => {
        setIsLoading(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
        ApiCalls.get(`/api/v1/job-boards-access?search=${searchJobBoard}&page=${value}`).then((res) => {
            const { data } = res.data.data;
            setBoardsAccesses(data);

            setPageNumber(value);
            setTotalCount(res.data.data.total_count);
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    };

    const handleActivateDeactivateBoardAccess = (access_id, status, job_count, job_limit) => {
        setInactivestatusId({
            id: access_id,
            status: status
        });
        if (status=="A" && job_count>=1) {
            setOpenInactiveDialog(true);
            return;
        }

        var formData = new FormData();
        formData.append("status", status==='A'?'I':'A');
        ApiCalls.post(`/api/v1/job-boards-access/update/status/${access_id}`, formData )
        .then((res) => {
            setBoardsAccesses(
            boardsAccesses.map((_boardAccess) => {
                return _boardAccess.access_id === access_id ? { ..._boardAccess,  status: status==='A'?'I':'A' } : _boardAccess;
            })
            );
            toast.success("Job board status updated successfully.");
        }).catch(function (error) {
            toast.success(error.data.message);
        });
    };

    function visible_count(boardAccess) {
        if (boardAccess.job_count >= boardAccess.job_limit) {
          return "eql_jobs";
        } else {
          return "lte_jobs";
        }
    }

    function getStatusText(getStatus) {
        if (getStatus === 'A') {
          return <span style={{ color: "var(--primary-color)" }}>Active</span>;
        } else if (getStatus === 'I') {
          return <span style={{ color: "#AFAFAF" }}>Inactive</span>;
        }
    }

    function getStatusBool(getStatus) {
        if (getStatus === 'I') {
          return false;
        } else if (getStatus === 'A') {
          return true;
        }
    }

    const modeOfOperationOptions = [
        { value: 'R', label: 'Redirect' },
        { value: 'A', label: 'Apply' },
    ];

    const getBoardXML = (board) => {
        let baseURL = process.env.REACT_APP_BASE_URL;
        window.open(`${baseURL}/api/v1/jobs/${localStorage.getItem('api_access_key')}/${board}/xml`, '_blank', 'noopener,noreferrer');
    };

    function getBoardTypeSTatus(type) {
        if (type === "X") {
          return "XML";
        }
    }

    const handleEnterKey = (e) => {
        setSearchJobBoard(e.target.value);
        if (e.key === "Enter") {
            e.preventDefault();
            getBoardsAccesses();
        }
        if (e.type === "input" && e.target.value === "") {
            e.preventDefault();
            clearSearch();
          }
    };

    const handleChangeBoardData = (e, fieldName) => {
        if (fieldName === 'user_name') {
            console.log(e.target.value.length);
            if (e.target.value.length > 128) {
                setNameError('Exceeded maximum character length of 128');
            } else {
                setUserName(e.target.value);
                setNameError('');
            }
        }
        if (fieldName === 'password') {
            console.log(e.target.value.length);
            if (e.target.value.length > 128) {
                setPasswordError('Exceeded maximum character length of 128');
            } else {
                setPassword(e.target.value);
                setPasswordError('');
            }
        }
        if (fieldName === 'api_key') {
            console.log(e.target.value.length);
            if (e.target.value.length > 256) {
                setApiKeyError('Exceeded maximum character length of 256');
            } else {
                setApiKey(e.target.value);
                setApiKeyError('');
            }
        }
    };

    const copyToClipboard = (url) => {
        copy(url);
        setTimeout(()=>{
            success("Link is Copied.");
        }, 100);
    };

    return (
        <>
            <div className="dash_content" ref={ ref } style={{ height: '100vh' }} id="configure-job-board">
                {isLoading && <Loader />}
                <div className="table-wrapper">
                    <div className="row job-head">
                        <div className="col-md-2">
                            <h4>
                                <span className='heading-text-color'>All Job Boards</span>
                            </h4>
                        </div>
                        <div className="col-md-10">
                            <div className="input_right board-assess-align">
                            <Input
                                disableUnderline
                                onKeyPress={handleEnterKey}
                                onInput={handleEnterKey}
                                onKeyDown={handleEnterKey}
                                value={searchJobBoard}
                                type="text"
                                placeholder='Search job boards'
                                className="job_search inputcontrast "
                                endAdornment={
                                <InputAdornment position="end">
                                        {( searchJobBoard !== "" &&
                                        <ClearIcon
                                        onClick={() => {
                                            clearSearch();
                                        }}
                                        tabIndex={-1}
                                        className='cursor-hover mr-20'
                                        aria-label="Clear icon"
                                        />
                                        )}
                                        <Typography className="search-byId">
                                        <Grid
                                        className="js-background border-radius-6 d-flex align-center cursor-hover p-13"
                                        onClick={() => {
                                            getBoardsAccesses();
                                        }}
                                        aria-label="Search icon"
                                        disableRipple="true"
                                        >
                                        <SearchIcon
                                            width="20px"
                                            aria-label="search icon"
                                            className='svg-icon'
                                        />
                                        </Grid>
                                        </Typography>
                                </InputAdornment>
                                }
                                />
                                <button tabIndex="0"
                                    onKeyPress={handleNewOpen}
                                    onClick={handleNewOpen}
                                    className="btn_add_job add-job-board" style={{ backgroundColor: "var(--primary-color)" }}>Add Job Board</button>
                        </div>
                        </div>
                    </div>
                    <div className="job_listing_table">
                        <div className="row">
                            <div className="col-md-12 p-inherit">
                                <div className="table_inner table-responsive">
                                <table className="table dash_table all_job_boards">
                                    <thead>
                                        {totalCount === 0
                                        ?
                                            ''
                                        :
                                            <tr className="tr_target">
                                                <th className="heading-job">Job Board Name</th>
                                                <th className="">Job Posting Limit</th>
                                                {/* <th className="heading-job">User Name</th> */}
                                                {/* <th className="heading-job">Password</th> */}
                                                {/* <th className="heading-job">Api Key</th> */}
                                                <th>Status</th>
                                                <th>XML File Path</th>
                                                <th className="center_heading">Action</th>
                                            </tr>
                                        }
                                    </thead>
                                    <tbody>
                                        {totalCount === 0
                                        ?
                                            <div className="dash_content center_heading">
                                                <Typography className="text-center">
                                                    <img width="295px" height="295px" className="profile-video-thumbnail" src={noJobsFound} alt="img"/>
                                                    <p className='no-data-found'>No job boards found</p>
                                                </Typography>
                                            </div>
                                        :
                                            boardsAccesses.map( (boardAccess, index) => {
                                                return (
                                                    <>
                                                        <tr className="tr_target list-hover-color" key={index}>
                                                            <td  className="th_target job_board pt-30 pl-10" style={{ width: "25%" }}>
                                                                <span className="target_span">{((pageNumber - 1) * 10) + index + 1}. &nbsp;</span> { boardAccess.job_board_name }
                                                            </td>
                                                            <td  className="th_target pt-30 job_limit ml-4" style={{ width: "15%" }}>
                                                                {boardAccess.job_count == boardAccess.job_limit || boardAccess.job_limit == 0
                                                                    ?
                                                                    <Tooltip
                                                                        title={
                                                                            <div className="f-12">
                                                                                {boardAccess.job_limit == 0 ? "No limit is set": "Job slot is full"}
                                                                            </div>
                                                                        }
                                                                        placement="bottom"
                                                                    >
                                                                        <span
                                                                            className={visible_count(boardAccess)}>
                                                                            {boardAccess.job_count}/{boardAccess.job_limit}
                                                                        </span>
                                                                    </Tooltip>
                                                                    :
                                                                    <span
                                                                        className={visible_count(boardAccess)}>
                                                                        {boardAccess.job_count}/{boardAccess.job_limit}
                                                                    </span>
                                                                }
                                                            </td>
                                                            {/* <td  className="th_target">
                                                                <span className="target_span">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> { boardAccess.user_name ? boardAccess.user_name : ';' }
                                                            </td> */}
                                                            {/* <td  className="th_target">
                                                                <span className="target_span">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> { boardAccess.password ? boardAccess.password : ';' }
                                                            </td> */}
                                                            {/* <td  className="th_target">
                                                                <span className="target_span">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> { boardAccess.api_key ? boardAccess.api_key : ';' }
                                                            </td> */}
                                                            <td className="center_heading td_target board-access-switch ml-4 d-flex job-board-status-toggle">
                                                                {/* <Toggle
                                                                    onChange={() => handleActivateDeactivateBoardAccess(boardAccess.access_id, boardAccess.status)}
                                                                    checked={getStatusBool(boardAccess.status)}
                                                                    width={48}
                                                                    height={20}
                                                                    sliderWidth={18}
                                                                    sliderHeight={18}
                                                                    translate={19}
                                                                    backgroundColorChecked="#445BC7"
                                                                    backgroundColorUnchecked="#AFAFAF"
                                                                    labelRight={getStatusText(boardAccess.status)}
                                                                    sliderLeft = {0}
                                                                    style={{left :"0px"}}
                                                                /> */}
                                                                <div className="switch-case-section">
                                                                    <FormControlLabel control={<Switch defaultChecked />}
                                                                        label={getStatusText(boardAccess.status)}
                                                                        checked={getStatusBool(boardAccess.status)}
                                                                        onChange={() => handleActivateDeactivateBoardAccess(boardAccess.access_id, boardAccess.status, boardAccess.job_count, boardAccess.jobLimit)}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="th_target pt-30 ml-4 pb-20" style={{ width: "33%" }}>
                                                                {boardAccess.xml_path ?
                                                                <span className='d-flex'>
                                                                <span className="target_span">
                                                                    <a
                                                                        className='primary-color cursor-pointer'
                                                                        rel="noreferrer"
                                                                        target='_blank'
                                                                        href={boardAccess.xml_path}
                                                                        style={{ wordBreak: "break-all" }}
                                                                    >
                                                                        {boardAccess.xml_path}
                                                                    </a>
                                                                </span>
                                                                <span className='ml-10'>
                                                                <Link
                                                                    onClick={()=>copyToClipboard(boardAccess.xml_path)}
                                                                    onKeyPress={()=>copyToClipboard(boardAccess.xml_path)}
                                                                    className="cursor-hover d-flex"
                                                                >
                                                                    <Tooltip
                                                                        placement="right"
                                                                        title="Copy URL"
                                                                    >
                                                                        <ContentCopyIcon
                                                                            style={{ width: "20px", marginTop: "3px" }}
                                                                            className="gray7"
                                                                        />
                                                                    </Tooltip>
                                                                </Link>
                                                                </span>
                                                                </span>
                                                                :
                                                                <span className='ml-4'>-</span>}
                                                            </td>
                                                            <td className="center_heading td_target edit-delete-align actions-d job-board-actions">
                                                                <a className='cursor-pointer'><i><img src="/beanbag-assets/img/edit-new-icon.svg" onClick={ () => editBoardAccess(boardAccess) } /></i></a>
                                                                <a className='cursor-pointer'><i><img src="/beanbag-assets/img/trash_black.svg"  onClick={() => {
                                                                    handleDeleteBoardAccess(boardAccess.access_id);
                                                                    }} className="black_img actions-fr" /></i></a>
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                     {totalCount === 0
                        ?
                        ''
                        :
                        <div className="col-md-6 p-inherit">
                           <p className="s-p-l-ten">Showing <span className="per-page-count-box">10</span> items per page</p>
                        </div>
                     }
                     {totalCount >= 1 &&
                    <div className="col-md-6">
                       <div className="pagination pagenation-design">
                        <>
                        <Grid
                        item
                        lg={6}
                        md={6}
                        xs={12}
                        className="justify-end d-flex pagenation-table navbarUser pt-8"
                        >
                        <Stack spacing={2} className="pagenation-joblist">
                            <Pagination
                            shape="rounded"
                            // color="primary"
                            rowsperpage={rowsperpage}
                            count={
                                Math.floor(totalCount / 10) +
                                (totalCount % 10 === 0 ? 0 : 1)
                            }
                            // page={page}
                            onChange={handlePageChange}
                            renderItem={(item) => (
                                <PaginationItem
                                components={{
                                    last: (props) => (
                                    <button
                                        className="btn primary-button profileButton"
                                        {...props}
                                    >
                                        Last
                                    </button>
                                    ),

                                    next: (props) => (
                                    <Box
                                        className="d-flex align-center cursor-hover"
                                        {...props}
                                    >
                                        <Box className="gray7 f-14 align-center font-weight-500 ml-10 mr-20">
                                        Next
                                        </Box>
                                        <ArrowForwardIcon
                                        height="13px"
                                        width="13px"
                                        />{" "}
                                    </Box>
                                    ),

                                    first: (props) => (
                                    <Box
                                        className="d-flex align-center cursor-hover"
                                        {...props}
                                    >
                                        <ArrowBackIcon
                                        height="13px"
                                        width="13px"
                                        />{" "}
                                        <Box className="gray7 f-14 align-center font-weight-500 ml-10 mr-20">
                                        Previous
                                        </Box>
                                    </Box>
                                    ),

                                    previous: (props) => (
                                    <Box
                                        className="d-flex align-center cursor-hover"
                                        {...props}
                                    >
                                        <ArrowBackIcon
                                        height="13px"
                                        width="13px"
                                        />{" "}
                                        <Box className="gray7 f-14 font-weight-500 ml-10 mr-20">
                                        Previous
                                        </Box>
                                    </Box>
                                    ),
                                }}
                                {...item}
                                />
                            )}
                            showFirstButton={false}
                            showLastButton={false}
                            />
                        </Stack>
                        </Grid>
                        </>
                    </div>
                    </div>

                     }
                    </div>
                </div>
            </div>
            <div className="modal fade post-job-model post-job-model_3" id="exampleModal5" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modal-content_ans">
                        <div className="modal-header modal_head" style={{ padding: "30px 15px 0px 15px" }}>
                            {!accessId && <h5>Add Job Board</h5>}
                            {accessId && <h5>Update Job Board</h5>}
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form role='form'>
                            <div className="modal-body mid_modal mid_3">
                                <label>Select Job Board</label>
                                <select className='form-control access-board-select cursor-pointer color-black' name="job_board_id" id="job_board_id" value={jobBoardId} onChange={(event) => setJobBoardId(event.target.value) }>
                                    <option value=''>Select Job Board</option>
                                    {jobBoards && jobBoards.map((jobBoard) => <option key={jobBoard.job_board_id}value={jobBoard.job_board_id} >{jobBoard.name}</option>)}
                                </select>
                                <label>User Name</label>
                                <input
                                    type="text"
                                    placeholder="Enter User Name"
                                    name="user_name"
                                    id="user_name"
                                    value={userName}
                                    onChange={(event) => setUserName(event.target.value) } />
                                <label>Password</label>
                                <input
                                    type="password"
                                    placeholder="Enter Password"
                                    name="password"
                                    id="password"
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value) } />
                                <label>Api Key</label>
                                <input
                                    type="text"
                                    placeholder="Enter Api Key"
                                    name="api_key"
                                    id="api_key"
                                    value={apiKey}
                                    onChange={(event) => setApiKey(event.target.value) } />
                                <input
                                    type="number"
                                    placeholder="Enter Job Posting Limit"
                                    name="job_limit"
                                    id="job_limit"
                                    value={jobLimit}
                                    onChange={(event) => setJobLimit(event.target.value) } />
                                <label>Select Mode of Operation</label>
                                <select className='form-control access-board-select cursor-pointer color-black' name="mode_of_operation" id="mode_of_operation" value={modeOfOperation} onChange={(event) => setModeOfOperation(event.target.value) }>
                                    <option value=''>Select Mode of Operation</option>
                                    {modeOfOperationOptions && modeOfOperationOptions.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
                                </select>
                                <div className="radio_sec">
                                    <h6>Status</h6>
                                    <label className="radio-inline">
                                        <input
                                            type="radio"
                                            name="status"
                                            id="inlineRadio1"
                                            checked={boardAccessStatus === 'A'}
                                            value='A'
                                            onChange={(event) => setBoardAccessStatus(event.target.value) }/> Active
                                    </label>
                                    <label className="radio-inline">
                                        <input
                                            type="radio"
                                            name="status"
                                            id="inlineRadio2"
                                            checked={boardAccessStatus === 'I'}
                                            value='I'
                                            onChange={(event) => setBoardAccessStatus(event.target.value) }/> Inactive
                                    </label>
                                </div>
                            </div>
                            <div className="modal-footer all_modal-footer">
                                <SubmitButton/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* <ToastContainer/> */}
            <div className="modal fade post-job-model post-job-model_2" id="exampleModal4" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content modal-content_ans modal_yes">
                        <div className="modal-header modal_head">
                            <h5
                                >Delete Job Board</h5>
                            {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button> */}
                        </div>
                        <div className="modal-body mid_modal">
                                <p className="opening opening_4">Are you sure you want to delete the selected job board?</p>
                                {popup.show && (
                                    <div className="answers">
                                        <span onClick={handleDeleteFalse}><a className="sub_answer cursor-hover">No</a></span>
                                        <span onClick={handleDeleteTrue}><a className="sub_answer_2 cursor-hover">Yes</a></span>
                                    </div>
                                )}
                        </div>

                    </div>
                </div>
            </div>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="modal-content" sx={style}>
                        <Grid container id="modal-title">
                            <Grid item xs={10}>
                                {!accessId && <Typography className="modalTitle f-18 heading-text-color">Add Job Board</Typography>}
                                {accessId && <Typography className="modalTitle f-18 heading-text-color">Update Job Board</Typography>}
                            </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} xs={2} align="right" className="cursor-hover sub-text-color">
                            <Typography className="icon-button">
                            <ClearIcon
                                    onClick={handleClose}
                                        aria-label="Clear icon"
                                        className="job-board-modal-close"
                                    />
                            </Typography>
                        </Grid>
                        <form>
                            <Grid id="modal-description" >
                                <Grid container spacing={1} className='mt-6'>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box className='m-b-model-label'>
                                            <Typography className="gray7 f-14">
                                                Select Job Board<span className="text-black">*</span>{" "}
                                            </Typography>
                                            <Box>
                                                <Select
                                                    name="job_board_id"
                                                    id="job_board_id"
                                                    value={jobBoardId ? jobBoardId : "default"}
                                                    onChange={(event) => setJobBoardId(event.target.value) }
                                                    defaultValue={"default"}
                                                    className="width-100 select-board"
                                                    renderValue={
                                                        jobBoardId !== "" ? undefined : () => <Placeholder>--Select--</Placeholder>
                                                      }
                                                    disabled={iseditjobboard ? true : false }
                                                    >
                                                <MenuItem value={"default"} >
                                                    --Select--
                                                </MenuItem>
                                                {jobBoards && jobBoards.map((jobBoard) => <MenuItem key={jobBoard.job_board_id} value={jobBoard.job_board_id} >{jobBoard.name}</MenuItem>)}
                                                </Select>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} className='mt-6'>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box>
                                            <Typography className="gray7 f-14">
                                                User Name
                                            </Typography>
                                            <Input
                                            type="text"
                                            id="user_name"
                                            value={userName}
                                            onChange={(event) => handleChangeBoardData(event, 'user_name') }
                                            className='form-control input-group'
                                            margin="normal"
                                            placeholder="Enter user name"
                                            disableUnderline={true}
                                            />
                                            <span className="danger-color error-msg">
                                            {" "}
                                            {nameError && nameError}
                                            </span>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} className='mt-6'>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box>
                                            <Typography className="gray7 f-14">
                                            Password
                                            </Typography>
                                            <Input
                                            type="text"
                                            id="password"
                                            value={password}
                                            onChange={(event) => handleChangeBoardData(event, 'password') }
                                            className='form-control input-group'
                                            margin="normal"
                                            placeholder="Enter password"
                                            disableUnderline={true}
                                            />
                                            <span className="danger-color error-msg">
                                            {" "}
                                            {passwordError && passwordError}
                                            </span>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} className='mt-6'>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box>
                                            <Typography className="gray7 f-14">
                                            API Key
                                            </Typography>
                                            <Input
                                            type="text"
                                            id="api_key"
                                            value={apiKey}
                                            onChange={(event) => handleChangeBoardData(event, 'api_key') }
                                            className='form-control input-group'
                                            margin="normal"
                                            placeholder="Enter api key"
                                            disableUnderline={true}
                                            />
                                            <span className="danger-color error-msg">
                                            {" "}
                                            {apiKeyError && apiKeyError}
                                            </span>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} className='mt-6'>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box>
                                            <Typography className="gray7 f-14 d-flex align-item-center">
                                                Job Posting Limit
                                                <Tooltip
                                                    placement="right"
                                                    title="This limit represents the number of jobs that can be posted on this job board."
                                                >
                                                    <InfoIcon style={{ marginLeft: "3px" }} aria-label="Info icon" />
                                                </Tooltip>
                                            </Typography>
                                            <Input
                                                type="number"
                                                id="job_limit"
                                                value={jobLimit}
                                                onChange={(event) => setJobLimit(event.target.value) }
                                                className='form-control input-group'
                                                margin="normal"
                                                placeholder="Enter job posting limit"
                                                disableUnderline={true}
                                                inputProps={{ min: 0 }}
                                                onKeyPress={(event) => {
                                                    if (event?.key === '-' || event?.key === '+') {
                                                      event.preventDefault();
                                                    }
                                                }}
                                            />
                                            <span className="danger-color error-msg">
                                            {" "}
                                                {apiKeyError && apiKeyError}
                                            </span>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} className='mt-6'>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box className='m-b-model-label'>
                                            <Typography className="gray7 f-14">
                                                Select Mode of Operation<span className="text-black">*</span>{" "}
                                            </Typography>
                                            <Box>
                                                <Select
                                                    name="mode_of_operation"
                                                    id="mode_of_operation"
                                                    value={modeOfOperation ? modeOfOperation : "default"}
                                                    defaultValue={"default"}
                                                    onChange={(event) => setModeOfOperation(event.target.value) }
                                                    className="width-100 select-board"
                                                    renderValue={
                                                        modeOfOperation !== "" ? undefined : () => <Placeholder>--Select--</Placeholder>
                                                      }>
                                                <MenuItem value={"default"}>
                                                    --Select--
                                                </MenuItem>
                                                {modeOfOperationOptions && modeOfOperationOptions.map(({ value, label }, index) => <MenuItem key={index} value={value} >{label}</MenuItem>)}
                                                </Select>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} className='mt-6'>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label" className="gray7 f-14" style={{ fontFamily: "var(--font-family)" }} >Status</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                            >
                                                <FormControlLabel
                                                    name="status"
                                                    id="inlineRadio1"
                                                    checked={boardAccessStatus === 'A'}
                                                    value='A'
                                                    onChange={(event) => setBoardAccessStatus(event.target.value) }
                                                    control={<Radio />} label="Active" />
                                                <FormControlLabel
                                                    name="status"
                                                    id="inlineRadio2"
                                                    checked={boardAccessStatus === 'I'}
                                                    value='I'
                                                    onChange={(event) => setBoardAccessStatus(event.target.value) }
                                                    control={<Radio />} label="Inactive" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} className='mt-6'>
                                <Grid item lg={12} md={12} xs={12}>
                                    <Grid id="modal-footer" className="justify-center d-flex mt-20 align-item-center">
                                        <SubmitButton/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Modal>
            </div>

            <Modal
                open={openConfirmBoxDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                className="inactive-modal inputcontrasts outline-none"
                >
                <Typography variant="h5" className="font-weight-500 f-20  gray9">
                    {`Existing job listings will not be removed from the respective job boards. Jobs can be removed using the "Remove from Job Board" option in the Job Listing.`}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                    <Button
                    type="button"
                    onClick={() => updateFormData()}
                    className="btn primary-button w-40 mt-20"
                    variant="contained"
                    disableRipple="true"
                    >
                    OK
                    </Button>
                </Typography>
                </Box>
            </Modal>
            <Modal
                open={openInactiveDialog}
                // onClose={handleCloseInactiveDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                className="inactive-modal inputcontrasts outline-none"
                >
                <Typography variant="h5" className="font-weight-500 f-20  gray9">
                    {`Existing job listings will not be removed from the respective job boards. Jobs can be removed using the "Remove from Job Board" option in the Job Listing.`}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                    <Button
                    type="button"
                    onClick={() => setInactivemodel()}
                    className="btn primary-button w-40 mt-20"
                    variant="contained"
                    disableRipple="true"
                    >
                    OK
                    </Button>
                </Typography>
                </Box>
            </Modal>
            <Dialog
                open={openConfirmDialog}
                onClose={handleCloseConfirmDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                id="confirm-dialog"
                className="dialogue-popup"
                >
                <DialogTitle id="alert-dialog-title" className="heading-text-color">
                    Delete Job Board
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                    id="alert-dialog-description"
                    className="f-14 font-weight-400"
                    >
                    Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="pb-20">
                    <Button
                    className="confirm-link dialog-secondary font-weight-600"
                    onClick={handleDeleteFalse}
                    aria-label="no title"
                    disableRipple={true}
                    id="alert-dialog-no"
                    >
                    No
                    </Button>
                    <Button
                    className="confirm-link dialog-primary primary-color"
                    onClick={handleDeleteTrue}
                    autoFocus
                    aria-label="yes title"
                    disableRipple={true}
                    id="alert-dialog-yes"
                    >
                    Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
