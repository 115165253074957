import React from 'react';
import { useEffect, useState, useRef } from "react";
import Toggle from 'react-styled-toggle';
import moment from "moment";
import ApiCalls from "../api/index";
import Pagination from '@mui/material/Pagination';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Loader from "../Loader";

import XMLViewer from "react-xml-viewer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Grid, Breadcrumbs, Stack } from "@mui/material";
import PaginationItem from "@mui/material/PaginationItem";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ReactComponent as ArrowBackIcon } from "../../src/assets/images/arrow-left.svg";
import { ReactComponent as ArrowForwardIcon } from "../../src/assets/images/arrow-right.svg";
import { makeStyles } from "@material-ui/core/styles";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 400,
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
   maxHeight: "400px",
   minWidth: "800px",
   overflowX: "auto !important",
   overflowY: "auto !important",
};




export default function JobViewPage() {
    const [jobLogsArray, setJobLogs] = useState([]);

    const [searchJobBoard, setSearchJobBoard] = useState('');

    const [pageNumber, setPageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [rowsperpage, setRowsPerPage] = React.useState(10);
    let appOrigin = localStorage.getItem("appOrigin");

    const [xmlData, setXmlData] = useState(null);
    const [open, setOpen] = useState(false);

    const [viewResponse, setViewResponse] = useState(null);
    const [openViewResponse, setOpenViewResponse] = useState(false);

    const [jobLogResponses, setJobLogResponses] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const params = useParams();

    let navigate = useNavigate();
    let location = useLocation();

    const goToJobs = (e) => {
        e.preventDefault();
        navigate("/admin/jobs");
    };

    useEffect(() => {
        document.title = `Jobs Detail | ${ appOrigin == 'H' ? ("Direct Health") : appOrigin == 'N' ? ("Neurodiversify") : ("SimplifyHire") }`;
        getJobLogs();
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [pageNumber]);

    const ref = useRef();

    useEffect(() => {
        ref?.current?.scrollIntoView();
    }, [ref?.current]);



    const getJobLogs = () => {
        setIsLoading(true);
        ApiCalls.get(`/api/v1/jobs/${params.job_id}?page=${pageNumber}`)
        .then((res) => {
            const { data } = res.data.data;
            setJobLogs(data);
            setPageNumber(res.data.data.page_number);
            setPageCount(res.data.data.page_count);
            setTotalCount(res.data.data.total_count);
            setTotalPage(res.data.data.total_pages);
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    };

    const handlePageChange = (event, value) => {
        setPageNumber(value);
    };

    const handleActiveInactiveJobLogsStatus = (log_id, status) => {
        setIsLoading(true);

        let active_condition = status==='P' || status==='C';
        var formData = new FormData();
        formData.append("status", active_condition?'I':'P');
        ApiCalls.post(`/api/v1/job-logs/update/status/${log_id}`, formData )
        .then((res) => {
            setJobLogs(
                jobLogsArray.map((_jobLog) => {
                return _jobLog.log_id === log_id ? { ..._jobLog, status: active_condition?'I':'P' } : _jobLog;
            })
            );
            toast.success("Status updated successfully.");
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            toast.error(error);
        });
    };

    const repostJob = (log_id) => {
        setIsLoading(true);
        console.log(log_id);
        var formData = new FormData();
        formData.append("job_log_id", log_id);
        ApiCalls.post(`/api/v1/repost/job`, formData )
        .then((res) => {
            getJobLogs();
            toast.success('Job posted successfully.');
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            toast.error(error);
        });
    };

    function getStatusBool(getStatus) {
        if (getStatus === 'P' || getStatus === 'C') {
          return true;
        }
        return false;
    }

    function getStatusText(status) {
        if (status === "P") {
            return "Pending";
        } else if (status === "C") {
            return "Completed";
        } else if (status === "R") {
            return "Failed";
        } else if (status === "I") {
            return "Test Mode";
        } else if (status === "A") {
            return "Pending";
        } else if (status === "L") {
            return "Closed";
        }
    }


    const handleCompletedtoClosedJobLogsStatus = (log_id, status) => {
        setIsLoading(true);
        var formData = new FormData();
        formData.append("status", 'I');
        ApiCalls.post(`/api/v1/job-logs/update/status/${log_id}`, formData )
        .then((res) => {
            // setJobLogs(
            //     jobLogsArray.map((_jobLog) => {
            //     return _jobLog.log_id === log_id ? { ..._jobLog, status: status==='C'?'L':'C'} : _jobLog;
            // })
            // );
            getJobLogs();
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            toast.error(error);
        });
    };

    function getActionText(log_id, status, jobLog) {
        if (status === "C") {
            return <a className='cursor-pointer' style={{ color: "#ff0000" }}><span onClick={ () => handleCompletedtoClosedJobLogsStatus(log_id, status) }>Remove from Job Board</span></a>;
        } else if (status === "P") {
            return "Remove from Job Board";
        } else if (status === "R") {
            return  <><i onClick={() => viewJobResponse(jobLog)} data-toggle="modal" data-target="#viewJobResponse" title="View Response" className='cursor-pointer' style={{ marginRight: "5px" }}><img src="/beanbag-assets/img/job-logs-icon.svg" height="18" /></i>
            <a onClick={() => viewJobResponse(jobLog)} data-toggle="modal" data-target="#viewJobResponse" title="View Response" className='cursor-pointer f-14' style={{ color: "#5469CC" }}>View Error Log</a></>;
        } else if (status === "L") {
            return "Removed from Job Board";
        } else if (status === "I") {
            return <span style={{ paddingLeft: "15px" }} className='job_board_action'>-</span>;
        }
    }

    const getColor = (status) => {
        if (status === "C") return 'red';
        if (status === "P") return 'grey';
        if (status === "R") return 'grey';
        if (status === "L") return 'grey';
        if (status === "I") return 'grey';
        return '';
    };



    const handleModalOpen = (jobLog) => {
        if (jobLog.status === 'I') {
            setViewResponse('Job was posted in the test mode!');
            setOpenViewResponse(true);
        } else {
            setXmlData(jobLog.response_payload);
            setOpen(true);
        }
    };

    const handleClose = () => setOpen(false);
    const handleCloseViewResponse = () => setOpenViewResponse(false);

    function IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    function returnBeautifyString(str) {
        try {
            const a = JSON.parse(str);
            return JSON.stringify(a, null, 2);
        } catch (e) {
            return str;
        }
    }

    const viewJobResponse = (jobLog) => {
        setJobLogResponses(jobLog.response_payload);
    };

   const modeStatus = localStorage.getItem('mode');

    return (
        <>
            <div className="dash_content"  ref={ref} style={{ height: '100vh' }} id="job-details">
                {isLoading && <Loader />}
                <div className="table-wrapper">
                <div className="row job-head">
                    <div className="col-md-12">
                        <Grid item lg={12} md={12} xs={12} className="m-auto">
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                className="mt-10 mb-10"
                                separator={<NavigateNextIcon fontSize="large" aria-label="Navigate icon" />}
                            >
                                <span className='cursor-pointer font-weight-500 gray9' onClick={goToJobs}
                                style={{ fontSize: "var(--font-size-16)" }}>
                                    {location?.state?.job}
                                </span>
                                <span style={{ color: "var(--primary-color)", fontSize: "var(--font-size-16)", cursor: "context-menu" }}>
                                    Job Logs
                                </span>
                            </Breadcrumbs>
                        </Grid>
                    </div>
                    {/* <div className="col-md-8">
                        <div className="input_right">
                            <input
                            type="text"
                            placeholder='Search'
                            onChange={ (e) => setSearchJobBoard(e.target.value) } />
                            <a onClick={ () => { getJobLogs(1); } } style={{cursor: 'pointer'}}><i><img className="fa-search" src='/beanbag-assets/img/search-icon.svg'/></i></a>
                        </div>
                    </div> */}
                </div>
                <div className="job_listing_table">
                    <div className="row">
                        <div className="col-md-12 p-inherit">
                            <div className="table_inner table-responsive">
                            <table className="table dash_table dash_table_2">
                                <thead>
                                    <tr className="tr_target logs-table">
                                        <th width="2%" scope="col"></th>
                                        {/* <th scope="col" className="board-heading-first-title">Job Title</th> */}
                                        <th width="20%" scope="col">Job Board</th>
                                        <th width="20%" scope="col">Posted Date</th>
                                        <th width="10%" scope="col" className="">Status</th>
                                        <th width="20%" className="center_heading" style={{ textAlign: "left" }}>Action</th>
                                        {/* <th width="20%" scope="col">Action</th> */}
                                        {/* {modeStatus && modeStatus === 'L' ?
                                        <th width="8%" scope="col"></th> : ''
                                        } */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {jobLogsArray?.map( (jobLog, index) => {
                                        return (
                                        <>
                                            <tr className="tr_target list-hover-color" key={index}>
                                                <td className="th_target pt-20">
                                                    <span className="target_span">{((pageNumber - 1) * 10) + index + 1}. &nbsp;</span>
                                                </td>
                                                {/* <td  className="th_target">
                                                    {jobLog.job_title}
                                                </td> */}
                                                <td  className="th_target job-log-boards-name job_logs p-l-0 pt-20">
                                                    <span className="target_span"></span> {jobLog.job_board_name}
                                                </td>
                                                <td className="td_target job_log">{moment(jobLog.request_timestamp).format("MMM DD, YYYY HH:mm")}</td>
                                                <td className="td_target job_log">
                                                    <span className={`status-size label
                                                    ${jobLog.status === 'C'?'label-success':jobLog.status === 'R'
                                                    ?'label-danger':jobLog.status === 'I'
                                                    ?'label-default':jobLog.status === 'L'
                                                    ?'label-warning':'label-info'}`}>
                                                    { getStatusText(jobLog.status)}</span>
                                                </td>
                                                <td className="center_heading td_target log-switch"

                                                style={{ color: getColor(jobLog.status), fontWeight: '400', textAlign: "left" }}>
                                                    <span>
                                                        { getActionText(jobLog.log_id, jobLog.status, jobLog)}
                                                    </span>


                                                </td>
                                                {/* {/* {jobLog.status === 'R' ?
                                                    <td className="td_target">
                                                        <i onClick={ () => viewJobResponse(jobLog) } data-toggle="modal" data-target="#viewJobResponse" title="View Response" className='cursor-pointer' style={{marginRight: "7px"}}><img src="/beanbag-assets/img/job-logs-icon.svg" /></i>
                                                        <a onClick={ () => viewJobResponse(jobLog) } data-toggle="modal" data-target="#viewJobResponse" title="View Response" className='cursor-pointer' style={{color: "#5469CC"}}>View Response</a>
                                                    </td>
                                                    :
                                                    <td className="td_target">
                                                        <i onClick={ () => viewJobResponse(jobLog) } data-toggle="modal" data-target="#viewJobResponse" title="View Response" className='cursor-pointer disabled' style={{marginRight: "7px"}}><img src="/beanbag-assets/img/job-logs-icon.svg" /></i>
                                                        <a onClick={ () => viewJobResponse(jobLog) } data-toggle="modal" data-target="#viewJobResponse" title="View Response" className='cursor-pointer disabled' style={{color: "#5469CC"}}>View Response</a>
                                                    </td>
                                                } */}
                                                    {/* {modeStatus && modeStatus === 'L' && jobLog.status === 'R' ?
                                                    <td className="disk" style={{ display: "flex", marginTop: "13px", color: "#5469CC" }}>
                                                        <i onClick={() => repostJob(jobLog.log_id)} className='cursor-pointer d-flex' style={{ marginRight: "5px", alignItems: 'center' }}><img src="/beanbag-assets/img/job-repost-icon.svg" width="16px" height="16px" /></i><span onClick={() => repostJob(jobLog.log_id)} className='cursor-pointer f-14'>Repost</span>
                                                    </td> : modeStatus && modeStatus === 'L' ? <td></td> : ''
                                                    } */}
                                            </tr>
                                        </>
                                        );
                                    })}
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="row">
                        {totalCount === 0
                            ?
                            ''
                            :
                            <div className="col-md-6 p-inherit">
                            <p className="s-p-l-ten">Showing <span className="per-page-count-box">10</span> items per page</p>
                            </div>
                        }
                        {totalCount >= 1 &&
                        <div className="col-md-6">
                        <div className="pagination pagenation-design">
                            <>
                            <Grid
                            item
                            lg={6}
                            md={6}
                            xs={12}
                            className="justify-end d-flex pagenation-table navbarUser pt-8"
                            >
                            <Stack spacing={2} className="pagenation-joblist">
                                <Pagination
                                shape="rounded"
                                // color="primary"
                                rowsperpage={rowsperpage}
                                count={
                                    Math.floor(totalCount / 10) +
                                    (totalCount % 10 === 0 ? 0 : 1)
                                }
                                // page={page}
                                onChange={handlePageChange}
                                renderItem={(item) => (
                                    <PaginationItem
                                    components={{
                                        last: (props) => (
                                        <button
                                            className="btn primary-button profileButton"
                                            {...props}
                                        >
                                            Last
                                        </button>
                                        ),

                                        next: (props) => (
                                        <Box
                                            className="d-flex align-center cursor-hover"
                                            {...props}
                                        >
                                            <Box className="gray7 f-14 align-center font-weight-500 ml-10 mr-20">
                                            Next
                                            </Box>
                                            <ArrowForwardIcon
                                            height="13px"
                                            width="13px"
                                            />{" "}
                                        </Box>
                                        ),

                                        first: (props) => (
                                        <Box
                                            className="d-flex align-center cursor-hover"
                                            {...props}
                                        >
                                            <ArrowBackIcon
                                            height="13px"
                                            width="13px"
                                            />{" "}
                                            <Box className="gray7 f-14 align-center font-weight-500 ml-10 mr-20">
                                            Previous
                                            </Box>
                                        </Box>
                                        ),

                                        previous: (props) => (
                                        <Box
                                            className="d-flex align-center cursor-hover"
                                            {...props}
                                        >
                                            <ArrowBackIcon
                                            height="13px"
                                            width="13px"
                                            />{" "}
                                            <Box className="gray7 f-14 font-weight-500 ml-10 mr-20">
                                            Previous
                                            </Box>
                                        </Box>
                                        ),
                                    }}
                                    {...item}
                                    />
                                )}
                                showFirstButton={false}
                                showLastButton={false}
                                />
                            </Stack>
                            </Grid>
                            </>
                        </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div className="modal fade post-job-model post-job-model_2" id="viewJobResponse" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header modal_head" style={{ paddingTop: "0px" }}>
                            <h5 style={{ fontSize: '20px', fontWeight: '600' }}>View Error Log</h5>
                            <button type="button" style={{ color: 'grey', opacity: 1 }} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body mid_modal">
                            <div className="badging">
                                <p><b>Issue</b>
                                    {/* <span className="badge">{jobLogResponses && jobLogResponses.length ? jobLogResponses.length : 0}</span> */}
                                </p>
                            </div>
                            {jobLogResponses && jobLogResponses.map( (response, index) => {
                                return (
                                    <>
                                        <p className="opening opening_2">{response.msg}</p>
                                        <p className="f-14 gray9 mt-25">
                                            <span className='font-weight-600'>Note: </span>
                                            <span className='font-weight-400 p-l-5'>Edit the job in SimplifyHire and repost it to the job boards.</span>
                                        </p>
                                        {/* <div className="view_r">
                                            <i className="fa fa-times"></i><span className="mid_span">Failed</span>
                                        </div> */}
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
            <div className="table-responsive" style={{ height: "auto", display: 'none' }}>
                <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    {IsJsonString(xmlData) ? (
                        <pre>{returnBeautifyString(xmlData)}</pre>
                    ) : (
                        <XMLViewer xml={xmlData} />
                    )}
                </Box>
                </Modal>
            </div>
            <div className="table-responsive" style={{ height: "auto", display: 'none' }}>
                <Modal
                open={openViewResponse}
                onClose={handleCloseViewResponse}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    {viewResponse}
                </Box>
                </Modal>
            </div>
        </>
    );
}
