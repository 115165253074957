import * as React from "react";
import { useState } from "react";
import ApiCalls from "../api/index";
import "../App.css";
import moment from "moment";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import XMLViewer from "react-xml-viewer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "400px",
  minWidth: "800px",
  overflowX: "auto !important",
  overflowY: "auto !important",
};

export default function HomePage(props) {
  const [boards, setBoards] = React.useState([]);
  const [xmlData, setXmlData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [boardOptions, setBoardOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [alert, setAlert] = useState({ status: "", message: "" });

  const [customer, setCustomer] = useState({
    username: "",
    email: "",
    password: "",
    domain: "",
    subscription_end_date: "",
  });

  useEffect(() => {
    ApiCalls.get(`/api/job-boards`).then((res) => {
      if (res) {
        const { data } = res.data;
        setBoards(data);
        const outputSelectedBoards = res.data.data.map((_board) => {
          //const found =  boards.some(a => a.board_id === _board.id);
          //return found ? {..._board, isSelected: true } : _board; // return the ones with equal id
          return { value: _board.id, label: _board.name }; // return the ones with equal id
        });
        setBoardOptions(outputSelectedBoards);
        forceUpdate();
      }
    });
  }, []);

  const handleChangeCustomer = (event, fieldName) => {
    if (fieldName === "username") {
      setCustomer((prevState) => {
        return { ...prevState, username: event.target.value };
      });
    }
    if (fieldName === "email") {
      setCustomer((prevState) => {
        return { ...prevState, email: event.target.value };
      });
    }
    if (fieldName === "password") {
      setCustomer((prevState) => {
        return { ...prevState, password: event.target.value };
      });
    }
    if (fieldName === "domain") {
      setCustomer((prevState) => {
        return { ...prevState, domain: event.target.value };
      });
    }
    // if(fieldName === 'api_access_key')
    //   setCustomer( prevState => { return {...prevState, api_access_key:event.target.value} });
    if (fieldName === "subscription_end_date") {
      setCustomer((prevState) => {
        return { ...prevState, subscription_end_date: event.target.value };
      });
    }
  };

  function handleChangeBoardCheckbox(event, board) {
    setBoards(
      boards.map((_board) => {
        return _board.id === board.id
          ? { ..._board, isSelected: event.target.checked, api_key: "" }
          : _board;
      })
    );
  }

  const navigate = useNavigate();

  // save customer and boards access
  const saveCustomer = () => {
    console.log(customer);
    console.log(boards);
    const outputSelectedBoards = selectedOption.map((_board) => {
      //const found =  boards.some(a => a.board_id === _board.id);
      //return found ? {..._board, isSelected: true } : _board; // return the ones with equal id
      return { id: _board.value, name: _board.label }; // return the ones with equal id
    });
    customer["boards"] = outputSelectedBoards;
    ApiCalls.post(`/api/customers/add`, customer)
      .then((res) => {
        const { data } = res.data;
        console.log(data);
        navigate("/admin/customers");
        setAlert({ status: "success", message: res.data.message });
      })
      .catch((err) => {
        setAlert({ status: "error", message: err.response.data.error });
      });
  };

  const subscriptionDatePickerClick = () => {
    const inputDateElement = document.querySelector("#subscription_end_date");
    inputDateElement.showPicker();
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="panel panel-primary">
            <div className="panel-heading">
              <h3 className="panel-title">Add Customer</h3>
            </div>
            <div className="panel-body">
              <div
                style={{ display: "none" }}
                className={`
                      ${
                        alert.status == "success"
                          ? "alert alert-success show"
                          : ""
                      }
                      ${
                        alert.status == "error" ? "alert alert-danger show" : ""
                      }`}
              >
                <span>{alert.message}</span>
              </div>
              <form role="form">
                <div className="form-group">
                  <label htmlFor="customer_name">Customer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="customer_name"
                    value={customer.username}
                    onChange={(event) =>
                      handleChangeCustomer(event, "username")
                    }
                    placeholder="Customer Name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="customer_name">Customer Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={customer.email}
                    onChange={(event) => handleChangeCustomer(event, "email")}
                    placeholder="Customer Email"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="customer_name">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="customer_password"
                    value={customer.password}
                    onChange={(event) =>
                      handleChangeCustomer(event, "password")
                    }
                    placeholder="Enter password"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="domain">Domain</label>
                  <input
                    type="text"
                    className="form-control"
                    id="domain"
                    placeholder="Domain"
                    value={customer.domain}
                    onChange={(event) => handleChangeCustomer(event, "domain")}
                  />
                </div>
                <div className="form-group" style={{ display: "none" }}>
                  <label htmlFor="api_key">API Access Key</label>
                  <input
                    type="text"
                    className="form-control"
                    id="api_key"
                    placeholder="API Access Key"
                    value={customer.api_access_key}
                    onChange={(event) =>
                      handleChangeCustomer(event, "api_access_key")
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="domain">Subscription End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="subscription_end_date"
                    placeholder="Subscription End Date"
                    value={customer.subscription_end_date}
                    style={{ cursor: "pointer" }}
                    onClick={subscriptionDatePickerClick}
                    onChange={(event) =>
                      handleChangeCustomer(event, "subscription_end_date")
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="domain">Job Boards</label>
                  {/*JSON.stringify(selectedOption)*/}
                  <Select
                    isMulti={true}
                    onChange={setSelectedOption}
                    options={boardOptions}
                  />
                  {/*boards.map( board => {
                        return (
                            <>
                              <span style={{display:'inline-block', width: "150px"}}>
                                <input type="checkbox"
                                  onChange={(event) => handleChangeBoardCheckbox(event, board)} /> <span style={{textTransform: 'capitalize'}}>{board.name}</span>
                              </span>
                            </>
                        )
                      })*/}
                </div>
                <button
                  type="button"
                  onClick={saveCustomer}
                  className="btn btn-info waves-effect waves-light pull-right"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
