import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Tooltip,
  Box,
  Button,
  Grid,
  Typography,
  Container,
  Card,
  MenuItem,
  Menu,
} from "@mui/material";
import { ReactComponent as MenuIcon } from "../../assets/images/menu-icon.svg";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";

export default function Navbar() {
  const [responseType, setResponseType] = useState("code");
  const [state, setState] = useState("FkCDxnQb0DJ0coqPnKQnv8DmOECBXdEE");
  const [scope, setScope] = useState(
    "phone+email+openid+profile aws.cognito.signin.user.admin"
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuBar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const handleCloseMenu = (event) => {
    setAnchorEl(null);
  };

  useEffect(() => {
    document.querySelector("body").classList.add("front_end_body");
  }, []);

  const scrollToCleint = (e, el) => {
    setAnchorEl(null);
    var scrollDiv = document.getElementById(el).offsetTop;
    window.scrollTo({ top: scrollDiv - 100, behavior: "smooth" });
  };

  const navigateToSSO = (event) => {
    window.location.href = `https://${process.env.REACT_APP_AWS_USER_POOL_DOMAIN}/login?redirect_uri=${process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}&response_type=${responseType}&client_id=${process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID}&identity_provider=COGNITO&scope=${scope}`;
  };

  return (
    <>
      {isMobile ? (
        <>
          <Grid item lg={12} md={12} xs={12}>
            <Box className="main_first_navbar align-item-center-flex">
              <Box className="justify-content width-100">
                <a href="/">
                  <img alt="" src="/beanbag-assets/img/BeanBag-logo.svg" />
                </a>
                <Button
                  variant="text"
                  onClick={handleMenuBar}
                  role="button"
                  aria-label="Menu icon"
                  disableRipple="true"
                  className="dash_board_navbar mt-0"
                >
                  {" "}
                  <MenuIcon
                    width="25px"
                    height="25px"
                    aria-label="Menu icon"
                    className="gray9"
                  />
                </Button>
              </Box>
            </Box>
          </Grid>
          <Menu
            className="navbar-responsive sidebar-dropdown"
            anchorEl={anchorEl}
            disableScrollLock={true}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            KeepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={open}
          >
            <Box className="navbar-responsive">
              <Box sx={{ textAlign: "right" }} className="icon-button">
                <CloseIcon
                  className="f-18"
                  onClick={handleCloseMenu}
                  onClose={handleCloseMenu}
                  tabIndex={0}
                  aria-label="Close icon"
                />
              </Box>
            </Box>
            <Grid item className="add-checklist-align">
              <>
                <Box
                  sx={{ px: 4 }}
                  className="p-10"
                  style={{ padding: "4px 16px" }}
                >
                  <a
                    onClick={(event) => scrollToCleint(event, "home")}
                    className="primary-color text-decoration f-16 font-weight-600 cursor-pointer"
                  >
                    Home
                  </a>
                </Box>
                <Box
                  sx={{ px: 4 }}
                  className="p-10"
                  style={{ padding: "4px 16px" }}
                >
                  <a
                    onClick={(event) => scrollToCleint(event, "how_it_works")}
                    className="primary-color text-decoration f-16 font-weight-600 cursor-pointer"
                  >
                    How It Works
                  </a>
                </Box>
                <Box
                  sx={{ px: 4 }}
                  className="p-10"
                  style={{ padding: "4px 16px" }}
                >
                  <a
                    onClick={(event) => scrollToCleint(event, "clients")}
                    className="primary-color text-decoration f-16 font-weight-600 cursor-pointer"
                  >
                    Our Clients
                  </a>
                </Box>
              </>
            </Grid>
          </Menu>
        </>
      ) : (
        <div className="main_first_navbar">
          <div className="toggle-menu"></div>
          <nav className="navbar container_bb" style={{ marginTop: "-20px" }}>
            <Link to="/" className="logo-ben" style={{ paddingLeft: "5px" }}>
              <img alt="" src="/beanbag-assets/img/BeanBag-logo.svg" />
            </Link>
            <ul className="nav navbar-nav navbar-right f-14 font-weight-600">
              <li>
                <a
                  onClick={(event) => scrollToCleint(event, "home")}
                  style={{ cursor: "pointer" }}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  onClick={(event) => scrollToCleint(event, "how_it_works")}
                  style={{ cursor: "pointer" }}
                >
                  How It Works
                </a>
              </li>
              <li>
                <a
                  onClick={(event) => scrollToCleint(event, "clients")}
                  style={{ cursor: "pointer" }}
                >
                  Clients
                </a>
              </li>
              {/*<li>*/}
              {/*    <a*/}
              {/*        onClick={event => navigateToSSO(event)}*/}
              {/*        style={{cursor: 'pointer'}}*/}
              {/*    >SignIn</a>*/}
              {/*</li>*/}
              {/* <li>
                        <a onClick={(e) => {e.preventDefault(); window.location.replace('/#plans');}} style={{cursor: 'pointer'}}>Plans</a>
                    </li> */}
            </ul>
          </nav>
        </div>
      )}
    </>
  );
}
