import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <header className="header-section transparent-header marketing-bg">
        <div className="header-area">
          <div className="container">
            <div className="primary-menu">
              <div className="logo">
                <Link to="/">
                  <img
                    src="temp-assets/images/logo/bean-bagv2.png"
                    style={{ width: "216px" }}
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="main-area">
                <div className="main-menu">
                  <ul>
                    <li className="">
                      <a href="/">Home</a>
                    </li>
                    <li className="">
                      <a href="#">How It Works</a>
                    </li>
                    <li className="">
                      <a href="#">Job Boards</a>
                    </li>
                    <li className="">
                      <a href="#">Clients</a>
                    </li>
                    <li className="">
                      <a href="#">About Us</a>
                    </li>
                    <li>
                      <a href="/login">Login</a>
                    </li>
                  </ul>
                </div>
                <div className="header-btn">
                  <Link
                    to="/request-a-demo"
                    style={{ fontSize: "16px", padding: "16px 25px" }}
                    className="btn btn-primary w-md waves-effect waves-light"
                  >
                    <span>Request a Demo</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
