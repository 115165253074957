import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiCalls from "../api/index";
import "../App.css";

export default function HomePage(props) {
  const [customerBoards, setCustomerBoards] = React.useState([]);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const userRole = localStorage.getItem("is_admin");
  const [alert, setAlert] = useState({ status: "", message: "" });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [customer, setCustomer] = useState({
    customer_secret: "",
    username: "",
    domain: "",
    api_key: "",
    subscription_end_date: "",
  });

  const params = useParams();

  useEffect(() => {
    ApiCalls.get(`/api/customers/view/${params.customer_secret}`).then(
      (res) => {
        console.log(res.data);
        const { customer, boards } = res.data;
        setCustomer(customer);
        setCustomerBoards(boards);
        forceUpdate();
      }
    );
  }, []);

  const handleChangeCustomer = (event, fieldName) => {
    if (fieldName === "username") {
      setCustomer((prevState) => {
        return { ...prevState, username: event.target.value };
      });
    }
    if (fieldName === "api_key") {
      setCustomer((prevState) => {
        return { ...prevState, api_key: event.target.value };
      });
    }
  };

  const navigate = useNavigate();
  const updateCustomerAccess = () => {
    console.log(customerBoards);
    ApiCalls.post(`/api/customer-boards/update/${customer.customer_secret}`, {
      boards: customerBoards,
    }).then((res) => {
      if (res) {
        const { data } = res.data;
        console.log(data);
        if (
          localStorage.getItem("token") &&
          !localStorage.getItem("consumer_id")
        ) {
          navigate("/admin/customers");
        } else {
          setAlert({
            status: "success",
            message: "Job boards credentials updated successfully!",
          });
          navigate(`/admin/customers/${customer.customer_secret}`);
        }
      }
    });
  };

  const handleDeleteBoard = (access_id) => {
    if (window.confirm("Are you sure want to delete?")) {
      //Logic to delete the job
      ApiCalls.delete(`/api/customer-boards/${access_id}/delete`).then(
        (res) => {
          if (res) {
            //const { data } = res.data;
            setCustomerBoards(
              customerBoards.filter(
                (customerBoard) => customerBoard.id !== access_id
              )
            );
          }
        }
      );
    }
    // setCustomerBoards(
    //   customerBoards.filter((_board, _index) => _index !== index)
    // );
  };

  function handleChangeBoardData(event, fieldName, board) {
    if (fieldName === "email") {
      setCustomerBoards(
        customerBoards.map((_board) => {
          return _board.id === board.id
            ? { ..._board, email: event.target.value }
            : _board;
        })
      );
    }

    if (fieldName === "password") {
      setCustomerBoards(
        customerBoards.map((_board) => {
          return _board.id === board.id
            ? { ..._board, password: event.target.value }
            : _board;
        })
      );
    }

    if (fieldName === "api_key") {
      setCustomerBoards(
        customerBoards.map((_board) => {
          return _board.id === board.id
            ? { ..._board, api_key: event.target.value }
            : _board;
        })
      );
    }
  }

  const editCustomerDetail = () => {
    navigate(`/admin/customer/edit/${localStorage.getItem("customer_secret")}`);
  };

  const linkedInUploadS3 = (customer_secret) => {
    setIsDeleteLoading(true);
    ApiCalls.get(`/api/jobs/linkedin/s3/${customer_secret}`).then((res) => {
      setIsDeleteLoading(false);
      console.log(res);
    });
  };

  return (
    <div className="container">
      {/* {userRole &&
        <button  onClick={editCustomerDetail} style={{marginBottom:'20px' }} className="btn btn-primary">Update Customer</button>
      } */}

      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-primary">
            <div className="panel-heading">
              <h3
                style={{ textTransform: "capitalize" }}
                className="panel-title"
              >
                {customer.username ? customer.username : ""}
              </h3>
            </div>
            <small
              style={{
                marginLeft: "20px",
                marginTop: "10px",
                display: "inline-block",
                fontSize: "16px",
              }}
            >
              API Documentation
            </small>
            :&nbsp;&nbsp;
            <p style={{ display: "inline", fontSize: "16px" }}>
              <a
                href="https://beanbag-api.simplifycareers.com/swagger/"
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            </p>
            <div
              style={{
                display: "none",
                marginTop: "10px",
                marginBottom: "0px",
              }}
              className={`
                      ${
                        alert.status == "success"
                          ? "alert alert-success show"
                          : ""
                      }
                      ${
                        alert.status == "error" ? "alert alert-danger show" : ""
                      }`}
            >
              <span>{alert.message}</span>
            </div>
            <div className="panel-body">
              <form role="form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-xs-6" htmlFor="domain">
                        Domain
                      </label>
                      <input
                        readOnly
                        className="form-control"
                        id="domain"
                        type="text"
                        value={customer.domain}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-xs-6">API Access Key</label>
                      <input
                        readOnly
                        className="form-control"
                        type="text"
                        value={customer.api_key}
                        onChange={(event) =>
                          handleChangeCustomer(event, "api_key")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-xs-6" htmlFor="domain">
                        Customer ID
                      </label>
                      <input
                        readOnly
                        className="form-control"
                        id="domain"
                        type="text"
                        value={customer.customer_secret}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-xs-12 form-group"
                  style={{
                    marginTop: "10px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <table
                    id="datatable"
                    className="table table-striped table-bordered"
                  >
                    <thead>
                      <tr>
                        <th>Job Board</th>
                        <th>Email</th>
                        <th>Password</th>
                        <th>API Key</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerBoards.length < 1 && (
                        <tr>
                          <td colSpan={5} className="text-center">
                            No Job Boards found.
                          </td>
                        </tr>
                      )}
                      {customerBoards.map((board, index) => {
                        console.log("board", board);
                        return (
                          <>
                            <tr>
                              <td
                                style={{
                                  textTransform: "capitalize",
                                  color: "black",
                                }}
                              >
                                {board.name}
                              </td>
                              <td>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="inputEmail3"
                                  autoComplete="off"
                                  value={board.email}
                                  onChange={(event) =>
                                    handleChangeBoardData(event, "email", board)
                                  }
                                  placeholder=""
                                />
                              </td>
                              <td>
                                <input
                                  type="password"
                                  className="form-control"
                                  id="inputPassword3"
                                  autoComplete="off"
                                  value={board.password}
                                  onChange={(event) =>
                                    handleChangeBoardData(
                                      event,
                                      "password",
                                      board
                                    )
                                  }
                                  placeholder=""
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputPassword4"
                                  autoComplete="off"
                                  value={board.api_key}
                                  onChange={(event) =>
                                    handleChangeBoardData(
                                      event,
                                      "api_key",
                                      board
                                    )
                                  }
                                  placeholder=""
                                />
                              </td>
                              <td className="text-center">
                                {board.board_slug == "linkedin" ? (
                                  <a
                                    onClick={() =>
                                      linkedInUploadS3(customer.customer_secret)
                                    }
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {isDeleteLoading ? (
                                      <span className="btn btn-success btn-xs">
                                        Uploading...
                                      </span>
                                    ) : (
                                      <span className="btn btn-success btn-xs">
                                        Upload
                                      </span>
                                    )}
                                  </a>
                                ) : null}
                                {(board.board_type === "xml" ||
                                  board.board_type === null) && (
                                  <a
                                    className="btn btn-primary btn-xs"
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "10px",
                                    }}
                                    href={`https://beanbag-api.simplifycareers.com/api/jobs/${customer.customer_secret}/${board.name}/xml`}
                                    target="_blank"
                                    rel="noreferrer"
                                    title="XML"
                                  >
                                    XML
                                  </a>
                                )}
                                <a
                                  className="btn btn-danger btn-xs"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleDeleteBoard(board.id)}
                                  title="Delete"
                                >
                                  <i className="ti-trash"></i>
                                </a>
                              </td>

                              <span
                                style={{
                                  marginRight: "50px",
                                  marginBottom: "10px",
                                  display: "none",
                                }}
                              >
                                <h5>
                                  <span
                                    style={{ textTransform: "capitalize" }}
                                  ></span>
                                </h5>
                                <div className="row">
                                  <div className="form-group">
                                    <label>Username / Email</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={board.username}
                                      onChange={(event) =>
                                        handleChangeBoardData(
                                          event,
                                          "username",
                                          board
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label>Password</label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      value={board.password}
                                      onChange={(event) =>
                                        handleChangeBoardData(
                                          event,
                                          "password",
                                          board
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div
                                  className="row"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div className="col-xs-12 col-sm-12">
                                    <label>
                                      API Key / Company Id / Client Id
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={board.api_key}
                                      onChange={(event) =>
                                        handleChangeBoardData(
                                          event,
                                          "api_key",
                                          board
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </span>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                  <button
                    onClick={updateCustomerAccess}
                    style={{ marginTop: "13px" }}
                    type="button"
                    className="btn btn-primary pull-right"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
