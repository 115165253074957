import React from "react";
import Toggle from "react-styled-toggle";
import { useEffect, useState, useRef } from "react";
import ApiCalls from "../api/index";
import moment from "moment";
import { closeModelWindow } from "../helpers/helper.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "@mui/material/Pagination";

export default function JobBoardsPage() {
  const [jobBoards, setJobBoards] = useState([]);
  const [boardName, setBoardName] = useState("");
  // const [ apiUrl, setApiUrl ] = useState('');
  const [logo, setLogo] = useState("");
  const [boardType, setBoardType] = useState("X");
  const [boardStatus, setBoardStatus] = useState("A");
  const [boardId, setBoardId] = useState("");
  const [searchBoardName, setSearchBoardName] = useState("");

  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [popup, setPopup] = useState({ show: false, board_id: null });
  let appOrigin = localStorage.getItem("appOrigin");

  useEffect(() => {
    document.title = `Job Boards | ${ appOrigin == 'H' ? ("Direct Health") : appOrigin == 'N' ? ("Neurodiversify") : ("SimplifyHire") }`;
    getBoards();
    window.scrollTo({ top: 0 });
  }, [pageNumber, totalPage]);

  const ref = useRef();

  useEffect(() => {
    ref?.current?.scrollIntoView();
  }, [ref?.current, pageNumber]);

  const openBoardModal = () => {
    setBoardName("");
    // setApiUrl('')
    // setBoardType('')
    setLogo("");
  };

  const getBoards = () => {
    ApiCalls.get(
      `/api/v1/job-boards?search=${searchBoardName}&page=${pageNumber}`
    )
      .then((res) => {
        const { data } = res.data.data;
        setJobBoards(data);

        setPageNumber(res.data.data.page_number);
        setPageCount(res.data.data.page_count);
        setTotalCount(res.data.data.total_count);
        setTotalPage(res.data.data.total_pages);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveBoard = (e) => {
    e.preventDefault();

    var formData = new FormData();
    formData.append("name", boardName);
    // formData.append("api_url", apiUrl);
    formData.append("board_type", boardType);
    formData.append("logo", logo);
    formData.append("status", boardStatus);
    ApiCalls.post(`/api/v1/job-boards`, formData)
      .then((res) => {
        console.log(res.data.data);
        closeModelWindow();
        toast.success(res.data.message);
        setJobBoards([...jobBoards, res.data.data]);
        getBoards();
        setBoardName("");
        // setApiUrl('')
        // setBoardType('')
        setLogo("");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const editBoard = (board) => {
    console.log(board.job_board_id);
    setBoardId(board.job_board_id);
    setBoardName(board.name);
    // setApiUrl(board.api_url)
    setBoardType(board.board_type);
    setLogo(board.logo);
    setBoardStatus(board.status);
  };

  const updateBoard = (e) => {
    e.preventDefault();

    var formData = new FormData();
    formData.append("name", boardName);
    // formData.append("api_url", apiUrl);
    formData.append("board_type", boardType);
    if (logo) {
      formData.append("logo", logo);
    }
    formData.append("status", boardStatus);
    ApiCalls.put(`/api/v1/job-boards/${boardId}`, formData)
      .then((res) => {
        getBoards();
        closeModelWindow();
        toast.success(res.data.message);
        setJobBoards([...jobBoards, res.data.data]);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleDeleteBoard = (board_id) => {
    setPopup({
      show: true,
      board_id: board_id,
    });
  };

  const handleDeleteTrue = () => {
    if (popup.show && popup.board_id) {
      ApiCalls.delete(`/api/v1/job-boards/${popup.board_id}`)
        .then((res) => {
          setJobBoards(
            jobBoards.filter((board) => board.job_board_id !== popup.board_id)
          );
          closeModelWindow();
          getBoards();
          setPopup({
            show: false,
            board_id: null,
          });
          toast.success("Job board deleted successfully.");
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleDeleteFalse = () => {
    closeModelWindow();
    setPopup({
      show: false,
      board_id: null,
    });
  };

  function SubmitButton() {
    if (!boardId) {
      if (boardName && logo && boardType && boardStatus) {
        return (
          <button
            type="submit"
            onClick={saveBoard}
            className="btn model-post-btn btn-primary"
          >
            Add Job Board
          </button>
        );
      } else {
        return (
          <button
            type="submit"
            disabled
            onClick={saveBoard}
            className="btn model-post-btn btn-primary"
          >
            Add Job Board
          </button>
        );
      }
    }
    if (boardId) {
      if (boardName && boardType && boardStatus) {
        return (
          <button
            type="submit"
            onClick={updateBoard}
            className="btn model-post-btn btn-primary"
          >
            Update Job Board
          </button>
        );
      } else {
        return (
          <button
            disabled
            type="submit"
            onClick={updateBoard}
            className="btn model-post-btn btn-primary"
          >
            Update Job Board
          </button>
        );
      }
    }
  }

  const handlePageChange = (event, value) => {
    console.log(totalPage);
    console.log(totalCount);
    console.log(pageNumber);
    console.log(pageCount);
    setPageNumber(value);
  };

  const handleActivateDeactivateBoard = (job_board_id, status) => {
    var formData = new FormData();
    formData.append("status", status === "A" ? "I" : "A");
    ApiCalls.post(`/api/v1/job-boards/update/status/${job_board_id}`, formData)
      .then((res) => {
        setJobBoards(
          jobBoards.map((_board) => {
            return _board.job_board_id === job_board_id
              ? { ..._board, status: status === "A" ? "I" : "A" }
              : _board;
          })
        );
        toast.success(res.data.message);
      })
      .catch(function (error) {
        toast.success(error.data.message);
      });
  };

  function getStatusText(getStatus) {
    if (getStatus === "A") {
      return "Active";
    } else if (getStatus === "I") {
      return "Inactive";
    }
  }

  function getStatusBool(getStatus) {
    if (getStatus === "I") {
      return true;
    } else if (getStatus === "A") {
      return false;
    }
  }

  return (
    <>
      <div className="dash_content" ref={ref} style={{ height: "100vh" }}>
        <div className="table-wrapper">
          <div className="row job-head">
            <div className="col-md-2">
              <h5><span className='heading-text-color'>All Job Boards</span></h5>
            </div>
            <div className="col-md-10">
              <div className="input_right">
                <input
                  onChange={(e) => setSearchBoardName(e.target.value)}
                  type="text"
                  placeholder="Search"
                />
                <a
                  onClick={() => {
                    getBoards(1);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-search"></i>
                </a>
                <button
                  onClick={openBoardModal}
                  className="btn_add_job"
                  data-toggle="modal"
                  data-target="#exampleModal5"
                >
                  Add Job Board
                </button>
              </div>
            </div>
          </div>
          <div className="job_listing_table">
            <div className="row">
              <div className="col-md-12">
                <div className="table_inner table-responsive">
                  <table className="table dash_table">
                    <thead>
                      <tr className="tr_target">
                        <th scope="col" className="heading-job">
                          Board Name
                        </th>
                        {/* <th scope="col">Added on</th> */}
                        <th scope="col" className="center_heading">
                          Status
                        </th>
                        <th scope="col" className="center_heading">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobBoards.map((jobBoard, index) => {
                        return (
                          <>
                            <tr className="tr_target" key={index}>
                              <td className="th_target">
                                <span className="target_span">
                                  {index + 1}. &nbsp;
                                </span>{" "}
                                {jobBoard.name}
                              </td>
                              {/* <td className="td_target">{moment(jobBoard.created_at).format("MMM DD, YYYY")}</td> */}
                              <td className="center_heading td_target">
                                <Toggle
                                  onChange={() =>
                                    handleActivateDeactivateBoard(
                                      jobBoard.job_board_id,
                                      jobBoard.status
                                    )
                                  }
                                  checked={getStatusBool(jobBoard.status)}
                                  width={40}
                                  height={20}
                                  sliderWidth={12}
                                  sliderHeight={12}
                                  translate={19}
                                  backgroundColorChecked="#AFAFAF"
                                  backgroundColorUnchecked={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue("--primary-color")}
                                  labelRight={getStatusText(jobBoard.status)}
                                />
                              </td>
                              <td className="center_heading td_target">
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#exampleModal5"
                                >
                                  <i>
                                    <img
                                      src="/beanbag-assets/img/edit_black.png"
                                      onClick={() => editBoard(jobBoard)}
                                    />
                                  </i>
                                </a>
                                <a href="#">
                                  <i>
                                    <img
                                      src="/beanbag-assets/img/trash_black.png"
                                      onClick={() => {
                                        handleDeleteBoard(
                                          jobBoard.job_board_id
                                        );
                                      }}
                                      className="black_img"
                                      data-toggle="modal"
                                      data-target="#exampleModal4"
                                    />
                                  </i>
                                </a>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {totalCount >= 10 && (
            <div className="row">
              <div className="col-md-6">
                <p style={{ fontSize: "14px" }} className="s-p-l-ten">
                  Showing <span className="per-page-count-box">10</span> item
                  per page
                </p>
              </div>
              <div className="col-md-6">
                <div className="pagination">
                  <Pagination
                    count={totalPage}
                    page={pageNumber}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="modal fade post-job-model post-job-model_3"
        id="exampleModal5"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-content_ans">
            <div className="modal-header modal_head">
              {!boardId && <h5>Add Job Board</h5>}
              {boardId && <h5>Update Job Board</h5>}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form role="form">
              <div className="modal-body mid_modal mid_3">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Ender Job Board Name"
                  name="name"
                  id="name"
                  value={boardName}
                  onChange={(event) => setBoardName(event.target.value)}
                />
                {/* <label>URL</label>
                                <input
                                    type="text"
                                    placeholder="Enter User Name"
                                    name="api_url"
                                    id="api_url"
                                    value={apiUrl}
                                    onChange={(event) => setApiUrl(event.target.value) } /> */}
                <label>Logo</label>
                <input
                  type="file"
                  placeholder="Enter User Name"
                  name="logo"
                  id="logo"
                  onChange={(event) => setLogo(event.target.files[0])}
                />
                <div className="radio_sec">
                  <h6>Board Type</h6>
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="board_type"
                      id="inlineRadio1"
                      checked={boardType === "X"}
                      value="X"
                      onChange={(event) => setBoardType(event.target.value)}
                    />{" "}
                    XML
                  </label>
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="board_type"
                      id="inlineRadio2"
                      checked={boardType === "A"}
                      value="A"
                      onChange={(event) => setBoardType(event.target.value)}
                    />{" "}
                    API
                  </label>
                </div>
                <div className="radio_sec">
                  <h6>Status</h6>
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="status"
                      id="inlineRadio1"
                      checked={boardStatus === "A"}
                      value="A"
                      onChange={(event) => setBoardStatus(event.target.value)}
                    />{" "}
                    Active
                  </label>
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="status"
                      id="inlineRadio2"
                      checked={boardStatus === "I"}
                      value="I"
                      onChange={(event) => setBoardStatus(event.target.value)}
                    />{" "}
                    Inactive
                  </label>
                </div>
              </div>
              <div className="modal-footer all_modal-footer">
                <SubmitButton />
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <ToastContainer/> */}
      <div
        className="modal fade post-job-model post-job-model_2"
        id="exampleModal4"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-content_ans modal_yes">
            <div className="modal-header modal_head">
              <h5>Delete Job Board</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mid_modal">
              <p className="opening opening_4">
                Are you sure you want to delete the selected job board?
              </p>
              {popup.show && (
                <div className="answers">
                  <span onClick={handleDeleteFalse}>
                    <a href="#" className="sub_answer">
                      No
                    </a>
                  </span>
                  <span onClick={handleDeleteTrue}>
                    <a href="#" className="sub_answer_2">
                      Yes
                    </a>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
