import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Header from "../../components/header";
import ApiCalls from "../../api/index";
import "../../App.css";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export default function HomePage(props) {
  const [customer, setCustomer] = useState({
    email: "",
    password: "",
    subscription_end_date: "",
  });

  const [alert, setAlert] = useState({ status: "", message: "" });
  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {

  }, []);


  const handleChangeCustomer = (event, fieldName) => {
    if (fieldName === "email") {
      setCustomer((prevState) => {
        return { ...prevState, email: event.target.value };
      });
    }
    if (fieldName === "domain") {
      setCustomer((prevState) => {
        return { ...prevState, domain: event.target.value };
      });
    }
    if (fieldName === "password") {
      setCustomer((prevState) => {
        return { ...prevState, password: event.target.value };
      });
    }
    if (fieldName === "subscription_end_date") {
      setCustomer((prevState) => {
        return { ...prevState, subscription_end_date: event.target.value };
      });
    }
  };



  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    username: Yup.string().trim().required("Name is required"),
    email: Yup.string().email('Invalid email format').required("Email is required"),
    password: Yup.string().trim().required("Password is required")
  });

  const {
    register,
    handleSubmit,
    setValue: setValue1,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange"
  });

  const onSubmit = async (data) => {
    //setIsLoading(true);
    console.log('data', data);
    handleSubmitOld(data);
  };

  // sign up
  const handleSubmitOld = (data) => {
    // console.log(customer);
    ApiCalls.post(`/api/auth/register`, data).then((res) => {
      if (res) {
        const { data } = res.data;
        //navigate("/login", { state: "You have been successfully signed up. Please login." });
        setMessageSent(true);
        setAlert({ status: 'success', message: res.data.message });
      } else {
        setAlert({ status: 'error', message: res.data.message });
      }
    });
  };


  return (
<>
    <Header/>
<div className="coming-soon">
<div className="container" style={{ marginTop: '30px', paddingTop: '20px' }}>
<div className="row">
  <div
    className="panel panel-color panel-primary panel-pages"
    style={{ width: "30%", marginLeft: "auto", marginRight: "auto", marginTop: "10%" }}
  >
    <div className="panel-body">
      <h3 className="text-center m-t-0 m-b-30" style={{ display: 'none' }}>
        {" "}
        <span className="">
          <img src="assets/images/big/bigb.png" alt="logo" style={{ height: "auto", maxWidth: "170px" }} />
        </span>
      </h3>
      <h4 className="text-muted text-center m-t-0">
        <b>Sign Up</b>
      </h4>
      <form className="form-horizontal m-t-20" onSubmit={handleSubmit(onSubmit)} >
      <div  style={{ display: "none" }}
                    className={` 
                      ${ alert.status =="success"? "alert alert-success show": "" } 
                      ${ alert.status =="error"? "alert alert-danger show": "" }`}
              >
                <span>{alert.message}</span>
              </div>

            { messageSent === false &&
                <>
          <div className="form-group">
          <div className="col-xs-12">
            <input
              style={{ fontSize: '14px' }}
              type="text"
              required=""
              placeholder="Name"
              autoComplete="off"
              name="username"
              id="username"
              className={`form-control input-group m-b-0 ${
                errors.username ? "is-invalid" : ""
              }`}
              {...register("username")}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="col-xs-12">
            <input
              style={{ fontSize: '14px' }}
              type="email"
              required=""
              placeholder="Email"
              autoComplete="off"
              name="email"
              id="email"
              className={`form-control input-group m-b-0 ${
                errors.email ? "is-invalid" : ""
              }`}
              { ...register("email") }
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-xs-12">
            <input
              style={{ fontSize: '14px' }}
              type="password"
              required=""
              placeholder="Password"
              autoComplete="off"
              name="password"
              id="password"
              className={`form-control input-group m-b-0 ${
                errors.password ? "is-invalid" : ""
              }`}
              { ...register("password") }
            />
          </div>
        </div>
        <div className="form-group">
                <div className="row inline-form">
                <div className="col-xs-4">
                </div>
                <div className="col-xs-2" style={{ marginTop: "7px", marginLeft: "290px" }}>
                    <Link to="/login" style={{ textDecoration: 'underline' }}>Login</Link>
                </div>
                </div>

              </div>
        <div className="form-group text-center m-t-20">
          <div className="col-xs-12">
            {" "}
            <button type="submit"
              className="btn btn-primary w-md waves-effect waves-light"
              style={{ fontSize: '17px' }}
            >
              Sign Up
            </button>
          </div>
        </div>
        </>
        }
        <div className="form-group m-t-30 m-b-0">
          <div className="col-sm-7"></div>
          <div className="col-sm-5 text-right"> </div>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
</div>
</>


  );
}
