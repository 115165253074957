import React from "react";
import { useEffect, useState, useRef } from "react";
import Toggle from "react-styled-toggle";
import moment from "moment";
import ApiCalls from "../api/index";
import Pagination from "@mui/material/Pagination";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import XMLViewer from "react-xml-viewer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "400px",
  minWidth: "800px",
  overflowX: "auto !important",
  overflowY: "auto !important",
};

export default function JobLogsPage() {
  const [jobLogsArray, setJobLogs] = useState([]);

  const [searchJobTitle, setSearchJobTitle] = useState("");

  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [xmlData, setXmlData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  let appOrigin = localStorage.getItem("appOrigin");

  useEffect(() => {
    document.title = `Jobs Logs | ${ appOrigin == 'H' ? ("Direct Health") : appOrigin == 'N' ? ("Neurodiversify") : ("SimplifyHire") }`;
    getJobLogs();
    window.scrollTo({ top: 0 });
 }, []);

  const ref = useRef();

  useEffect(() => {
    ref?.current?.scrollIntoView();
  }, [ref?.current]);

  const getJobLogs = () => {
    ApiCalls.get(`/api/v1/job-logs?search=${searchJobTitle}&page=${pageNumber}`)
      .then((res) => {
        const { data } = res.data.data;
        setJobLogs(data);
        setPageNumber(res.data.data.page_number);
        setPageCount(res.data.data.page_count);
        setTotalCount(res.data.data.total_count);
        setTotalPage(res.data.data.total_pages);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const handleActiveInactiveJobLogsStatus = (log_id, status) => {
    var formData = new FormData();
    formData.append("status", status === "P" ? "I" : "P");
    ApiCalls.post(`/api/v1/job-logs/update/status/${log_id}`, formData)
      .then((res) => {
        setJobLogs(
          jobLogsArray.map((_jobLog) => {
            return _jobLog.log_id === log_id
              ? { ..._jobLog, status: status === "P" ? "I" : "P" }
              : _jobLog;
          })
        );
        toast.success(res.data.message);
      })
      .catch(function (error) {
        toast.success(error.data.message);
      });
  };

  function getStatusBool(getStatus) {
    if (getStatus === "I") {
      return true;
    } else if (getStatus === "A") {
      return false;
    }
  }

  function getStatusText(status) {
    if (status === "P") {
      return "Pending";
    } else if (status === "C") {
      return "Posting Completed";
    } else if (status === "R") {
      return "Posting Rejected";
    } else if (status === "I") {
      return "Inactive";
    } else if (status === "A") {
      return "Pending";
    }
  }

  const handleModalOpen = (row) => {
    setXmlData(row);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function returnBeautifyString(str) {
    try {
      const a = JSON.parse(str);
      return JSON.stringify(a, null, 2);
    } catch (e) {
      return str;
    }
  }

  const modeStatus = localStorage.getItem("mode");

  return (
    <>
      <div className="dash_content" ref={ref}>
        <div className="table-wrapper">
          <div className="row job-head">
            <div className="col-md-2">
              <h5><span className='heading-text-color'>Job Logs</span></h5>
            </div>
            <div className="col-md-10">
              <div className="input_right">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchJobTitle(e.target.value)}
                />
                <a
                  onClick={() => {
                    getJobLogs(1);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i>
                    <img
                      className="fa-search"
                      src="/beanbag-assets/img/search-icon.svg"
                    />
                  </i>
                </a>
              </div>
            </div>
          </div>
          <div className="job_listing_table">
            <div className="row">
              <div className="col-md-12">
                <div className="table_inner table-responsive">
                  <table className="table dash_table dash_table_2">
                    <thead>
                      <tr className="tr_target">
                        <th width="2%" scope="col"></th>
                        <th scope="col" className="heading-job">
                          Job Title
                        </th>
                        <th scope="col" className="heading-job">
                          Job Board
                        </th>
                        <th scope="col">Posted Date</th>
                        <th scope="col" className="center_heading">
                          Status
                        </th>
                        <th className="center_heading">Active</th>
                        <th scope="col">Action</th>
                        {/* {modeStatus && modeStatus === "L" ? (
                          <th scope="col"></th>
                        ) : (
                          ""
                        )} */}
                      </tr>
                    </thead>
                    <tbody>
                      {jobLogsArray?.map((jobLog, index) => {
                        return (
                          <>
                            <tr className="tr_target" key={index}>
                              <td className="th_target">
                                <span className="target_span">
                                  {index + 1}. &nbsp;
                                </span>
                              </td>
                              <td className="th_target">{jobLog.job_title}</td>
                              <td className="th_target">
                                <span className="target_span">
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>{" "}
                                {jobLog.job_board_name}
                              </td>
                              <td className="td_target">
                                {moment(jobLog.request_timestamp).format(
                                  "MMM DD, YYYY"
                                )}
                              </td>
                              <td className="td_target center_heading">
                                <span
                                  className={`label
                                                   ${
                                                     jobLog.status === "C"
                                                       ? "label-success"
                                                       : jobLog.status === "R"
                                                       ? "label-danger"
                                                       : jobLog.status === "I"
                                                       ? "label-default"
                                                       : "label-info"
                                                   }`}
                                >
                                  {getStatusText(jobLog.status)}
                                </span>
                              </td>
                              <td className="center_heading td_target">
                                <Toggle
                                  onChange={() =>
                                    handleActiveInactiveJobLogsStatus(
                                      jobLog.log_id,
                                      jobLog.status
                                    )
                                  }
                                  checked={getStatusBool(jobLog.status)}
                                  width={40}
                                  height={20}
                                  sliderWidth={12}
                                  sliderHeight={12}
                                  translate={19}
                                  backgroundColorChecked="#AFAFAF"
                                  backgroundColorUnchecked={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue("--primary-color")}
                                />
                              </td>
                              <td className="td_target">
                                <i
                                  onClick={() =>
                                    handleModalOpen(jobLog.request_payload)
                                  }
                                  title="View Request Payload"
                                  style={{
                                    marginRight: "7px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img src="/beanbag-assets/img/icon-img.png" />
                                </i>
                                <a
                                  onClick={() =>
                                    handleModalOpen(jobLog.request_payload)
                                  }
                                  title="View Request Payload"
                                  style={{
                                    cursor: "pointer",
                                    color: "#5469CC",
                                  }}
                                >
                                  View Payload
                                </a>
                              </td>
                              {/* {modeStatus &&
                              modeStatus === "L" &&
                              jobLog.status === "I" ? (
                                <td
                                  className="disk"
                                  style={{
                                    display: "flex",
                                    marginTop: "20px",
                                    color: "#5469CC",
                                  }}
                                >
                                  <i
                                    style={{
                                      marginRight: "7px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img src="/beanbag-assets/img/icon-img.png" />
                                  </i>
                                  <span style={{ cursor: "pointer" }}>
                                    Repost
                                  </span>
                                </td>
                              ) : (
                                ""
                              )} */}
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {totalCount >= 10 && (
            <div className="row">
              <div className="col-md-6">
                <p className="s-p-l-ten">
                  Showing <span className="per-page-count-box">10</span> item
                  per page
                </p>
              </div>
              <div className="col-md-6">
                <div className="pagination">
                  <Pagination
                    count={totalPage}
                    page={pageNumber}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="modal fade post-job-model post-job-model_2"
        id="exampleModal3"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header modal_head">
              <h5>View Response</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body mid_modal">
              <div className="badging">
                <p>
                  <b>Issue</b>
                  <span className="badge">3</span>
                </p>
              </div>
              <p className="opening">No. of opening is missing</p>
              <div className="view_r">
                <i className="fa fa-times"></i>
                <span className="mid_span">Failed</span>
              </div>
            </div>
            <div className="modal-footer">
              <p className="opening opening_2">No. of opening is missing</p>
              <div className="view_r">
                <i className="fa fa-times"></i>
                <span className="mid_span">Failed</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer/> */}
      <div
        className="table-responsive"
        style={{ height: "auto", display: "none" }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {IsJsonString(xmlData) ? (
              <pre>{returnBeautifyString(xmlData)}</pre>
            ) : (
              <XMLViewer xml={xmlData} />
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
}
