import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as JobChoices from "../Services/JobChoices";
import ApiCalls from "../api/index";
import "../App.css";
import axios from "axios";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CKEditor from "react-ckeditor-component";

export default function EditBoard(props) {
  const [alert, setAlert] = useState({ status: "", message: "" });
  const [messageSent, setMessageSent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [description, setDescription] = useState("");

  const validationSchema = Yup.object().shape({
    title: Yup.string().trim().required("Job Title is required"),
    job_level: Yup.string().required("Job Level is required"),
    compensation: Yup.string().trim().required("Compensation is required"),
    industry: Yup.string().trim().required("Industry is required"),
    salary_currency: Yup.string()
      .trim()
      .required("Salary Currency is required"),
    job_occupation: Yup.string().trim().required("Job Occupation is required"),
    salary_min: Yup.string().trim().required("Salary Min is required"),
    salary_max: Yup.string().trim().required("Salary Mmax is required"),
    employee_type: Yup.string().trim().required("Employee Type is required"),
    requirements: Yup.string().trim().required("Requirements is required"),
    salary_frequency: Yup.string()
      .trim()
      .required("Salary Frequency is required"),
    job_type: Yup.string().trim().required("Job Type is required"),
    job_category: Yup.string().trim().required("Job Category is required"),
    // job_status: Yup.string().trim().required("job_status is required"),
    experience: Yup.string().trim().required("Experience is required"),
    // education: Yup.string().trim().required("Education is required"),
    url: Yup.string().trim().required("Url is required"),
    apply_url: Yup.string().trim().required("Apply Url is required"),
    company_name: Yup.string().trim().required("Company Name is required"),
    address: Yup.string().trim().required("Address is required"),
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().trim().required("State is required"),
    country: Yup.string().trim().required("Country is required"),
    countryCode: Yup.string().trim().required("CountryCode is required"),
    postal_code: Yup.string().trim().required("Postal code is required"),
    status: Yup.string().trim().required("Status is required"),
    job_random_number: Yup.string()
      .trim()
      .required("Job Random Number is required"),
    job_reference_number: Yup.string()
      .trim()
      .required("JobReference Number is required"),
    source_job_id: Yup.string().trim().required("Source Job Id is required"),
    description: Yup.string().trim().required("Description is required"),
  });

  const {
    register,
    handleSubmit,
    setValue: setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  let navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    ApiCalls.get(`/api/jobs/edit/${params.job_id}`)
      .then((res) => {
        setIsLoading(false);
        var _job = res.data.data;
        setDescription(_job.description);
        const fields = [
          "title",
          "job_level",
          "compensation",
          "industry",
          "salary_currency",
          "job_occupation",
          "salary_min",
          "salary_max",
          "employee_type",
          "requirements",
          "salary_frequency",
          "job_type",
          "job_category",
          "job_status",
          "experience",
          "education",
          "url",
          "apply_url",
          "company_name",
          "address",
          "city",
          "state",
          "country",
          "countryCode",
          "postal_code",
          "status",
          "job_random_number",
          "job_reference_number",
          "customer_id",
          "source_job_id",
          "description",
        ];
        fields.forEach((field) => setValue(field, _job[field]));
      })
      .catch((err) => {
        setAlert({ status: "error", message: err.response.data.error });
      });
  }, []);

  const handleChangeJobDescription = (event, fieldName) => {
    if (fieldName === "description") {
      setDescription((prevState) => {
        return { ...prevState, description: event.target.value };
      });
    }
  };

  const onSubmit = async (data) => {
    handleSubmitJob(data);
  };

  const handleSubmitJob = (data) => {
    ApiCalls.post(`/api/jobs/update/${params.job_id}`, data)
      .then((res) => {
        const { data } = res.data;
        setMessageSent(true);
        console.log(data);
        navigate("/admin/jobs");
        setAlert({ status: "success", message: res.data.message });
      })
      .catch((err) => {
        setAlert({ status: "error", message: err.response.data.error });
      });
  };

  return (
    <>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <h3 className="panel-title">Edit Job</h3>
        </div>
        {/*<div  style={{ display: "none", marginTop:"10px"}}
          className={`
          ${ alert.status =="success"? "alert alert-success show": "" }
          ${ alert.status =="error"? "alert alert-danger show": "" }`}
          >
          <span>{alert.message}</span>
        </div>*/}
        <div className="panel-body">
          <form role="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">Job Information</h3>
              </div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Job Title</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Job Title"
                        name="title"
                        id="title"
                        className={`form-control input-group m-b-0 ${
                          errors.title ? "is-invalid" : ""
                        }`}
                        {...register("title")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Requirements</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Requirements"
                        name="requirements"
                        id="requirements"
                        className={`form-control input-group m-b-0 ${
                          errors.requirements ? "is-invalid" : ""
                        }`}
                        {...register("requirements")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Job Ref#</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Job Reference Number"
                        name="job_reference_number"
                        id="job_reference_number"
                        className={`form-control input-group m-b-0 ${
                          errors.job_reference_number ? "is-invalid" : ""
                        }`}
                        {...register("job_reference_number")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Source Job ID</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Source Job ID"
                        name="source_job_id"
                        id="source_job_id"
                        className={`form-control input-group m-b-0 ${
                          errors.source_job_id ? "is-invalid" : ""
                        }`}
                        {...register("source_job_id")}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Description</label>
                      <CKEditor
                        activeClass="p10"
                        content={description}
                        value={description}
                        onChange={(event) =>
                          handleChangeJobDescription(event, "description")
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Job Category</label>
                      <select
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Job Category"
                        name="job_category"
                        id="job_category"
                        className={`form-control input-group m-b-0 ${
                          errors.job_category ? "is-invalid" : ""
                        }`}
                        {...register("job_category")}
                      >
                        <option value="">Select</option>
                        {JobChoices.JobCategories &&
                          JobChoices.JobCategories.map((value, index) => {
                            return <option key={index} value={value}>{value}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Job Occupation</label>
                      <select
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Job Occupation"
                        name="job_occupation"
                        id="job_occupation"
                        className={`form-control input-group m-b-0 ${
                          errors.job_occupation ? "is-invalid" : ""
                        }`}
                        {...register("job_occupation")}
                      >
                        <option value="">Select</option>
                        {JobChoices.JobOccupations &&
                          JobChoices.JobOccupations.map((value, index) => {
                            return <option key={index} value={value}>{value}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Education</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        placeholder="Education"
                        name="education"
                        id="education"
                        className={`form-control input-group m-b-0 ${
                          errors.education ? "is-invalid" : ""
                        }`}
                        {...register("education")}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Experience</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Experience"
                        name="experience"
                        id="experience"
                        className={`form-control input-group m-b-0 ${
                          errors.experience ? "is-invalid" : ""
                        }`}
                        {...register("experience")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Apply URL</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Apply URL"
                        name="apply_url"
                        id="apply_url"
                        className={`form-control input-group m-b-0 ${
                          errors.apply_url ? "is-invalid" : ""
                        }`}
                        {...register("apply_url")}
                      />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Job Type</label>
                      <select
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Job Type"
                        name="job_type"
                        id="job_type"
                        className={`form-control input-group m-b-0 ${
                          errors.job_type ? "is-invalid" : ""
                        }`}
                        {...register("job_type")}
                      >
                        <option value="">Select</option>
                        {JobChoices.JobTypes &&
                          JobChoices.JobTypes.map((value, index) => {
                            return <option key={index} value={value}>{value}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Employee Type</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Employee Type"
                        name="employee_type"
                        id="employee_type"
                        className={`form-control input-group m-b-0 ${
                          errors.employee_type ? "is-invalid" : ""
                        }`}
                        {...register("employee_type")}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Job Level</label>
                      <select
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Job Level"
                        name="job_level"
                        id="job_level"
                        className={`form-control input-group m-b-0 ${
                          errors.job_level ? "is-invalid" : ""
                        }`}
                        {...register("job_level")}
                      >
                        <option value="">Select</option>
                        {JobChoices.JobLevels &&
                          JobChoices.JobLevels.map((value, index) => {
                            return <option key={index} value={value}>{value}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Compensation</label>
                      <select
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Compensation"
                        name="compensation"
                        id="compensation"
                        className={`form-control input-group m-b-0 ${
                          errors.compensation ? "is-invalid" : ""
                        }`}
                        {...register("compensation")}
                      >
                        <option value="">Select</option>
                        {JobChoices.Compensations &&
                          JobChoices.Compensations.map((value, index) => {
                            return <option key={index} value={value}>{value}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">Company</h3>
              </div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Company Name</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Company Name"
                        name="company_name"
                        id="company_name"
                        className={`form-control input-group m-b-0 ${
                          errors.company_name ? "is-invalid" : ""
                        }`}
                        {...register("company_name")}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Industry</label>
                      <select
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Industry"
                        name="industry"
                        id="industry"
                        className={`form-control input-group m-b-0 ${
                          errors.industry ? "is-invalid" : ""
                        }`}
                        {...register("industry")}
                      >
                        <option value="">Select</option>
                        {JobChoices.Industries &&
                          JobChoices.Industries.map((value, index) => {
                            return <option key={index} value={value}>{value}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group">
                      <label htmlFor="">URL</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="URL"
                        name="url"
                        id="url"
                        className={`form-control input-group m-b-0 ${
                          errors.url ? "is-invalid" : ""
                        }`}
                        {...register("url")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">Salary</h3>
              </div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Salary Currency</label>
                      <select
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Salary Currency"
                        name="salary_currency"
                        id="salary_currency"
                        className={`form-control input-group m-b-0 ${
                          errors.salary_currency ? "is-invalid" : ""
                        }`}
                        {...register("salary_currency")}
                      >
                        <option value="">Select</option>
                        {JobChoices.SalaryCurrencies &&
                          JobChoices.SalaryCurrencies.map((value, index) => {
                            return <option key={index} value={value}>{value}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Salary Frequency</label>
                      <select
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Salary Frequency"
                        name="salary_frequency"
                        id="salary_frequency"
                        className={`form-control input-group m-b-0 ${
                          errors.salary_frequency ? "is-invalid" : ""
                        }`}
                        {...register("salary_frequency")}
                      >
                        <option value="">Select</option>
                        {JobChoices.SalaryFrequencies &&
                          JobChoices.SalaryFrequencies.map((value, index) => {
                            return <option key={index} value={value}>{value}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Salary Min</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Salary Min"
                        name="salary_min"
                        id="salary_min"
                        className={`form-control input-group m-b-0 ${
                          errors.salary_min ? "is-invalid" : ""
                        }`}
                        {...register("salary_min")}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Salary Max</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Salary Max"
                        name="salary_max"
                        id="salary_max"
                        className={`form-control input-group m-b-0 ${
                          errors.salary_max ? "is-invalid" : ""
                        }`}
                        {...register("salary_max")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">Location</h3>
              </div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">City</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="City"
                        name="city"
                        id="city"
                        className={`form-control input-group m-b-0 ${
                          errors.city ? "is-invalid" : ""
                        }`}
                        {...register("city")}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">State</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="State"
                        name="state"
                        id="state"
                        className={`form-control input-group m-b-0 ${
                          errors.state ? "is-invalid" : ""
                        }`}
                        {...register("state")}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Country</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Country"
                        name="country"
                        id="country"
                        className={`form-control input-group m-b-0 ${
                          errors.country ? "is-invalid" : ""
                        }`}
                        {...register("country")}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Country Code</label>
                      <select
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Country Code"
                        name="countryCode"
                        id="countryCode"
                        className={`form-control input-group m-b-0 ${
                          errors.countryCode ? "is-invalid" : ""
                        }`}
                        {...register("countryCode")}
                      >
                        <option value="">Select</option>
                        {JobChoices.CountryCodes &&
                          JobChoices.CountryCodes.map((value, index) => {
                            return <option key={index} value={value}>{value}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="">Postal Code</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Postal Code"
                        name="postal_code"
                        id="postal_code"
                        className={`form-control input-group m-b-0 ${
                          errors.postal_code ? "is-invalid" : ""
                        }`}
                        {...register("postal_code")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <div className="form-group">
                      <label htmlFor="">Address</label>
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        required=""
                        placeholder="Address"
                        name="address"
                        id="address"
                        className={`form-control input-group m-b-0 ${
                          errors.address ? "is-invalid" : ""
                        }`}
                        {...register("address")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary pull-right">
              Update
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
