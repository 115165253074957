import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function TermsAndPrivacyPage() {
  let appOrigin = localStorage.getItem("appOrigin");
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.title = `Terms And Policy | ${
      appOrigin == "H"
        ? "Direct Health"
        : appOrigin == "N"
        ? "Neurodiversify"
        : "SimplifyHire"
    }`;
    document.querySelector("body").classList.add("front_end_body");
  }, []);
  return (
    <>
      <div className="main_first_navbar privacy_p_nav">
        <a href="#" className="logo-ben">
          <Link to="/">
            <img src="/beanbag-assets/img/BeanBag.png" alt="" />
          </Link>
        </a>
        <div className="panel-heading panel-heading-nav">
          <ul className="nav nav-tabs font-weight-600">
            <li className="active">
              <a
                className="tab_heading"
                href="#one"
                aria-controls="one"
                role="tab"
                data-toggle="tab"
              >
                Terms of Use
              </a>
            </li>
            <li>
              <a href="#two" aria-controls="two" role="tab" data-toggle="tab">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
      <section className="content_sec_privacy">
        <div className="container-fluid">
          <div className="panel panel-default">
            <div className="panel-body">
              <div className="tab-content">
                <div
                  role="tabpanel"
                  className="tab-pane fade in active"
                  id="one"
                >
                  <div className="privacy_tab_1">
                    <h3 className="term_heading f-24">Terms of Use</h3>
                    <p>
                      Simplify Hire and its related products and services at
                      simplifyhire.com, BeanBag, Direct Sourcing as a Service,
                      etc. (collectively <b>“Simplify Hire”</b>) is a service of
                      Simplify Workforce Inc., a Delaware corporation{" "}
                      <b>(“Simplify”)</b>. Simplify Hire helps people and
                      businesses become successful and reach their full
                      potential through our innovative talent solutions.
                    </p>
                    <p>
                      These terms and conditions (the “Terms of Use,” “Terms”)
                      set forth the provisions under which all job seekers,
                      candidates, employers, and other users (“you”) may use
                      Simplify Hire. These Terms govern your use of Simplify
                      Source and the products, features, apps, services,
                      technologies, and software we offer (the “Simplify Hire
                      Services” or “Services”) except where we expressly state
                      that separate terms (and not these) apply. The Simplify
                      Source Services are provided to you by Simplify and
                      Simplify Hire. You agree to be bound by these terms and
                      conditions for use of the services. Be sure to check this
                      page periodically for updates, as your continued use of
                      Simplify Hire signifies your acceptance of any changed
                      terms.
                    </p>
                    <p>
                      You may not use the Simplify Hire Services to determine a
                      consumer’s eligibility for: (a) credit or insurance for
                      personal, family, or household purposes; (b) employment;
                      or (c) a government license or benefit.
                    </p>
                    <h4 className="policy_head_tab">
                      1. Use of Simplify Content
                    </h4>
                    <p>
                      Simplify authorizes you, subject to these Terms, to access
                      and use Simplify Hire and the Simplify Source Services to
                      download and print a single copy of the content for your
                      personal, non-commercial use. The contents of Simplify
                      Hire, such as designs, text, graphics, images, video,
                      information, logos, button icons, software, audio files,
                      and other Simplify content (collectively, “Simplify
                      Content”), are protected under copyright, trademark, and
                      other laws. Unauthorized use of the Simplify Content may
                      violate these laws and/or applicable communications
                      statutes and regulations, and is strictly prohibited. You
                      must preserve all copyright, trademark, service mark, and
                      other proprietary notices contained in the original
                      Simplify Content on any authorized copy you make of the
                      Simplify Content.
                    </p>
                    <p>
                      Any code that Simplify creates to generate or display any
                      Simplify Content or pages making up any component or whole
                      of Simplify Hire is also protected by Simplify’s copyright
                      and you may not copy or adapt such code.
                    </p>
                    <p>
                      You agree not to sell or modify the Simplify Content or
                      reproduce, display, publicly perform, distribute, or
                      otherwise use the Simplify Content in any way for any
                      public or commercial purpose, in connection with products
                      or services that are not those of Simplify Source, in any
                      manner that is likely to cause confusion among consumers,
                      that disparages or discredits Simplify or its licensors,
                      that dilutes the strength of Simplify’s or its licensor’s
                      property, or that otherwise infringes Simplify’s or its
                      licensor’s intellectual property rights. You further agree
                      to in no other way misuse Simplify Content. The use of the
                      Simplify Content on any other application, website, or in
                      a networked computer environment for any purpose is also
                      prohibited. Any code that Simplify creates to generate or
                      display any Simplify Content or the pages making up any
                      component or element of Simplify Hire is also protected by
                      Simplify’s copyright and you may not copy or adapt such
                      code.
                    </p>
                    <h4 className="policy_head_tab">2. Use of Simplify Hire</h4>
                    <p>
                      Simplify Hire offers job requisitions and job
                      advertisements `(“job database”), a profile database (also
                      sometimes called a resume database), including but not
                      limited to specialized diversity and veteran databases, if
                      applicable (“profile database”), which may only be used by
                      persons seeking employment and/or career information
                      (“candidates”) and by businesses, organizations,
                      institutions, or other entities seeking employees
                      (“employers”). Furthermore, creating a profile on Simplify
                      Hire may be used by individuals for permitted professional
                      and personal networking purposes. Your use of Simplify
                      Hire is also subject to any Master Services Agreement
                      (“MSA”) or other contracts you may have with Simplify. In
                      the case of any conflict between these Terms and any MSA
                      or contract you have with Simplify, the terms of your MSA
                      or contract will prevail.
                    </p>
                    <p>
                      You are responsible for the confidentiality and security
                      of your own account, including not sharing your password,
                      giving access to your Simplify Source account to others,
                      or transferring your account to anyone else without our
                      permission. As such, you are responsible for all uses of
                      your Simplify Hire account, whether or not authorized by
                      you. You agree to immediately notify Simplify of any
                      unauthorized use of your account, profile, or passwords.
                    </p>
                    <h5>All Simplify Hire users agree to not:</h5>
                    <div className="pointed_sec ml-35">
                      <div className="sub_point">
                        <span>a.</span>
                        <p>
                          transmit, post, distribute, store, or destroy
                          material, including without limitation Simplify
                          Content, in violation of any applicable law or
                          regulation, including but not limited to laws or
                          regulations governing the collection, processing, or
                          transfer of personal information, or in breach of
                          Simplify’s Privacy Policy;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>b.</span>
                        <p>
                          take any action that imposes an unreasonable or
                          disproportionately large load on any Simplify
                          infrastructure;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>c.</span>
                        <p>
                          use any device to navigate or search any Simplify site
                          other than the tools available on Simplify Source,
                          generally available to third party web browsers, or
                          other tools approved by Simplify;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>d.</span>
                        <p>
                          use any data mining, robots, or similar data gathering
                          or extraction methods;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>e.</span>
                        <p>
                          violate or attempt to violate the security of any
                          Simplify site including attempting to probe, scan, or
                          test the vulnerability of a system or network or to
                          breach security or authentication measures without
                          proper authorization;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-35">f.</span>
                        <p>
                          forge any TCP/IP packet header or any part of the
                          header information in any e-mail or newsgroup posting;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>g.</span>
                        <p>
                          reverse engineer or decompile any parts of any
                          Simplify site;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>h.</span>
                        <p>
                          aggregate, copy, or duplicate in any manner any of the
                          Simplify Content or information available from
                          Simplify Hire, including expired job ads, other than
                          as permitted by these Terms;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-35">i.</span>
                        <p>
                          frame or link to any Simplify Content or information
                          available from any Simplify site, unless permitted by
                          these Terms;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-35">j.</span>
                        <p>
                          post any content or material that promotes or endorses
                          false or misleading information or illegal activities,
                          or endorses or provides instructional information
                          about illegal activities or other activities
                          prohibited by these Terms, such as making or buying
                          illegal weapons, violating someone’s privacy,
                          providing or creating computer viruses, or pirating
                          media;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>k.</span>
                        <p>
                          post any resume or profile or apply for any job on
                          behalf of another part;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-35">l.</span>
                        <p>
                          defer any contact from an employer to any agent,
                          agency, or other third party;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-25">m.</span>
                        <p>
                          set more than one copy of the same resume to public at
                          any one time;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>n.</span>
                        <p>
                          share with a third party any login credentials to any
                          Simplify site;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>o.</span>
                        <p>
                          access data not intended for you or logging into a
                          server or account which you are not authorized to
                          access;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>p.</span>
                        <p>
                          post or submit to any Simplify site any incomplete,
                          false, or inaccurate biographical information or
                          information which is not your own;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>q.</span>
                        <p>
                          post content that contains restricted or password-only
                          access pages, or hidden pages or images;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-33">r.</span>
                        <p>
                          solicit passwords or personally identifiable
                          information from other users;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>s.</span>
                        <p>
                          delete or alter any material posted by any other
                          person or entity;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-33">t.</span>
                        <p>
                          harass, incite harassment, or advocate harassment of
                          any group, company, or individual;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>u.</span>
                        <p>
                          send unsolicited mail or email, make unsolicited phone
                          calls or send unsolicited messages promoting and/or
                          advertising products or services to any user, or
                          contact any users that have specifically requested not
                          to be contacted by you;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>v.</span>
                        <p>
                          attempt to interfere with service to any user, host,
                          or network, including, without limitation, via means
                          of submitting a virus to any Simplify site,
                          overloading, “flooding,” “spamming,” “mailbombing,” or
                          “crashing”;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-27">w.</span>
                        <p>
                          promote or endorse an illegal or unauthorized copy of
                          another person’s copyrighted work, such as providing
                          or making available pirated computer programs or links
                          to them, provided or making available information to
                          manufacture installed copy-protect devices, or
                          providing or making available pirated music or other
                          media or links to pirated music or other media files;
                          or,
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>x.</span>
                        <p>
                          use Simplify Hire for any unlawful purpose or any
                          illegal activity, or post or submit any content,
                          resume, or job ad that is defamatory, libellous,
                          implicitly or explicitly offensive, vulgar, obscene,
                          threatening, abusive, hateful, racist, discriminatory,
                          of a menacing character or likely to cause annoyance,
                          inconvenience, embarrassment, anxiety, or could cause
                          harassment to any person or include any links to
                          pornographic, indecent, or sexually explicit material
                          of any kind, as determined by Simplify’s sole
                          discretion.
                        </p>
                      </div>
                    </div>
                    <p>
                      Violation of system or network security may result in
                      civil and/or criminal liability. Simplify will investigate
                      occurrences which may involve such violations and may
                      involve, and cooperate with, law enforcement authorities
                      in prosecuting users who are involved in such violations.
                    </p>
                    <h4 className="policy_head_tab">
                      3. Additional Terms Applicable to Employers
                    </h4>
                    <p>
                      Employers are solely responsible for their postings on
                      Simplify Hire. Simplify is not to be considered to be an
                      employer with respect to your use of any Simplify site,
                      including Simplify Source, and Simplify shall not be
                      responsible for any employment decisions, for whatever
                      reason, made by any entity posting jobs on any Simplify
                      site.
                    </p>
                    <p>
                      You understand and acknowledge that if you cancel your
                      employer account or your employer account is terminated,
                      all of your account information from Simplify, including
                      Simplify Hire resumes, network contacts, and profiles,
                      will be marked as deleted and may be deleted from
                      Simplify’s databases. Information may continue to be
                      available for some period of time due to delays in
                      propagating such deletion through Simplify systems.
                    </p>
                    <p>
                      In order to protect Simplify users from commercial
                      advertising or solicitation, Simplify reserves the right
                      to restrict the number of e-mails or messages which an
                      employer may send to users to a number which Simplify
                      deems appropriate in its sole discretion. You shall use
                      Simplify Hire in accordance with all applicable privacy
                      and data protection laws.
                    </p>
                    <h5>Job ads may not contain:</h5>
                    <div className="pointed_sec ml-35">
                      <div className="sub_point">
                        <span>a.</span>
                        <p>
                          any hyperlinks, other than those specifically
                          authorized by Simplify;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>b.</span>
                        <p>
                          misleading, unreadable, or “hidden” keywords or
                          keywords that are irrelevant to the job opportunity
                          being presented, as determined in Simplify’s
                          reasonable discretion;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>c.</span>
                        <p>
                          the names, logos, or trademarks of unaffiliated
                          companies other than those of your customer save where
                          expressly agreed by Simplify;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>d.</span>
                        <p>
                          the names of colleges, cities, states, towns, or
                          countries that are unrelated to the posting;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>e.</span>
                        <p>more than one job or job description;</p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-35">f.</span>
                        <p>
                          more than one location, or more than one job category,
                          unless so allowed by Simplify Hire;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>g.</span>
                        <p>
                          inaccurate, false, or misleading information; and,
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>h.</span>
                        <p>
                          material or links to material that exploits people in
                          a sexual, violent, or other manner, or solicits
                          personal information from anyone under 18.
                        </p>
                      </div>
                    </div>
                    <h5>
                      You may not use your Simplify Hire job ad or profiles to:
                    </h5>
                    <div className="pointed_sec">
                      <div className="sub_point">
                        <span>a.</span>
                        <p>
                          post jobs in a manner that does not comply with
                          applicable local, national, and international laws,
                          including but not limited to laws relating to labor
                          and employment, equal employment opportunity and
                          employment eligibility requirements, data privacy,
                          data access and use, and intellectual property;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>b.</span>
                        <p>
                          post jobs that require citizenship of any particular
                          country or lawful permanent residence in a country as
                          a condition of employment, unless otherwise required
                          in order to comply with law, regulations, executive
                          order, or federal, state, or local government
                          contract;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>c.</span>
                        <p>
                          post jobs that include any screening requirement or
                          criterion in connection with a job ad where such
                          requirement or criterion is not an actual and legal
                          requirement of the posted job;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>d.</span>
                        <p>
                          with respect to profiles, determine a consumer’s
                          eligibility for (i) credit or insurance for person,
                          family, or household purposes; (ii) employment; or
                          (iii) government license or benefit;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>e.</span>
                        <p>
                          promote or advertise career fairs, job fairs, hiring
                          events, conferences, seminars, or open houses, or any
                          other event or meeting;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-35">f.</span>
                        <p>
                          post jobs or other advertisements for competitors of
                          Simplify or post jobs or other content that contains
                          links to any site competitive with Simplify;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>g.</span>
                        <p>sell, promote, or advertise products or services;</p>
                      </div>
                      <div className="sub_point">
                        <span>h.</span>
                        <p>
                          post any franchise, pyramid scheme, “club membership,”
                          distributorship, multi-level marketing opportunity, or
                          sales representative agency arrangement;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-35">i.</span>
                        <p>
                          post any business opportunity that requires an up
                          front or periodic payment or requires recruitment of
                          other members, sub-distributors, or sub-agents;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-35">j.</span>
                        <p>
                          post any business opportunity that pays commission
                          only unless the posting clearly states that the
                          available job pays commission only and clearly
                          describes the product or service that the candidate
                          would be selling;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>k.</span>
                        <p>
                          promote any opportunity that does not represent a bona
                          fide employment which is generally indicated by the
                          employer’s use of Internal Revenue Service forms W-2
                          or 1099;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-35">l.</span>
                        <p>
                          post jobs on any Simplify site for modelling, acting,
                          talent, or entertainment agencies or talent scouting
                          positions;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-25">m.</span>
                        <p>
                          advertise sexual services or seek employees for jobs
                          of a sexual nature;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>n.</span>
                        <p>
                          request the use of human body parts or the donation of
                          human parts, including, without limitation,
                          reproductive services such as egg donation and
                          surrogacy;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>o.</span>
                        <p>
                          endorse a political party, political agenda, political
                          position or issue;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>p.</span>
                        <p>promote a religion;</p>
                      </div>
                      <div className="sub_point">
                        <span>q.</span>
                        <p>
                          post jobs located in countries subject to economic
                          sanctions of the United States Government; and,
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-35">r.</span>
                        <p>
                          except where allowed by applicable law, post jobs
                          which require the applicant to provide information
                          relating to his/her (i)racial or ethnic origin, (ii)
                          political beliefs, (iii) philosophical or religious
                          beliefs, (iv) membership of a trade union, (v)
                          physical or mental health (including pregnancy
                          status), (vi) sexual life, (vii) the commission or
                          criminal offences or proceedings, or (viii) age.
                        </p>
                      </div>
                    </div>
                    <p>
                      Simplify reserves the right to remove any job ad or
                      content from any Simplify site, which in the reasonable
                      exercise of Simplify’s discretion, does not comply with
                      the above Terms, or if any content is posted that Simplify
                      believes is not in the best interest of Simplify.
                    </p>
                    <p>
                      If at any time during your use of Simplify Hire, you made
                      a misrepresentation of fact to Simplify or otherwise
                      misled Simplify in regards to the nature of your business
                      activities, Simplify will have grounds to terminate your
                      use of the Simplify Hire, or any and all other Simplify
                      services.
                    </p>
                    <h5>Profiles Database:</h5>
                    <p>Use of the Profile Database by Employers</p>
                    <p>
                      You shall use the Profile Database as provided in these
                      Terms and in any contract you have with Simplify. You
                      shall use the Profile Database in accordance with all
                      applicable privacy and data protection laws, and you agree
                      you shall not further disclose any of the data from the
                      Profile Database to any third party, unless you are an
                      authorized recruitment agency, staffing agency,
                      advertising or other agencies, using the profile
                      explicitly for employment purposes expressly authorized by
                      Simplify.
                    </p>
                    <p>
                      You may not use the Profile Database in any way which, in
                      Simplify’s sole discretion, adversely affects Simplify’s
                      business, business prospects, the performance or function
                      of any site or the Profile Database, or interferes with
                      the ability of others to access the Profile Database.
                    </p>
                    <p>
                      You shall take appropriate physical, technical, and
                      administrative measures to protect the data you have
                      obtained from the Profile Database from loss, misuse,
                      unauthorized access, disclosure, alteration, or
                      destruction. You shall not share the Profile Database
                      login credentials with any other party, nor share Profile
                      Database data, including profiles and any and all details
                      related thereof, with any party.
                    </p>
                    <h5>
                      The Profile Database and information obtained from the
                      Profile Database shall not be used:
                    </h5>
                    <div className="pointed_sec">
                      <div className="sub_point">
                        <span>a.</span>
                        <p>
                          for any purpose other than as an employer seeking
                          employees, including but not limited to advertising
                          promotions, products, or services to any profile
                          users;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>b.</span>
                        <p>
                          to send emails or text messages or make phone calls to
                          profile users that, in Simplify’s sole discretion, are
                          excessive in frequency or related to job ads that are
                          irrelevant to the work history or location of, or
                          unlikely to be of interest to, the profile user
                          contacted;
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>c.</span>
                        <p>
                          to make unsolicited phone calls or faxes or send
                          unsolicited mail, email, or newsletters to profile
                          users or to contact any individual unless they have
                          agreed to be contacted (where consent is required or,
                          if express consent is not required, who has not
                          informed you that they do not want to be contacted);
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>d.</span>
                        <p>
                          to store candidates or to contact job seekers, resume
                          holders, or profile users in regards to career fairs
                          or business opportunities prohibited by these Terms;
                          or,
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>e.</span>
                        <p>
                          take any other action that is, in Simplify’s sole
                          discretion, inconsistent with these Terms of Use,
                          misleading or incomplete, or in violation of any
                          Federal, State, Local law, statute, code, rule, or
                          regulation.
                        </p>
                      </div>
                    </div>
                    <p>
                      Simplify reserves the right to limit the amount of data
                      (including profile views) that may be accessed by you or
                      the number of contacts, emails, or messages you may make
                      or send within any Simplify system in any given time
                      period. These limits may be amended in Simplify’s sole
                      discretion from time to time.
                    </p>
                    <h4 className="policy_head_tab">
                      4. Additional Terms Applicable to Candidates
                    </h4>
                    <p>
                      When you register with any Simplify site, you will be
                      asked to login with a service or create an account and
                      provide Simplify with certain information, including,
                      without limitation, name and a valid email address
                      (“information”).
                    </p>
                    <p>
                      Any Profile you submit must be accurate and describe you,
                      an individual person. The Profile requires standard fields
                      to be completed and you may not include in these fields
                      any information that is not expressly requested for those
                      fields.
                    </p>
                    <p>
                      You acknowledge and agree that you are solely responsible
                      for the form, content, and accuracy of any profile or
                      material contained therein placed by you on any Simplify
                      site, including Simplify Hire.
                    </p>
                    <p>
                      Simplify reserves the right to offer third party services
                      and products to you based on the preferences you identify
                      in your registration and at any time thereafter or you
                      have agreed to receive; such offers may be made by
                      Simplify or by third parties. Please see Simplify Hire’
                      Privacy Policy for further details regarding your
                      information.
                    </p>
                    <p>
                      You understand and acknowledge that you have no ownership
                      rights in your account and if you inactive, anonymize, or
                      delete your Simplify account or your Simplify account is
                      suspended or terminated, all your account information from
                      Simplify, including resumes, profiles, skills, work
                      history, saved jobs, etc., will be marked as deleted and
                      may be deleted from Simplify’s databases and/or will be
                      removed from any public area of Simplify Hire or any
                      Simplify site. Information may continue to be available
                      for some period of time because of delays in propagating
                      such inactivation, anonymization, or deletion through
                      Simplify’s systems. In addition, third parties may retain
                      saved copies of your information.
                    </p>
                    <p>
                      Simplify reserves the right to delete your account and all
                      your information due to inactivity or inaccuracy, or for
                      any reason whatsoever.
                    </p>
                    <h4 className="policy_head_tab">
                      5. User Content and Submissions
                    </h4>
                    <p>
                      You understand that all information, data, text, software,
                      music, sound, photographs, graphics, video,
                      advertisements, messages, or other materials submitted,
                      posted, or displayed by you through a Simplify Hire or a
                      Simplify site (“User Content”) is the sole responsibility
                      of the person which such User Content originated. Simplify
                      claims no ownership or control over any User Content. You
                      or a third-party licensor, as appropriate, retain all
                      patent, trademark, and copyright to any User Content you
                      submit, post, or display on or through Simplify Source and
                      you are responsible for protecting those rights, as
                      appropriate. By submitting, posting, or displaying User
                      Content on or through Simplify Source, you grant Simplify
                      a worldwide, non-exclusive, royalty-free, transferable,
                      sub-licensable license to use, reproduce, adapt,
                      distribute, and publish such User Content through Simplify
                      Hire and any other Simplify site or service. In addition,
                      by submitting, posting, or displaying User Content which
                      is intended to be available to the general public, you
                      grant Simplify a worldwide, non-exclusive, royalty-free
                      license to reproduce, adapt, distribute, and publish such
                      User Content for promoting Simplify and its services.
                      Simplify will discontinue this licensed use within a
                      commercially reasonable period after such User Content is
                      removed from Simplify Hire. Simplify reserves the right to
                      refuse to accept, post, display, or transmit any User
                      Content in its sole discretion.
                    </p>
                    <p>
                      You also represent and warrant that you have the right to
                      grant, or the holder of any rights, including moral rights
                      in such content has completely and effectively waived all
                      such rights and validly and irrevocably granted to you the
                      right to grant, the license stated above. If you post User
                      Content in any Simplify site, you also permit any user to
                      access, display, view, store, and reproduce such User
                      Content for personal use. Subject to the foregoing, the
                      owner of such User Content placed on any Simplify site
                      retains any and all rights that may exist in such User
                      Content. Simplify may review and remove any User Content
                      that, in its sole judgment, violates these Terms, violates
                      applicable laws, rules or regulations, is abusive,
                      disruptive, offensive, or illegal, or violates the rights
                      of, or harms or threatens the safety of, users of any
                      Simplify site. Simplify reserves the right to expel users
                      and prevent their further access to Simplify Hire and any
                      and all other Simplify sites and/or use of the Simplify
                      Services for violating the Terms or applicable laws,
                      rules, or regulations. Simplify may take any action with
                      respect to User Content that it deems necessary or
                      appropriate in its sole discretion if it believes that
                      such User Content could create liability for Simplify,
                      damage Simplify’s brand or public image, or cause Simplify
                      to lose users or (in whole or in part) the services of its
                      service providers or other suppliers.
                    </p>
                    <p>
                      Simplify does not represent or guarantee the truthfulness,
                      accuracy, or reliability of User Content, or any other
                      communications posted by users nor does Simplify endorse
                      any opinions expressed by users. You acknowledge that any
                      reliance on material posted by other users will be at your
                      own risk.
                    </p>
                    <p>
                      The following is a partial list of User Content that is
                      prohibited on Simplify Hire. The list below is for
                      illustration purposes only and does not constitute a
                      complete list of all prohibited User Content.
                    </p>
                    <h4>Content that:</h4>
                    <ul className="dotted_list">
                      <li>
                        is implicitly or explicitly offensive, such as User
                        Content that engages in, endorses, or promotes racism,
                        bigotry, discrimination, hatred, or physical harm of any
                        kind against any group or individual;
                      </li>
                      <li>
                        harasses, incites harassment, or advocates harassment of
                        any group or individual;
                      </li>
                      <li>
                        involves transmission of “junk mail,” “chain letters,”
                        or unsolicited mass mailing, “spamming,” or “phishing”;
                      </li>
                      <li>
                        promotes or endorses false or misleading information or
                        illegal activities or conduct that is abusive,
                        threatening, obscene, defamatory, or libellous;
                      </li>
                      <li>
                        promotes or endorses an illegal or unauthorized copy of
                        another person’s copyrighted work, such as providing or
                        making available pirated computer programs or links to
                        them, providing or making available information to
                        circumvent manufacture-installed copy-protect devices,
                        or providing or making available pirates music or other
                        media or links to pirates music or other media files;
                      </li>
                      <li>
                        contains restricted or password only access pages, or
                        hidden pages or images;
                      </li>
                      <li>
                        displays or links to pornographic, indecent, or sexually
                        explicit material of any kind;
                      </li>
                      <li>
                        provides or links to material that exploits people under
                        the age of eighteen (18) in a sexual, violent, or other
                        manner, or solicits personal information from anyone
                        under the age of eighteen (18);
                      </li>
                      <li>
                        provides instructional information about illegal
                        activities or other activities prohibited by these
                        Terms, including without limitation, making or buying
                        illegal weapons, violating someone’s privacy, providing
                        or creating computer viruses or pirating any media; and,
                      </li>
                    </ul>
                    <p>
                      Any profile you submit must describe you, an individual
                      person. Examples of inappropriate and prohibited profiles
                      include, but are not limited to, profiles that purport to
                      represent an animal, place, animate object, fictional
                      character, or real individual that is not you.
                    </p>
                    <p>
                      Profiles derived from User Content may also be made
                      available through other Simplify sites, products, and
                      services. Simplify does not make any representation
                      regarding the accuracy or validity of such derived works
                      or their appropriateness for evaluation by employers.
                      Derived Profiles may differ significantly from User
                      Content
                    </p>
                    <p>
                      While we appreciate hearing from our users and welcome
                      comments regarding our services, Simplify Source, and
                      other Simplify sites, our policy does not permit us to
                      accept or consider creative ideas, suggestions,
                      inventions, or materials other than those which we have
                      specifically requested. While we do value your feedback on
                      our services, please be specific in your comments
                      regarding our services and do not submit creative ideas,
                      inventions, suggestions, or materials. If, despite our
                      request, you send us creative suggestions, ideas,
                      drawings, concepts, inventions, or other information
                      (collectively, the “Submission”), the Submission shall be
                      the property of Simplify. None of the Submission shall be
                      subject to any obligation of confidentiality on our part
                      and we shall not be liable for any use or disclosure of
                      any Submission. Simplify shall own exclusively all now
                      known or later discovered rights to the Submission and
                      shall be entitled to unrestricted use of the Submission
                      for any purpose whatsoever, commercial or otherwise,
                      without compensation to you or any other person.
                    </p>
                    <h4 className="f-19" style={{ fontWeight: "400" }}>
                      6. Identification of Agent to Receive Notification of
                      Claimed Copyright or Trademark Infringement
                    </h4>
                    <p>
                      If you believe that your copyrighted work or trademark has
                      been unloaded, posted, or copied to any Simplify site, and
                      is accessible on such Simplify site in a way that
                      constitutes copyright or trademark infringement, please
                      contact Simplify by mail at:
                    </p>
                    <div className="pointed_sec not_styled">
                      <p>Simplify Workforce Inc.</p>
                      <p>ATTN: Operations – Legal – DMCA</p>
                      <p>10 Exchange Place</p>
                      <p>Jersey City, NJ 07302</p>
                      <p>United States</p>
                    </div>
                    <h4 className="policy_head_tab">
                      7. Termination of Users who Infringe the Copyright or
                      Other Intellectual Property Rights of Others
                    </h4>
                    <p>
                      Simplify respects the intellectual property of others, and
                      we ask our users and content partners to do the same. The
                      unauthorized posting, reproduction, copying, distribution,
                      modification, public display, or public performance of
                      copyrighted works constitutes infringement of the
                      copyright owner’s rights. As a condition to your use of
                      the Simplify sites, you agree not to use any Simplify site
                      to infringe the intellectual property rights of others in
                      any way. Simplify reserves the right to terminate the
                      accounts of any users, and block access to the Simplify
                      sites of any users who are repeat infringers of the
                      copyrights, or other intellectual property rights, of
                      others. Simplify reserves the right, in its sole
                      discretion, to take these actions to limit access to
                      Simplify Hire and/or terminate the accounts at any time,
                      in our sole discretion. Furthermore, Simplify reserves the
                      right, in its sole judgment, to limit access to Simplify
                      Hire, any Simplify site, and/or terminate the accounts at
                      any time, for any reason whatsoever, with or without
                      notice, and without any liability to the user who is
                      terminated or to the user whose access is blocked.
                      Notwithstanding the above, in the event that you believe
                      in good faith that a notice of copyright infringement has
                      been wrongly filed against you, please contact Simplify as
                      set forth in Section 6 above.
                    </p>
                    <h4 className="policy_head_tab">
                      8. Limitation of Liability
                    </h4>
                    <p>
                      Simplify Hire is provided “as is,” and Simplify makes no
                      guarantees that it operates safely, securely, or
                      error-free, or that it will function without disruptions,
                      delays, or imperfections. To the extent permitted by law,
                      we also DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR
                      IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF
                      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                      AND NON-INFRINGEMENT. We do not control or direct what
                      people and others do or say, and we are not responsible
                      for their actions or conduct (whether online or offline)
                      or any content they share (including offensive,
                      inappropriate, obscene, unlawful, and other objectionable
                      content).
                    </p>
                    <p>
                      We cannot predict when issues might arise with Simplify
                      Hire, Simplify sites, or any of the Simplify products or
                      services. Accordingly, our liability shall be limited to
                      the fullest extent permitted by applicable law, and under
                      no circumstance we will be liable to you for any lost
                      profits, revenues, information, or data, or consequential,
                      special, indirect, exemplary, punitive, or incidental
                      damages arising out of or related to these Terms, Simplify
                      Hire, or Simplify in part or as a whole, even if we have
                      been advised of the possibility of such damages. Our
                      aggregate liability arising out of or relating to these
                      Terms of Simplify Hire will not exceed $100.
                    </p>
                    <h4 className="policy_head_tab">9. Indemnity</h4>
                    <p>
                      You agree to defend, indemnify, and hold harmless
                      Simplify, its affiliates, and their respective officers,
                      employees, and agents, from and against any claims,
                      actions, or demands, including without limitation
                      reasonable legal and accounting fees, alleging or
                      resulting from (i) any User Content or other material you
                      provide to any Simplify site, (ii) your use of any
                      Simplify Content, or (iii) your breach of these Terms.
                      Simplify shall provide notice to you promptly of any such
                      claim, suit, or proceeding.
                    </p>
                    <h4 className="policy_head_tab">10. Disputes</h4>
                    <p>
                      Simplify provides clear rules so that we can limit or
                      hopefully avoid disputes between you and us. If a dispute
                      does arise, however, it is useful to know up front where
                      it can be resolved and what laws will apply.
                    </p>
                    <p>
                      For any claim, cause of action, dispute you have against
                      us that arises out of or relates to these Terms or
                      Simplify Hire (“claim”), you agree that it will be
                      resolved exclusively in the U.S. District Court for the
                      State of New Jersey, or a state court located in Hudson
                      County, New Jersey. You also agree to submit to the
                      personal jurisdiction of either of these courts for the
                      purpose of litigating such claim, and that the laws of the
                      State of New Jersey will govern these Terms and any claim,
                      without regard to the conflict of laws provisions.
                    </p>
                    <h4 className="policy_head_tab">11. Other</h4>
                    <p>The following additional terms and conditions apply:</p>
                    <div className="pointed_sec ml-34">
                      <div className="sub_point">
                        <span>a.</span>
                        <p>
                          These Terms make up the entire agreement between you
                          and Simplify regarding your use of Simplify Source.
                          They supersede any prior agreements, excepting those
                          agreements that explicitly take precedence, as
                          identified in Section 2.
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>b.</span>
                        <p>
                          Some of the products and services that Simplify offers
                          are governed by supplemental terms. If you use any of
                          these products and services, supplemental terms will
                          be made available and will become a part of our
                          agreement with you.
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>c.</span>
                        <p>
                          If any portion of these Terms is found to be
                          unenforceable, the remaining portion will remain in
                          full force and effect. If we fail to enforce any of
                          these Terms, it will not be considered a waiver. Any
                          amendment to or waiver of these Terms must be made in
                          writing and signed by us.
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>d.</span>
                        <p>
                          You will not transfer any of your rights or
                          obligations under these Terms to anyone else without
                          our consent.
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>e.</span>
                        <p>
                          You will not transfer any of your rights or
                          obligations under these Terms to anyone else without
                          our consent.
                        </p>
                      </div>
                      <div className="sub_point">
                        <span className="m-r-35">f.</span>
                        <p>
                          You may designate a person (called a “legacy contact”)
                          to manage your account if it is memorialized. Only
                          your legacy contact or a person who you have
                          identified in a valid will or similar document
                          expressing clear consent to disclose your content upon
                          death or incapacity will be able to seek disclosure
                          from your account after it is memorialized.
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>g.</span>
                        <p>
                          These Terms do not confer any third-party beneficiary
                          rights. All of our rights and obligations under these
                          Terms are freely assignable by us in connection with a
                          merger, acquisition, or sale of assets, or by
                          operation of law or otherwise.
                        </p>
                      </div>
                      <div className="sub_point">
                        <span>h.</span>
                        <p>
                          Simplify reserves all rights not expressly granted to
                          you.
                        </p>
                      </div>
                    </div>
                    <p>Last Updated: May 10, 2022</p>
                  </div>
                </div>
                <div role="tabpanel" className="tab-pane fade" id="two">
                  <div className="privacy_tab_1">
                    <h3 className="f-24">Privacy Policy</h3>
                    <p>
                      At Simplify Workforce Inc. (“we,” “our,” or “us”), we are
                      committed to protecting the privacy and personal data of
                      our users. This privacy policy explains how we collect,
                      use, store, and disclose your personal information.
                    </p>
                    <p>
                      This Privacy Policy applies to our website,
                      (www.simplifyhire.com), and its associated subdomains
                      (collectively, our “Service”) in particular through the
                      Simplify Hire related products and services at
                      simplifyhire.com, SimplifyHire, Direct Sourcing as a
                      Service, etc. (collectively “Simplify Hire” and “Site”).
                      By accessing or using our Service, you signify that you
                      have read, understood and agree to our collection,
                      storage, use and disclosure of your personal information
                      as prescribed in this Privacy Policy.
                    </p>
                    <p>
                      Unless specified otherwise, all Data requested by this
                      Application/Site is mandatory and failure to provide this
                      Data may make it impossible for this Application to
                      provide its services. In cases where this Application/Site
                      specifically states that some Data is not mandatory, Users
                      are free not to communicate this Data without consequences
                      to the availability or the functioning of the Service.
                    </p>
                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-4 policy_head_tab">
                        1. Definitions and Key terms:
                      </h4>
                    </div>
                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span>1.1.</span>
                        <p>
                          “Account” means a unique account created for You to
                          access our Service or parts of our Service.
                        </p>
                      </div>
                    </div>
                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span>1.2.</span>
                        <p>
                          "Controller” refers to Simplify Customer, who
                          determines the purpose and means of processing.
                        </p>
                      </div>
                    </div>
                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span>1.3.</span>
                        <p>
                          "Cookies" are small files that are placed on Your
                          computer, mobile device or any other device by a
                          website, containing the details of Your browsing
                          history on that website among its many uses.
                        </p>
                      </div>
                    </div>
                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span>1.4.</span>
                        <p>
                          "Data Subject” refers to a person whose personal data
                          is being processed.
                        </p>
                      </div>
                    </div>
                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span>1.5.</span>
                        <p>
                          "Personal Data" is any information that relates to an
                          identified or identifiable individual.
                        </p>
                      </div>
                    </div>
                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span>1.6.</span>
                        <p>
                          "Processor” refers to an organization which processes
                          on behalf of the controller, Not an employee of the
                          controller.
                        </p>
                      </div>
                    </div>
                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span>1.7.</span>
                        <p>
                          “Services” refers to the website and in particular,
                          Simplify Hire related products.
                        </p>
                      </div>
                    </div>
                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-20 policy_head_tab">
                        2. Collected Personal Data and How We Use it
                      </h4>
                    </div>
                    <div className="pointed_sec_2">
                      <div className="sub_point mt-20">
                        <span>2.1.</span>
                        <p>
                          We collect the following categories of personal data
                          from our customers, customers’ candidates and Site
                          visitors:
                        </p>
                      </div>
                    </div>
                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mr-20">2.1.1.</span>
                        <p>
                          <b>Identifiers –</b> Included in this category are
                          classes of data which identify an individual and their
                          personal characteristics. Examples are names,
                          addresses, job title, employer, contact details, age,
                          sex, date of birth, physical descriptions, identifiers
                          issued by public bodies, e.g. SSN and NI numbers. We
                          may collect names, email addresses and contact
                          information of visitors who register for Simplify
                          Hire, in profile entries and of our customers’ users
                          and their recipients. For our customers, we may also
                          collect information about a customer’s business such a
                          company name, company size, business type, and contact
                          information.
                        </p>
                      </div>
                    </div>
                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mr-18">2.1.2.</span>
                        <p>
                          <b>
                            Education and professional training information –
                          </b>
                          Included in this category are matters which relate to
                          the education and professional training of an
                          individual. Examples are academic records,
                          qualifications, skills, training records, and
                          professional expertise.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mr-18">2.1.3.</span>
                        <p>
                          <b>Employment information –</b> Included in this
                          category are matters relating to the employment of an
                          individual, as necessary for the creation and
                          performance of an employment contract or working
                          relationship between you and Simplify or its client.
                          Examples are business role, employment and career
                          history, recruitment and termination details,
                          attendance record, health and safety records,
                          performance appraisals, training records, agency
                          employer, security records or other information
                          necessary to determine an individual’s fitness for
                          employment.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mr-18">2.1.4.</span>
                        <p>
                          <b>Financial details –</b> Included in this category
                          are matters relating to the financial affairs of an
                          individual. Examples are income, salary, billing rate,
                          payments, benefits, bank or other information
                          necessary to process payroll for an individual. For
                          our customers, information may also include payment
                          processing information such as credit or debit card
                          number, bank information, and billing address.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mr-18">2.1.5.</span>
                        <p>
                          <b>Goods or services provided –</b> Included in this
                          category are classes of data relating to goods and
                          services which have been provided. Examples are
                          details of the goods or services supplied, licenses
                          issued, agreements and contracts.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mr-18">2.1.6.</span>
                        <p>
                          <b>IT information –</b> Included in this category is
                          any information relating to an individual’s use of
                          technology or software including IP addresses, any
                          information about the computing or mobile device an
                          individual is using, location data gathered from such
                          devices, connection data, usernames and passwords, and
                          social media handles. source to the Site.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mr-18">2.1.7.</span>
                        <p>
                          <b>Special Categories –</b> Included in this category
                          are classes of data that are considered sensitive
                          data, personally identifiable information, or
                          sensitive personal data under applicable law. This
                          category of personal data is typically collected to
                          comply with legal requirements or to determine fitness
                          for employment.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mr-18">2.1.8.</span>
                        <p>
                          We may also collect from you, personal data about your
                          contacts such as email addresses. If you believe that
                          one of your contacts has provided us with your
                          personal data and you would like to request that it be
                          removed from our database, please contact us at{" "}
                          <a className="primary-color" href="#">
                            privacy@simplifycareers.com.
                          </a>
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-15">2.2.</span>
                        <p className="m_l_12">
                          Personal data is collected and used for Simplify’s
                          legitimate business purposes and to comply with the
                          Company’s contractual and legal obligations, including
                          establishing, managing and providing Simplify Hire to
                          our customers. Personal data is used by Simplify for
                          the purposes described in this Privacy Policy, or for
                          additional purposes that Simplify has advised you of,
                          and / or for which Simplify has obtained your consent
                          with respect to the use or disclosure of your personal
                          data. Specifically, we use the personal data collected
                          only for the following purposes:
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mr-18">2.2.1.</span>
                        <p>
                          Provide the Service to our customers, including
                          through third parties.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-13">2.2.2.</span>
                        <p>
                          Facilitate the services provided to our customers by
                          Simplify partners.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-13">2.2.3.</span>
                        <p>Keep records of customer activity.</p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-13">2.2.4.</span>
                        <p>Keep records of customer information.</p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-15">2.2.5.</span>
                        <p>
                          Administer a customer’s accounts, including payment.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-15">2.2.6.</span>
                        <p>
                          Respond to a customer’s service or support requests.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-15">2.2.7.</span>
                        <p>For marketing, promotional and sales purposes.</p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-14">2.2.8.</span>
                        <p>For data security purposes.</p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-13">2.2.9.</span>
                        <p>
                          For statistical, analytical machine learning and
                          product/service enhancement purposes.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-8">2.2.10.</span>
                        <p>
                          Simplify does not use personal data for automated
                          decision making.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-15">2.3.</span>
                        <p className="ml-14">
                          Simplify may aggregate and/or de-identify personal
                          data (“Aggregated Data”) and use, disclose,
                          distribute, and publish Aggregated Data for
                          statistical, analytical, machine learning and product
                          enhancement purposes. Simplify employs reasonable and
                          technically appropriate measures designed to prevent
                          the re-identification of such personal data by a
                          third-party. Aggregated Data may be shared with third
                          parties for research and other purposes.
                        </p>
                      </div>
                    </div>
                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-15">2.4.</span>
                        <p className="ml-15">
                          We may use your personal data to comply with the law,
                          including collecting and revealing personal data as
                          required (e.g., for minimum wage, hours worked, tax,
                          health and safety, antidiscrimination laws, and global
                          mobility), to respond to judicial authorities, or to
                          exercise or protect Simplify and/or its client's legal
                          rights.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-15">2.5.</span>
                        <p className="ml-15">
                          We will only share your personal data with third
                          parties (our agents) in the ways that are described in
                          our customer agreements and this Privacy Policy. We do
                          not sell your personal data to third parties.
                          Additionally, our employees are trained on privacy
                          obligations and subject to confidentiality obligations
                          and only have access to personal data as necessary for
                          our business purposes and their own duties.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-15">2.6.</span>
                        <p className="ml-15">
                          We may email information regarding updates to the
                          Service or service offerings to our customers in
                          accordance with the terms and conditions of each
                          customer’s agreements with us or site visitors in
                          accordance with the terms described in this Privacy
                          Policy.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-15">2.7.</span>
                        <p className="ml-15">
                          Other third parties, such as content or third party
                          service providers, may provide content or services
                          through the Service and may need access to your
                          personal data to provide their services to you.
                          Customers will be using a Service to host data and
                          information. Simplify will not use or disclose your
                          personal data, except (i) to the extent necessary to
                          provide the Service to you or as requested by you, or
                          (ii) pursuant to law, as determined by Simplify in its
                          sole discretion, or (iii) by a court order, or (iv) in
                          connection with third party cookies as described in
                          Section 23.3.5 of this Privacy Policy. Individual
                          customer or customer candidate records may at times be
                          viewed or accessed by Simplify’s authorized employees
                          or agents only for the purpose(s) of (i) providing the
                          Service and related professional services to customer
                          or customer candidates, (ii) resolving a support issue
                          affecting any of them, (iii) to inspect and resolve a
                          suspected violation of customer agreements with a
                          customer, or (iv) as may be required by law, as
                          determined by Simplify in its sole discretion,
                          judicial proceeding, subpoena, legal process or
                          binding court order. We also reserve the right to
                          disclose your personal data when we believe, in our
                          sole discretion, that disclosure is necessary to
                          protect our rights and/or comply with a judicial
                          proceeding, subpoena, court order, or legal process.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-15">2.8.</span>
                        <p className="ml-14">
                          Personal data may be disclosed or distributed to
                          another party with which Simplify enters, or may
                          enter, into a corporation transaction. If Simplify is
                          acquired in a merger, acquisition, or sale of all or
                          substantially all its assets, you will be notified via
                          email, on our Site and/or by a prominent notice on our
                          Service of any change in the uses of your personal
                          data, as well as any choices you may have regarding
                          your personal data. The disclosure of personal data to
                          another party as set forth herein may involve the
                          transfer of such personal data outside the state,
                          province, country or other jurisdiction in which
                          Simplify stores or otherwise processes personal data,
                          subject to Simplify taking steps to provide that any
                          such transfer is made only in compliance with
                          applicable laws. In the unlikely event of a
                          bankruptcy, insolvency or liquidation, the database
                          containing personal data may be treated as an asset of
                          Simplify and may be subject to transfer to a third
                          party.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-15">2.9.</span>
                        <p className="ml-14">
                          Simplify may disclose personal data to companies that
                          assist us in providing our Service or that partner
                          with us to provide you their services or content. In
                          this context, any such transfers to third parties are
                          governed by our vendor agreements with them, which
                          provide standards of care for the protection for
                          personal and confidential information that are not
                          less stringent than the standards contained in our
                          customer agreements, and in no event, less than a
                          reasonable standard of care in accordance with the
                          Standard Contractual Clauses as set forth in Section
                          13 of this Privacy Policy, the Privacy Shield
                          Framework (to which Simplify still adheres) and other
                          applicable law. Such third-party companies are
                          authorized to use your personal data only as necessary
                          to provide these services to us and/or you and for the
                          purposes for which the personal data was collected.
                          Personal data may be transferred to third parties
                          outside the state, province, country or other
                          jurisdiction in which Simplify stores personal data,
                          subject to Simplify taking steps to provide that any
                          such transfer is made in full compliance with
                          applicable laws. In the case of partners with whom you
                          contract directly, their policies regarding personal
                          data will govern your relationship with them as stated
                          in the applicable agreement between you and the
                          partner and we will not be responsible for any actions
                          or omission of these partners. Under the Standard
                          Contractual Clauses and Privacy Shield Principles, you
                          have the right to opt out of (i) disclosures of your
                          personal data to third parties; or (ii) uses of your
                          personal data for a purpose that is materially
                          different from the purpose(s) for which it was
                          originally collected or subsequently authorized by
                          you.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-15">2.10.</span>
                        <p className="ml-6">
                          Customers are solely responsible for maintaining the
                          confidentiality and security of their user
                          registration and password. Customers or Site visitors
                          may opt-out of receiving sales, promotional or
                          advertising emails from us by selecting the opt-out
                          link located in the message body of all our electronic
                          communications. Customers or Site visitors may also
                          email{" "}
                          <a className="primary-color" href="#">
                            privacy@simplifycareers.com
                          </a>
                          &nbsp;directly with a request to be removed from such
                          communications. We may also track and analyze
                          information that doesn’t directly identify you as a
                          person and aggregate usage and volume statistical
                          information from our Site visitors, customers’
                          candidates and customers and provide such information
                          in aggregated form to third parties.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-15">2.11.</span>
                        <p className="ml-10">
                          We are a service provider to our customers and have no
                          direct relationship with the customer candidate or any
                          individual whose personal data a customer processes.
                          If you are a candidate or individual of one of our
                          customers and would no longer like to be contacted by
                          a person or entity that uses our Service, please
                          contact the customer that you interact with directly.
                          If you are a customer and would like to update your
                          account, please contact us at<space></space>
                          <a className="primary-color" href="#">
                            {" "}
                            privacy@simplifycareers.com.
                          </a>
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-15">2.12.</span>
                        <p className="ml-6">
                          <b>Marketing:</b> Simplify may also ask website
                          visitors who register for Simplify Hire, to provide
                          certain information, such as email addresses to use
                          and receive content from the site. Simplify will
                          provide these visitors with the ability to opt-in or
                          opt-out of future communications from the Company as
                          required by applicable law. If you would like to
                          discontinue receiving this information, you may update
                          your email preferences by using the “Unsubscribe” link
                          found in emails we send to you or at your member
                          profile on our Site or by contacting us at{" "}
                          <a className="primary-color" href="#">
                            privacy@simplifycareers.com
                          </a>
                          .
                        </p>
                      </div>
                    </div>

                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-20 policy_head_tab">
                        3. How We Use Cookies, Log Files, Social Media Plug-ins
                        and Other Tracking Technologies
                      </h4>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point mt-20">
                        <span>3.1.</span>
                        <p className="ml-2">
                          Technologies such as cookies or similar technologies
                          are used by Simplify and our partners, affiliates, or
                          our service providers. These technologies are used in
                          analyzing trends, administering the site, tracking
                          users’ movements around the site and to gather
                          demographic information about our user base as a
                          whole. We may receive reports based on the use of
                          these technologies by these companies on an individual
                          as well as aggregated basis.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-28">3.2.</span>
                        <p>
                          <b>Log in files:</b> We gather certain information
                          automatically and store it in log files. This
                          information includes internet protocol (IP) addresses,
                          browser type, internet service provider (ISP),
                          referring/exit pages, operating system, date/time
                          stamp, clickstream data, the files viewed on our site
                          (e.g., HTML pages, graphics, etc.), to analyze trends
                          in the aggregate and administer the Site. We do link
                          these automatically-collected data sets to personal
                          data of you that we may have already stored.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-28">3.3.</span>
                        <p>
                          <b>Cookies:</b> We use cookies to remember users’
                          settings (e.g., language preference), and for your
                          authentication when you visit the Site or use the
                          Service, as the case may be. The following information
                          will help you to understand the different types of
                          cookies used:
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mr-18">3.3.1.</span>
                        <p>
                          <b>Strictly necessary</b> cookies are required for the
                          operation of the Site. These include, for example,
                          cookies which are necessary for users to create an
                          account or login to secure areas of the Site, and
                          cookies that are required to show error or success
                          messages to users.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-14">3.3.2.</span>
                        <p>
                          <b>Session cookies</b> containing encrypted
                          information to allow the system to uniquely identify
                          you while you are logged in. Session cookies exist
                          only during an online session. They disappear from
                          your computer when you close your browser software or
                          turn off your computer. This information allows
                          Simplify to process your transactions and requests.
                          Session cookies help us make sure you are who you say
                          you are after you’ve logged in and are required to use
                          the Simplify application.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-14">3.3.3.</span>
                        <p>
                          <b>Persistent cookies</b> that only Simplify can read
                          and use, to identify the fact that you are a Simplify
                          customer or a visitor, who has registered for Simplify
                          Hire, to identify and maintain your preferences such
                          as language, country and last check out or to receive
                          certain types of content. Persistent cookies remain on
                          your computer after you’ve closed your browser or
                          turned off your computer. They include such
                          information as a unique identifier for your browser.
                          We are careful about the security and confidentiality
                          of the information stored in persistent cookies. For
                          example, we do not store account numbers or passwords
                          in persistent cookies. Users or visitors who disable
                          their Web browsers’ ability to accept cookies will be
                          unable to use all aspects of our service or Simplify
                          Hire.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-14">3.3.4.</span>
                        <p>
                          <b>Targeting cookies</b> which store a user’s
                          username, the referring user if using the referral
                          network and the referral source to the Site.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-14">3.3.5.</span>
                        <p>
                          Some third party, performance, and targeting cookies
                          could be used:
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-14">3.3.6.</span>
                        <p>
                          <b>Functional cookies</b> which store user ids and
                          enable the website to provide enhanced functionality
                          and personalisation. They may be set by us or by third
                          party providers whose services we have added to our
                          pages. If you do not allow these cookies then some or
                          all of these services may not function properly.
                        </p>
                      </div>
                    </div>
                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <p>
                          Please note that other third parties may also use
                          cookies. These cookies are likely to be
                          analytical/performance cookies or targeting cookies
                          and include, for example, those from advertising
                          networks or providers of external services.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="m-r-16">3.3.7.</span>
                        <p>
                          <b>Strictly necessary cookies</b> are necessary for
                          the website to function. They are usually only set in
                          response to actions made by you which amount to a
                          request for services, such as setting your privacy
                          preferences, logging in or filling in forms. You can
                          set your browser to block or alert you about these
                          cookies, but some parts of the site will not then
                          work.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mr-18">3.4.</span>
                        <p className="ml-10">
                          <b>Opt-out:</b> You can control the use of cookies at
                          the individual browser level. If you reject cookies,
                          you may still use our Site, but your ability to use
                          some features or areas of our Site may be limited.
                          Many browsers allow a private mode to be activated
                          through which the cookies are always erased after the
                          visit. Depending on each browser, this private mode
                          can have different names. The following is a list of
                          the most common browsers that support the minimum
                          recommended level of encryption TLS 1.2. Each browser
                          type has a different name for “private mode”:
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span></span>
                        <p className="ml-28">
                          Internet Explorer 11 and later versions = In Private
                          Safari 7 and later versions = Private
                          Navigation/Browsing Opera 17 and later versions =
                          Private Navigation/Browsing Mozilla Firefox 27 and
                          later versions = Private Navigation/Browsing Google
                          Chrome 30 and later versions = Incognito
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mr-18">3.5.</span>
                        <p className="ml-10">
                          <b>Targeted Advertisement:</b> We partner with third
                          parties to either display advertising on our Site or
                          to manage our advertising on other sites. Our
                          third-party partners may use cookies or similar
                          technologies to gather information about your
                          activities on this Site and other websites to provide
                          you advertising based upon your browsing activities
                          and interests. If you wish to not have this
                          information used for the purpose of serving you
                          interest-based ads, you may opt-out by clicking{" "}
                          <a href="#">https://optout.aboutads.info</a>(or if
                          located in the European Union click or{" "}
                          <a href="#">https://www.youronlinechoices.eu</a>).
                          Please note this does not opt you out of generic ads.
                          You will continue to receive generic ads.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mr-18">3.6.</span>
                        <p className="ml-10">
                          <b>Social Media (Features) and Widgets:</b> Our Site
                          includes social media features, such as LinkedIn.
                          These features may collect your IP address, which page
                          you are visiting on our Site, and may set a cookie to
                          enable the feature to function properly. Social media
                          features are either hosted by a third party or hosted
                          directly on our Site. Your interactions with these
                          features are governed by the privacy statement of the
                          company providing it.
                        </p>
                      </div>
                    </div>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mr-18">3.7.</span>
                        <p className="m_l_12">
                          <b>Blogs:</b> Our Site offers publicly accessible
                          blogs or community forums. You should be aware that
                          any information you provide in these areas may be
                          read, collected, and used by others who access them.
                          To request removal of your personal data from our blog
                          or community forum, contact us at{" "}
                          <a className="primary-color" href="#">
                            privacy@simplifycareers.com
                          </a>
                          . In some cases, we may not be able to remove your
                          personal data, in which case we will let you know if
                          we are unable to do so and why. Alternatively, if you
                          used a third-party application to post such
                          information, you can remove it, either by logging into
                          the application and removing the information, or by
                          contacting the appropriate third party application.
                        </p>
                      </div>
                    </div>

                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-20 policy_head_tab">
                        4. Access to Personal Data Controlled by Simplify
                      </h4>
                    </div>

                    <p className="mt-20">
                      Upon request and within 30 days, or as otherwise required
                      by applicable law, Simplify will provide you with
                      information about whether we hold, or process on behalf of
                      a third party, any of your personal data. In addition,
                      Simplify will take reasonable steps to permit customers to
                      correct, amend, delete, or receive a copy of their
                      personal data. To request this access, please contact{" "}
                      <a className="primary-color" href="#">
                        privacy@simplifycareers.com
                      </a>
                      . In accordance with customer agreements, Simplify will,
                      upon request, provide our customers with a copy of their
                      personal data stored by Simplify upon expiration or
                      termination of the customer agreements, unless applicable
                      law determines otherwise.
                    </p>

                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-20 policy_head_tab">
                        5. Access to Personal Data, Including Biometric Data,
                        Controlled by our Customers
                      </h4>
                    </div>

                    <p className="mt-20">
                      Simplify acknowledges that you have the right to access
                      your personal data and to withdraw consent to the
                      use/processing of your personal data. In most cases,
                      Simplify has no direct relationship with the individuals
                      whose personal data it processes for customers. We
                      recommend that an individual who seeks access, or who
                      seeks to correct, amend, delete or receive a copy of
                      personal data should direct his/her query to the Simplify
                      customer (the data controller).
                    </p>
                    <p>
                      Simplify customers may collect, store and process
                      biometric data of candidates or individuals, including
                      without limitation fingerprint, retinal and facial
                      recognition data using Simplify supplied equipment and/or
                      Services. Simplify customers and/or their end users are
                      solely responsible for compliance with any applicable
                      biometric data protection laws including, without
                      limitation, obtaining legally required consents and
                      providing legally required notices and/or opt-out/in
                      requirements to such individuals. The use of any Simplify
                      Service by customers or their end users does not guarantee
                      compliance with applicable biometric data protection laws.
                      If you are a candidate or individual of one of our
                      customers or their end users and have any questions or
                      requests or objections related to the collection, storage
                      and processing of your biometric data, please contact the
                      customer or end user that you interact with directly. If
                      requested to remove data by our customer, we will respond
                      within 30 days or as otherwise required by applicable law.
                      Individuals may also submit such inquiries or requests
                      directly to Simplify at no cost for them by contacting
                      <space></space>{" "}
                      <a className="primary-color" href="#">
                        privacy@simplifycareers.com.
                      </a>
                    </p>

                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-20 policy_head_tab">
                        6. Data Retention by Simplify
                      </h4>
                    </div>

                    <p className="mt-20">
                      We will retain personal data including biometric data for
                      as long as a customer’s account is active or as needed to
                      provide the Service to our customer, as required under
                      customer agreements and / or for as long as Simplify needs
                      the personal data to fulfill the purposes for which the
                      Company initially collected it, unless otherwise required
                      by applicable law or court order. We will also retain this
                      personal data as necessary to comply with our legal and/or
                      contractual obligations, comply with court orders, resolve
                      disputes, and enforce our agreements. When the purpose for
                      which Simplify is processing the personal data is
                      fulfilled, expires or is terminated, Simplify will delete
                      (or render unusable) any personal data in accordance with
                      our customer agreements, this Privacy Policy and
                      applicable law. In the event we cannot delete your
                      personal data or render it unusable, we will inform you of
                      the reasons, subject to any legal restrictions.
                    </p>

                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-20 policy_head_tab">
                        7. Data Security
                      </h4>
                    </div>

                    <p className="mt-20">
                      Simplify employs reasonable and appropriate security
                      measures to protect against the loss, misuse, and
                      alteration of the personal data it processes. When the
                      Service is accessed using Microsoft Internet Explorer
                      versions 8.0 or higher, Transport Layer Security (TLS)
                      technology protects information using both server
                      authentication and data encryption to help provide that
                      personal data is safe, and secure while in transit.
                      Simplify also implements an advanced security method based
                      on dynamic data and encoded session identifications, and
                      hosts the Service in a secure server environment that uses
                      a firewall and other advanced technology to protect
                      against interference or access from outside intruders.
                      Finally, Simplify provides individual usernames and
                      passwords that must be entered each time a customer logs
                      on. These safeguards help protect against unauthorized
                      access, maintain data accuracy, and provide for the
                      appropriate use of personal data. Nevertheless, no method
                      of transmission over the Internet, or method of electronic
                      storage, is one hundred percent (100%) secure, however.
                      Therefore, we cannot guarantee absolute security. If you
                      have any questions about security on our Service, please
                      contact us at{" "}
                      <a className="primary-color" href="#">
                        privacy@simplifycareers.com
                      </a>
                      .
                    </p>

                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-20 policy_head_tab">
                        8. Links to Third Party Sites
                      </h4>
                    </div>
                    <p className="mt-20">
                      Our Service includes links to other Web sites whose
                      privacy practices may differ from those at{" "}
                      <a href="#">https://www.directsource.ai</a>. If you submit
                      personal data to any of these sites, your information is
                      governed by the applicable third-party’s privacy policies
                      and we are not responsible for it. We therefore encourage
                      you to carefully read any notices provided through a
                      hyperlink and the privacy policy of any Web site you visit
                      and raise questions directly with these other providers.
                    </p>

                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-20 policy_head_tab">
                        9. No Services for Minors
                      </h4>
                    </div>

                    <p>
                      We do not knowingly collect information from minors. To
                      use the Site, you must be the age of legal majority in
                      your place of residence. By using the Site, you hereby
                      represent that you are at least the age of legal majority
                      in your place of residence. We do not use an application
                      or other mechanism to determine the age of users of the
                      Site. All information provided to us will be treated as if
                      it was provided by an adult. We will use commercially
                      reasonable efforts to delete information associated with a
                      minor as soon as practicable if we learn that a minor has
                      submitted information about himself/herself to us.
                    </p>

                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-20 policy_head_tab">
                        10. Note for California Residents
                      </h4>
                    </div>

                    <p className="mt-20">
                      California law, including the California Consumer Privacy
                      Act of 2018 (“CCPA”), permits California residents who
                      provide us with personal information to request certain
                      information including the categories of personal
                      information we collect, the sources from which your
                      personal information is collected, how we use your
                      personal information, the categories of third parties with
                      whom we share your personal information, and whether we
                      sell your personal information or otherwise disclose such
                      information to third parties for their direct marketing
                      purposes. See Sections 21 and 23 above for the categories
                      of personal information we collect, the sources of such
                      information and how we use your personal information. To
                      request access to or deletion of your personal
                      information, please contact the Company’s Data Protection
                      Officers. See Section 154 for contact details. We do not,
                      at this time, sell your personal information or disclose
                      your personal information to third parties for their
                      direct marketing purposes. Accordingly, no opt out is
                      necessary at this time. Although we currently do not sell
                      your personal information, if you would still like to
                      record your opt-out, you may do so by contacting us at
                      <a className="primary-color" href="#">
                        privacy@simplifycareers.com
                      </a>
                      . If we change this policy, we will update this Section
                      and provide instructions on how you may make a request for
                      details regarding the sale of your personal information.
                      While we do not sell your personal information, we may
                      share your personal information for “business purpose[s]”
                      (as defined in the CCPA) with third parties. We will only
                      share your personal information with third parties in the
                      ways that are described in our customer agreements and
                      this Privacy Policy.
                    </p>

                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-20 policy_head_tab">
                        11. Note for Residents of the European Union, European
                        Economic Area and Switzerland
                      </h4>
                    </div>
                    <p className="mt-20">
                      With respect to the personal data of individuals in the
                      European Union/European Economic Area United Kingdom, and
                      Switzerland, Simplify has contracts with Standard
                      Contractual Clauses in place with clients, staffing
                      suppliers and others that serve as the mechanism for the
                      lawful transfer of personal data from those regions to
                      other countries such as the United States.
                    </p>

                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span className="mt-20">11.1.</span>
                        <p>
                          Reference to Personal data: Any data that relates to
                          an identifiable or identified individual. GDPR covers
                          a broad spectrum of information that could be used on
                          its own, or in combination with the other pieces of
                          information, to identify a person. Personal data
                          extends beyond a person’s name or email address. For
                          example – Financial information; political opinions;
                          genetic data; biometric data; IP address; physical
                          address; sexual orientation and ethnicity.
                        </p>
                      </div>
                    </div>
                    <p>
                      The data protection principles include requirements such
                      as:
                    </p>
                    <ul className="dotted_list ml-10">
                      <li>
                        Personal data collected must be processed in a fair,
                        legal, and transparent way and should only be used in a
                        way that a person would reasonably expect.
                      </li>
                      <li>
                        Personal data should only be collected to fulfil a
                        specific purpose and it should only be used for that
                        purpose. Organisations must specify why they need the
                        personal data when they collect it.
                      </li>
                      <li>
                        Personal data should be held no longer than necessary to
                        fulfil its purpose.
                      </li>
                    </ul>
                    <div className="pointed_sec_2">
                      <div className="sub_point">
                        <span>11.2.</span>
                        <b style={{ fontSize: "16px", marginTop: "2px" }}>
                          Data Subjects Rights – Data Access, Portability and
                          Deletion:
                        </b>
                      </div>
                    </div>
                    <p>
                      We are committed to helping our customers meet the data
                      subject’s rights requirements of GDPR. We process or
                      stores all personal data in fully vetted, DPA compliant
                      vendors. We do store all conversation and personal data
                      for up to 6 years unless your account is deleted. In which
                      case, we dispose of all data in accordance with our
                      Privacy Policy, but we will not hold it longer than 60
                      days. This includes the rights to:
                    </p>
                    <ul className="dotted_list">
                      <li>
                        Make a request for access to and a copy of your personal
                        information.
                      </li>
                      <li>
                        Request the erasure (or deletion) of personal data that
                        is no longer needed to serve the purposes for which it
                        was obtained, or that Simplify or its client does not
                        need to keep. for other legitimate purposes. .
                      </li>
                      <li>
                        Object to or Restrict your personal data being
                        processed.
                      </li>
                      <li>
                        Request that your personal data be ported to another
                        organisation.
                      </li>
                    </ul>
                    <p>
                      These rights can be applied differently depending on the
                      type of data involved and Simplify specific legal
                      justification for processing personal data.
                    </p>
                    <p>
                      Please contact{" "}
                      <a className="primary-color" href="#">
                        privacy@simplifycareers.com
                      </a>{" "}
                      if you want to exercise one of the above rights. Any
                      requests will be considered and responded to in compliance
                      with relevant data privacy laws.
                    </p>
                    <p>
                      Please be aware that we may ask you for detailed details
                      in order to verify your identity and right of access, as
                      well as to search for and provide you with the personal
                      data we have about you. We may charge you a fair fee to
                      access your personal data in limited circumstances;
                      however, we will notify you of any fees in advance.
                    </p>
                    <p>
                      You have the right to revoke your consent at any time if
                      we are depending on your consent to process your personal
                      data. Please notice, however, that the lawfulness of the
                      processing prior to the revocation of your consent will
                      not be affected.
                    </p>
                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-20 policy_head_tab">
                        12. Do-Not-Track Disclosure
                      </h4>
                    </div>

                    <p className="mt-20">
                      There are many methods where web browser signals and
                      similar mechanisms can indicate your choice to disable
                      tracking. But we may not be aware of or able to honor
                      every such mechanism. Because there is not yet a common
                      understanding of how to interpret web browser-based “Do
                      Not Track” (DNT) signals other than cookies, we may not
                      respond to undefined DNT signals to our Sites or online
                      services. More information about “do not track” is
                      available at www.allaboutdnt.org concerning such
                      information.
                    </p>

                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-20 policy_head_tab">
                        13. Severability
                      </h4>
                    </div>

                    <p className="mt-20">
                      The invalidity or unenforceability of any provisions of
                      this Privacy Policy in any country shall not affect the
                      validity or enforceability of any other provision of this
                      Privacy Policy, which shall remain in full force and
                      effect.
                    </p>

                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-20 policy_head_tab">
                        14. Changes in this Privacy Policy
                      </h4>
                    </div>

                    <p>
                      We may update this privacy statement to reflect changes to
                      our information practices that will become effective upon
                      posting. If we make material changes to this policy, we
                      will notify you here, by email, or by means of a notice
                      through the Service prior to the change becoming
                      effective. We encourage and strongly recommend you to
                      periodically review this page for the latest information
                      on our privacy practices.
                    </p>

                    <div className="tab_2_display">
                      <h4 className="tab_2_head mt-20 policy_head_tab">
                        15. Data Protection Officers
                      </h4>
                    </div>

                    <p className="mt-20">
                      Simplify has appointed Data Protection Officers, who are
                      responsible for matters relating to data privacy and
                      protection. The Data Protection Officers can be contacted
                      by email at{" "}
                      <a className="primary-color" href="#">
                        privacy@simplifycareers.com
                      </a>
                      , or by mail at: Simplify Workforce Inc., 10 Exchange
                      Place, Jersey City, NJ 07302, Attn: Data Protection
                      Officer, Legal.
                    </p>

                    <p>
                      Any questions or complaints concerning Simplify Hire’s
                      Privacy Policy or handling of personal data can be
                      directed to:{" "}
                      <a className="primary-color" href="#">
                        privacy@simplifycareers.com
                      </a>{" "}
                      or Simplify Workforce Inc., 10 Exchange Place, Jersey
                      City, NJ 07302. In addition, you have the right to lodge a
                      complaint with your local data processing authority.
                    </p>
                    <p>Last Updated: Jun 14, 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          © 2022 BeanBag.
          {appOrigin == "H"
            ? "Direct Health"
            : appOrigin == "N"
            ? "Neurodiversify"
            : "SimplifyHire"}
          . All rights reserved worldwide.
        </div>
      </section>
    </>
  );
}
