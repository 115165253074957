import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet, Link, useLocation } from "react-router-dom";
import { Button, Grid, Tooltip, Typography, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as DashboardIcon } from "../../assets/images/dash-img-active.svg";
import { ReactComponent as DashboardIcon2 } from "../../assets/images/dash-img.svg";
import { ReactComponent as JoblistingIcon } from "../../assets/images/dashimg2.svg";
import { ReactComponent as ReportsIcon } from "../../assets/images/dashimg3.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/dashimg4.svg";


const drawerWidth = 240;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(open && {
  //     width: `calc(100% - ${drawerWidth}px)`,
  //     marginLeft: `${drawerWidth}px`,
  //     transition: theme.transitions.create(['margin', 'width'], {
  //         easing: theme.transitions.easing.easeOut,
  //         duration: theme.transitions.duration.enteringScreen,
  //     }),
  // }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


export default function Sidebar() {
  const location = useLocation();
  const URLPath = location.pathname;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(localStorage.getItem("sidebarOpened") ? (localStorage.getItem("sidebarOpened") === "true" ? true : false) : true);
  const job_id = URLPath.split("/").pop();
  const job_logs = '/admin/job-details/'+job_id;



  useEffect(() => {
    document.querySelector('body').classList.remove('frontend');
  }, []);


  const handleDrawerOpen = () => {
    localStorage.setItem("sidebarOpened", true);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    localStorage.setItem("sidebarOpened", false);
    setOpen(false);
  };

  let navigate = useNavigate();

  const goToJobsLogs = (e) => {
    e.preventDefault();
    navigate("/admin/jobs-logs");
  };

  const goToJobs = (e) => {
    e.preventDefault();
    navigate("/admin/jobs");
  };

  const goToDashboard = (e) => {
    e.preventDefault();
    navigate("/admin/dashboard");
  };

  const goToJobsBoards = (e) => {
    e.preventDefault();
    navigate("/admin/job-boards-listing");
  };

  const goToJobsBoardsAccess = (e) => {
    e.preventDefault();
    navigate("/admin/job-boards-accesses");
  };

  const goToAccountSetting = (e) => {
    e.preventDefault();
    navigate("/admin/account-setting");
  };

  const goToJobBoardsPerformance = (e) => {
    e.preventDefault();
    navigate("/admin/job-board-performance");
  };

  const openMenu = () => {
    var x = document.getElementById("configure_board").style.display = "block";
  };

  const closeMenu = () => {
    var x = document.getElementById("configure_board").style.display = "none";
  };

  // useEffect(() => {
  //   if ( !localStorage.getItem('token') )
  //     navigate("/login");
  // }, []);

  const getApiDocument = () => {
    let api_documentURL = process.env.REACT_APP_DSAAS_CONDUIT_URL;
    window.open(`${api_documentURL}`);
  };

  return (
    <>
        <Grid item lg={12} md={12} xs={12}>
        <Grid item lg={12} md={12} xs={12} className="w-100">
          <Grid className={isMobile? "w-100":"menu-list w-100"}>
            <Grid className="menu-list" style={{ minWidth: "65px" }}>
              <Box sx={{ display: 'flex' }} >
                <CssBaseline />
                <Grid position="fixed" className="collapse-navbar" open={open}>
                  <Toolbar>
                    <Button
                      // color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      className="collapse-label align-start pl-0 icon-button sidebar-small"
                      sx={{ mr: 0, ml: 0, ...(open && { display: 'none' }) }}
                      role="switch"
                      aria-checked={open ? "true" : "false"}
                      style={{ padding: "6px 8px" }}
                      tabIndex="0"
                      disableRipple={true}
                    >

                      <Grid style={{ minWidth: "65px" }}>
                        <Typography className="mt-15" onClick={handleDrawerOpen} onKeyPress={handleDrawerOpen} tabIndex="0">
                          <Tooltip title="Expand" className='expand-tooltip' placement="right">
                            <ChevronRightIcon className="default-img-border menu-sideIcon-collaped" aria-label="Expand icon" tabIndex="-1" />
                          </Tooltip>
                        </Typography>

                        <>
                          <Typography className="mt-70" tabIndex="0" onClick={goToDashboard}>
                            <Tooltip title="Dashboard" placement="right">
                              {(URLPath === '/admin/dashboard') ?
                                <Typography className="side-menu-bg p-10 justify-center display-flex fastpass-issue">
                                  <DashboardIcon width="16px" height="16px" className={`${window.location.pathname.split("/")[2] === "dashboard" ? "active fill-svg-icons" : ""}`}></DashboardIcon>
                                  {/* <img width="16px" height="16px" src="/beanbag-assets/img/dash-img-active.svg" alt='' className={`${window.location.pathname.split("/")[2] === "dashboard" ? "active" : ""}`} aria-label="Jobs icon" /> */}
                                </Typography> :
                                <Typography className="p-10 justify-center display-flex fastpass-issue">
                                  <DashboardIcon2 width="16px" height="16px" className={`${window.location.pathname.split("/")[2] === "dashboard" ? "active fill-svg-icons" : ""}`}></DashboardIcon2>
                                  {/* <img width="16px" height="16px" src="/beanbag-assets/img/dash-img.svg" alt='' className={`${window.location.pathname.split("/")[2] === "dashboard" ? "active" : ""}`} aria-label="Jobs icon" /> */}
                                </Typography>
                              }
                            </Tooltip>
                          </Typography>

                          <Typography className="mt-70" tabIndex="0" onClick={goToJobs}>
                            <Tooltip title="Job Listing" placement="right">
                              {(URLPath === '/admin/jobs' || URLPath === job_logs) ?
                                <Typography className="side-menu-bg p-10 justify-center display-flex fastpass-issue">
                                  <JoblistingIcon width="16px" height="16px" className={`${window.location.pathname.split("/")[2] === "jobs" || window.location.pathname.split("/")[2] === "job-details" ? "active fill-svg-icons" : ""}`}></JoblistingIcon>
                                  {/* <img width="16px" height="16px" src="/beanbag-assets/img/dashimg2-active.svg" alt='' className={`${window.location.pathname.split("/")[2] === "dashboard" ? "active" : ""}`} aria-label="Jobs icon" /> */}
                                </Typography> :
                                <Typography className="p-10 justify-center display-flex fastpass-issue">
                                  <JoblistingIcon width="16px" height="16px" className={`${window.location.pathname.split("/")[2] === "jobs" ? "active fill-svg-icons" : ""}`}></JoblistingIcon>
                                  {/* <img width="16px" height="16px" src="/beanbag-assets/img/dashimg2.svg" alt='' className={`${window.location.pathname.split("/")[2] === "dashboard" ? "active" : ""}`} aria-label="Jobs icon" /> */}
                                </Typography>
                              }
                            </Tooltip>
                          </Typography>

                          <Typography className="mt-70" tabIndex="0" onClick={goToJobBoardsPerformance}>
                            <Tooltip title="Reports" placement="right">
                              {(URLPath === '/admin/job-board-performance') ?
                                <Typography className="side-menu-bg p-10 justify-center display-flex fastpass-issue">
                                  <ReportsIcon width="16px" height="16px"
                                  className={`${window.location.pathname.split("/")[2] === "job-board-performance" ? "active fill-svg-icons" : ""}`}>
                                  </ReportsIcon>
                                  {/* <img width="16px" height="16px" src="/beanbag-assets/img/dashimg3-active.svg" alt='' className={`${window.location.pathname.split("/")[2] === "dashboard" ? "active" : ""}`} aria-label="Jobs icon" /> */}
                                </Typography> :
                                <Typography className="p-10 justify-center display-flex fastpass-issue">
                                  <ReportsIcon width="16px" height="16px"
                                  className={`${window.location.pathname.split("/")[2] === "job-board-performance" ? "active fill-svg-icons" : ""}`}>
                                  </ReportsIcon>
                                  {/* <img width="16px" height="16px" src="/beanbag-assets/img/dashimg3.svg" alt='' className={`${window.location.pathname.split("/")[2] === "dashboard" ? "active" : ""}`} aria-label="Jobs icon" /> */}
                                </Typography>
                              }
                            </Tooltip>
                          </Typography>


                          <Typography className="mt-50">
                            {/* <Tooltip title="Settings" placement="right"> */}
                              <nav className="nav w-100 ">
                                <ul className="nav__menu " tabIndex="0">
                                  <li className={`nav__menu-item justify-center ${(URLPath === '/admin/job-boards-accesses' || URLPath === '/admin/account-setting') ? "active-list" : ""}`} style={{ paddingTop: "10px", paddingBottom: "8px" }}>
                                    {
                                      (URLPath === '/admin/job-boards-accesses' || URLPath === '/admin/account-setting') ?
                                        <a tabIndex="-1">
                                          <Typography className=" pr-10 pl-10 justify-center">
                                          <SettingsIcon width="16px" height="16px" className="fill-svg-icons"></SettingsIcon>
                                            {/* <img src='/beanbag-assets/img/dashimg4-active.svg' width="16px" height="16px" aria-label="Edit icon" className="inputcontrasts" /> */}

                                          </Typography>
                                        </a> :
                                        <a tabIndex="-1">
                                          <Typography className=" pr-10 pl-10 justify-center">
                                            <img src='/beanbag-assets/img/dashimg4.svg' width="16px" height="16px" aria-label="Edit icon" className="inputcontrasts" />

                                          </Typography>
                                        </a>
                                    }


                                    <ul className="navBar__submenu small-screen-nav sidenav-sub-menu" tabIndex="0">
                                      <li onClick={goToJobsBoardsAccess} className="nav__submenu-item" style={{ paddingTop: '17px' }}  >
                                        <a className="pl-13">
                                          Configure Job Board
                                        </a>
                                      </li>
                                      <li onClick={goToAccountSetting} className="nav__submenu-item pb-15" style={{ paddingTop: '17px' }} >
                                        <a className="pl-13">
                                          Account Settings
                                        </a>
                                      </li>
                                      <li onClick={() => getApiDocument()} className="nav__submenu-item pb-15" style={{ paddingTop: '17px' }} >
                                        <a className="pl-13">
                                          API Document
                                        </a>
                                      </li>
                                    </ul>

                                  </li>
                                </ul>
                              </nav>
                            {/* </Tooltip> */}

                          </Typography>

                        </>
                      </Grid>

                    </Button>
                  </Toolbar>
                </Grid>
                <Drawer
                  sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    mr: 0,
                    '& .MuiDrawer-paper': {
                      width: drawerWidth,
                      boxSizing: 'border-box',
                    },
                  }}
                  variant="persistent"
                  anchor="left"
                  open={open}
                  className="sidenav-collapse"
                //style={{ marginRight: ' 0px ' }}
                // className="expand-mode"
                >
                  <IconButton onClick={handleDrawerClose} onKeyPress={handleDrawerClose} className="mt-20 position-issue icon-button" style={{ padding: '18px 0px 16px 16px' }} aria-label="Collapse icon-btn" role="switch" aria-checked={open ? "true" : "false"} tabIndex="0">
                    <Tooltip title="Collapse" placement="right">
                      <ChevronLeftIcon className="default-img-border menu-sideIcon-expended" style={{ left: '119px' }} aria-label="Collapse icon" tabIndex="-1" />
                    </Tooltip>
                  </IconButton>


                  <Grid className='sidebar-list'>
                    {(URLPath === '/admin/dashboard') ?
                      <Link to="/admin/dashboard" tabIndex="0" onClick={goToDashboard} className={`${window.location.pathname.split("/")[2] === "dashboard" ? "active" : ""}`}>
                        <ul>
                          <li className="active-list cursor-hover ">
                            <DashboardIcon width="16px" height="16px" className={`${window.location.pathname.split("/")[2] === "dashboard" ? "active fill-svg-icons" : ""}`}></DashboardIcon>
                            {/* <img width="16px" height="16px" src="/beanbag-assets/img/dash-img-active.svg" alt='' className={`${window.location.pathname.split("/")[2] === "dashboard" ? "active" : ""}`}  aria-label="Jobs icon" /> */}
                            <label className="primary-shadow-color">Dashboard</label>
                          </li>
                        </ul>
                      </Link>
                      :
                      <Link to="/admin/dashboard" tabIndex="0" onClick={goToDashboard} className={`${window.location.pathname.split("/")[2] === "dashboard" ? "active" : ""}`}>
                        <ul>
                          <li className="cursor-hover mt-20 ">
                            {/* <DashboardIcon width="16px" height="16px" className={`${window.location.pathname.split("/")[2] === "dashboard" ? "active svg-icons" : ""}`}></DashboardIcon> */}
                            <img width="16px" height="16px" src="/beanbag-assets/img/dash-img.svg" alt='' className={`${window.location.pathname.split("/")[2] === "dashboard" ? "active" : ""}`}  aria-label="Jobs icon" />
                            <label className="sub-text-color gray9">Dashboard</label>
                          </li>
                        </ul>
                      </Link>
                    }

                    {(URLPath === '/admin/jobs' || URLPath === job_logs) ?
                      <Link to="/admin/jobs" onClick={goToJobs} className={`${window.location.pathname.split("/")[2] === "jobs" || window.location.pathname.split("/")[2] === "job-details" ? "active" : ""}`}>
                        <ul>
                          <li className="active-list cursor-hover  mt-20 ">

                            <JoblistingIcon width="16px" height="16px" className={`${window.location.pathname.split("/")[2] === "jobs" || window.location.pathname.split("/")[2] === "job-details" ? "active fill-svg-icons" : ""}`}></JoblistingIcon>
                            {/* <img className={`${window.location.pathname.split("/")[2] === "jobs" ? "active" : ""}`}
                              src="/beanbag-assets/img/dashimg2-active.svg" alt='' /> */}
                            <label className="primary-shadow-color">Job Listing</label>
                          </li>
                        </ul>
                      </Link>
                      :
                      <Link to="/admin/jobs" onClick={goToJobs} className={`${window.location.pathname.split("/")[2] === "jobs" ? "active" : ""}`}>
                        <ul>
                          <li className="cursor-hover mt-20 ">
                          <JoblistingIcon width="16px" height="16px" className={`${window.location.pathname.split("/")[2] === "jobs" ? "active fill-svg-icons" : ""}`}></JoblistingIcon>
                            {/* <img className={`${window.location.pathname.split("/")[2] === "jobs" ? "active" : ""}`}
                              src="/beanbag-assets/img/dashimg2.svg" alt='' /> */}
                            <label className="sub-text-color gray9">Job Listing</label>
                          </li>
                        </ul>
                      </Link>
                    }


                    {(URLPath === '/admin/job-board-performance') ?
                      <Link to="/admin/job-board-performance" onClick={goToJobBoardsPerformance}  className={`${window.location.pathname.split("/")[2] === "job-board-performance" ? "active" : ""}`}>
                        <ul>
                          <li className="active-list cursor-hover mt-20 ">
                          <ReportsIcon width="16px" height="16px"
                          className={`${window.location.pathname.split("/")[2] === "job-board-performance" ? "active fill-svg-icons" : ""}`}>
                          </ReportsIcon>
                            {/* <img  className={`${window.location.pathname.split("/")[2] === "job-board-performance" ? "active" : ""}`}
                              src="/beanbag-assets/img/dashimg3-active.svg" alt='' /> */}
                            <label className="primary-shadow-color">Reports</label>
                          </li>
                        </ul>
                      </Link>
                      :
                      <Link to="/admin/job-board-performance" onClick={goToJobBoardsPerformance}  className={`${window.location.pathname.split("/")[2] === "job-board-performance" ? "active" : ""}`}>
                        <ul>
                          <li className="cursor-hover mt-20 ">
                            <ReportsIcon width="16px" height="16px"
                              className={`${window.location.pathname.split("/")[2] === "job-board-performance" ? "active fill-svg-icons" : ""}`}>
                            </ReportsIcon>
                            {/* <img  className={`${window.location.pathname.split("/")[2] === "job-board-performance" ? "active" : ""}`}
                              src="/beanbag-assets/img/dashimg3.svg" alt='' /> */}
                            <label className="sub-text-color gray9">Reports</label>
                          </li>
                        </ul>
                      </Link>
                    }
                    {/*
                <Link to="" onClick={goToJobBoardsPerformance} title="Reports" className={`${window.location.pathname.split("/")[2] === "job-board-performance" ? "active" : ""}`}>
                  <ul>
                    <li className="cursor-hover mt-20 ">
                      <img title="Settings" onMouseEnter={openMenu} onMouseLeave={closeMenu} className={`${(window.location.pathname.split("/")[2] === "job-boards-listing") || window.location.pathname.split("/")[2] === "job-boards-accesses" || window.location.pathname.split("/")[2] === "account-setting" ? "active" : ""}`}
                        src="/beanbag-assets/img/dashimg4.svg" alt='' />
                      <label className="sub-text-color">Settings</label>
                    </li>
                  </ul>
                </Link> */}

                    <Grid className="justify-center display-flex">
                      <nav className="nav w-100 ">
                        <ul className="nav__menu " tabIndex="0">
                          <li
                            className={`nav__menu-item justify-center ${(URLPath === '/admin/job-boards-accesses' || URLPath === '/admin/account-setting') ? "active-list" : ""}`}
                          >
                            {
                              (URLPath === '/admin/job-boards-accesses' || URLPath === '/admin/account-setting') ?
                                <a tabIndex="-1">
                                  <Typography className=" pr-10 pl-10 justify-center">
                                    <SettingsIcon width="16px" height="16px" className="fill-svg-icons"></SettingsIcon>
                                    {/* <img src='/beanbag-assets/img/dashimg4-active.svg' width="16px" height="16px" aria-label="setting icon" /> */}
                                    <label className="primary-shadow-color m-b-0">Settings</label>
                                  </Typography>
                                </a> :
                                <a tabIndex="-1">
                                  <Typography className=" pr-10 pl-10 justify-center">
                                    <img src='/beanbag-assets/img/dashimg4.svg' width="16px" height="16px" aria-label="setting icon" />
                                    <label className="sub-text-color m-b-0 gray9">Settings</label>
                                  </Typography>
                                </a>
                            }


                            <ul className="navBar__submenu sidenav-sub-menu" tabIndex="0">
                              <li onClick={goToJobsBoardsAccess} className="nav__submenu-item" style={{ paddingTop: '17px' }}  >
                                <a className="pl-13 gray8">
                                  Configure Job Board
                                </a>
                              </li>
                              <li onClick={goToAccountSetting} className="nav__submenu-item pb-15" style={{ paddingTop: '17px' }} >
                                <a className="pl-13 gray8">
                                  Account Settings
                                </a>
                              </li>
                              <li onClick={() => getApiDocument()} className="nav__submenu-item pb-15" style={{ paddingTop: '17px' }} >
                                <a className="pl-13 gray8">
                                  API Document
                                </a>
                              </li>
                            </ul>

                          </li>
                        </ul>
                      </nav>
                    </Grid>

                  </Grid>

                </Drawer>
              </Box>
            </Grid>

            <Grid item lg={12} md={12} xs={12}>
              <Main open={open} className="public-view responsive-outlet"
                style={{ paddingRight: '0', paddingBottom: '0', paddingTop: '0' }}
              >
                {/* <DrawerHeader /> */}
                {/* <Grid sx={{mt:10, ml:40}}>
                        <Outlet />
                    </Grid> */}
                <Grid item lg={12} md={12} xs={12} sx={{ ml: 38, ...(open && { marginLeft: ' 135px ' }) }} className="bg-grey responsive-outlet ">
                  <Outlet />
                </Grid>
              </Main>
            </Grid>

          </Grid>
        </Grid>
        </Grid>

    </>
  );
}
