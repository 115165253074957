import { Grid, Typography, Button, Card, CardContent, CardMedia, CardActionArea } from "@mui/material";
import React, { useRef, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import RegisterIcon  from "../assets/images/register.svg";
import OnboardIcon from "../assets/images/onboard.svg";
import BoardsIcon from "../assets/images/boards.svg";
import PostJobIcon from "../assets/images/postjob.svg";
import OptimizeIcon from "../assets/images/optimize.svg";
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/swiper-bundle.min.css';

// Import any additional Swiper modules you need
import { Pagination } from "swiper";

// Import Material-UI components if needed
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    swiperContainer: {
        width: "100%",
        height: "100%", // Adjust the height as per your requirements
        // Apply any additional styling you need
    },
});

export default function HowItWorks(props) {
    const classes = useStyles();
    return (
        <>
            <Grid item
                id="howItWorks"
                ref={props.howItWorksRef}
                sx={{ py: 3, px: 4 }}
                className="pb-80 body-container responsive-direct-source"
            >
                <Grid item lg={12} md={12} xs={12} sx={{ py: 3, mt: 4 }} className="direct-source">
                    <Grid>
                        <Typography className="f-32 launch-page-title text-center">
                                How It Works
                        </Typography>
                        <Grid className="f-32 width-100 mt-10 d-flex justify-center primary-color">
                            <Grid className="title-border"></Grid>
                        </Grid>
                        <Grid>
                            <Typography className="f-16 mt-20 mb-30 text-center text-black">
                                Streamline your hiring process in 5 simple steps with Beanbag.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.swiperContainer}>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        slidesPerGroup={3}
                        pagination={{ dynamicBullets: true, clickable: true }}
                        modules={[Pagination]}
                        style={{
                            "--swiper-pagination-color": "#D0D5DD",
                            "--swiper-pagination-bullet-inactive-color": "#98A2B3",
                            "--swiper-pagination-bullet-inactive-opacity": "1",
                            "--swiper-pagination-bullet-size": "16px",
                            "--swiper-pagination-bullet-horizontal-gap": "6px",
                            "--swiper-pagination-bottom": "0px",
                          }}
                    >
                        <SwiperSlide>
                            <Card className="launch-page-card c-white features-card" sx={{ minHeight: 402, maxWidth: 452, py: 4 }}>
                                <CardActionArea >
                                <Grid className="card-image svg-icons">
                                    <CardMedia
                                        component="img"
                                        height="50"
                                        src={RegisterIcon}
                                        alt="Register icon"
                                        style={{ objectFit: "contain" }}
                                    />
                                    </Grid>
                                    <CardContent>
                                        <Typography className="f-24 text-center launch-page-title1 pb-20">
                                            Register</Typography>
                                        <Typography className="f-16 text-center launch-page-subtext">
                                            Register your account in minutes and get ready to streamline your recruitment process.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card className="launch-page-card c-white features-card" sx={{ minHeight: 402, maxWidth: 452, py: 4 }}>
                                <CardActionArea >
                                <Grid className="card-image">
                                    <CardMedia
                                        component="img"
                                        height="50"
                                        src={OnboardIcon}
                                        alt="Onboard icon"
                                        style={{ objectFit: "contain" }}
                                    />
                                    </Grid>
                                    <CardContent>
                                        <Typography className="f-24 text-center launch-page-title1 pb-20">
                                                Self Onboard</Typography>
                                        <Typography className="f-16 text-center launch-page-subtext">
                                                Quickly set up your profile and get ready to post jobs like never before.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card className="launch-page-card c-white features-card" sx={{ minHeight: 402, maxWidth: 452, py: 4 }}>
                                <CardActionArea >
                                <Grid className="card-image">
                                    <CardMedia
                                        component="img"
                                        height="50"
                                        src={BoardsIcon}
                                        alt="Boards icon"
                                        style={{ objectFit: "contain" }}
                                    />
                                    </Grid>
                                    <CardContent>
                                        <Typography className="f-24 text-center launch-page-title1 pb-20">
                                                Set Your Job Boards</Typography>
                                        <Typography className="text-center launch-page-subtext">
                                                Choose from our extensive list of job boards, search engines, and social media platforms.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card className="launch-page-card c-white features-card" sx={{ minHeight: 402, maxWidth: 452, py: 4 }}>
                                <CardActionArea >
                                <Grid className="card-image">
                                    <CardMedia
                                        component="img"
                                        height="50"
                                        src={PostJobIcon}
                                        alt="PostJob icon"
                                        style={{ objectFit: "contain" }}
                                    />
                                    </Grid>
                                    <CardContent>
                                        <Typography className="f-24 text-center launch-page-title1 pb-20">
                                                Post Jobs with Ease</Typography>
                                        <Typography className="text-center launch-page-subtext">
                                                Create, edit, and post jobs with just a few clicks. Our user-friendly interface makes job posting a walk in the park.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card className="launch-page-card c-white features-card" sx={{ minHeight: 402, maxWidth: 452, py: 4 }}>
                                <CardActionArea >
                                <Grid className="card-image">
                                    <CardMedia
                                        component="img"
                                        height="50"
                                        src={OptimizeIcon}
                                        alt="Optimize icon"
                                        style={{ objectFit: "contain" }}
                                    />
                                    </Grid>
                                    <CardContent>
                                        <Typography className="f-24 text-center launch-page-title1 pb-20">
                                                Analyze and Optimize:</Typography>
                                        <Typography className="text-center launch-page-subtext">
                                                Use our comprehensive analytics to track your job postings' performance. Optimize your strategy and watch your hiring success soar.
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                    </Swiper>
                </Grid>
            </Grid>

        </>

    );
}
