import axios from "axios";
import ApiCalls from "../api/index";

class AccessServiceClass {
  enterpriseUrl = process.env.REACT_APP_DSAAS_ENTERPRISE_URL;
  feedBackUrl = process.env.REACT_APP_DSAAS_BASE_URL + "api/v1/feedback";
  feedBackExist = process.env.REACT_APP_DSAAS_BASE_URL + "api/v1/feedback/exists";
//   enterpriseUrl = "https://qa-services-dsaas.simplifycareers.com/api/v1/enterprises/user/0/1";
  consumer_id= localStorage.getItem("consumer_id");
  async getEnterpriseData(payload) {
    let headers = {
      'Authorization': payload.idToken,
      'x-access-token': payload.accessToken,
    };
    if (payload.enterpriseId) {
      headers['enterpriseId'] = payload.enterpriseId;
    }
    return axios.get(this.enterpriseUrl, {
      headers: headers
    });
  }

  async getisFeedBackExist(payload) {
    let headers = {
      'Authorization': "Bearer " + localStorage.getItem("token"),
      'x-access-token': localStorage.getItem("accessToken"),
    };
    if (localStorage.getItem("enterpriseId")) {
      headers['enterpriseId'] = localStorage.getItem("enterpriseId");
    }
    return axios.get(this.feedBackExist, {
      headers: headers
    });
  }

  async sendFeedBack(payload) {
    let headers = {
      'Authorization': "Bearer " + localStorage.getItem("token"),
      'x-access-token': localStorage.getItem("accessToken"),
    };
    if (localStorage.getItem("enterpriseId")) {
      headers['enterpriseId'] = localStorage.getItem("enterpriseId");
    }
    return axios.post(this.feedBackUrl, payload, {
      headers: headers
    });
  }
}
const AccessService = new AccessServiceClass();
export default AccessService;
