import { useState } from "react";
import { Link, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import ApiCalls from "../../api/index";
import "../../App.css";
import Header from "../../components/header";
import { useEffect } from "react";
import BarLoader from "react-spinners/BarLoader";
import { css } from "@emotion/react";

export default function ResetPassword() {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [color, setColor] = useState("#03a9f4");
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [alert, setAlert] = useState({ status: "", message: "" });

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: #03a9f4;
`;

  useEffect(() => {
    window.history.replaceState({}, document.title);
    console.log(searchParams.get('token'));
    const token = searchParams.get('token');
    }, []);

  const navigate = useNavigate();

  // login
  const handleSubmit = () => {
    if (newPassword.trim() === '') {
      setAlert({ status: 'error', message: 'New Password is required' });
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setAlert({ status: 'error', message: 'New Password and Confirm New Password should be equal' });
      return;
    }
    console.log('customer', newPassword, searchParams.get('token'));
    ApiCalls.patch(`api/password/reset/confirmPasswordReset`, { token: searchParams.get('token'), uidb64: searchParams.get('uidb64'), password: newPassword }).then((response) => {
      if (response.status === 200) {
        console.log(response);
        setIsPasswordReset(true);
        setAlert({ status: 'success', message: response.data.message });
      } else {
        setAlert({ status: 'error', message: response.data.message });
      }
    });
  };

const getConsumer = (user_id) => {
};

  return (
    <>
        <Header/>
        <div className="coming-soon">
        <div className="container" style={{ marginTop: '30px', paddingTop: '20px' }}>
      <div className="row">

        <div
          className="panel panel-color panel-primary panel-pages"
          style={{ width: "30%", marginLeft: "auto", marginRight: "auto", marginTop: "10%" }}
        >
          <div className="panel-body">
          <BarLoader color={color} loading={isLoading} css={ override } size={ 750 } />
            <h3 className="text-center m-t-0 m-b-30" style={{ display: 'none' }}>
              {" "}
              <span className="">
                <img src="/assets/images/big/bigb.png" alt="logo" style={{ height: "auto", maxWidth: "170px" }} />
              </span>
            </h3>
              <h4 className="text-muted text-center m-t-0">
                <br/>
                <b>Reset your password</b>
              </h4>
            <form className="form-horizontal m-t-20">
            <div  style={{ display: "none" }}
                    className={` 
                      ${ alert.status =="success"? "alert alert-success show": "" } 
                      ${ alert.status =="error"? "alert alert-danger show": "" }`}
              >
                <span>{alert.message}</span>
        </div>
              { isPasswordReset === false &&
              <div className="form-group">
                <div className="col-xs-12">
                  <input
                    className="form-control"
                    style={{ fontSize: '14px' }}
                    type="password"
                    required=""
                    placeholder="Enter New Password"
                    value={newPassword}
                    onChange={(event) =>
                      setNewPassword(event.target.value)
                    }
                  />
                </div>
              </div>
          }
            { isPasswordReset === false &&
                <div className="form-group">
                <div className="col-xs-12">
                    <input
                      className="form-control"
                      style={{ fontSize: '14px' }}
                      type="password"
                      required=""
                      placeholder="Confirm New Password"
                      value={confirmNewPassword}
                      onChange={(event) =>
                        setConfirmNewPassword(event.target.value)
                      }
                    />
                  </div>
              </div>
              }
              <div className="form-group">
                <div className="row inline-form">
                  { isPasswordReset &&
                    <div className="col-xs-2" style={{ marginTop: "7px", marginLeft: "150px", marginTop: "30px" }}>
                      <Link style={{ textDecoration: 'underline' }} to="/login">Login</Link>
                  </div>
                }
                </div>

              </div>
              { isPasswordReset === false &&
                  <div className="form-group text-center m-t-20">
                  <div className="col-xs-12">
                    {" "}
                    <button type="button"
                      className="btn btn-primary w-md waves-effect waves-light"
                      style={{ fontSize: '17px' }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              }
              <div className="form-group m-t-30 m-b-0">
                <div className="col-sm-7"></div>
                <div className="col-sm-5 text-right"> </div>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
        </div>
    </>
  );
}
