module.exports = {
  Compensations: [
    "YEARLY",
    "HOURLY",
    "WEEKLY",
    "MONTHLY",
    "DAILY",
    "BIWEEKLY",
    "QUATERLY",
    "PIECE_RATE",
    "OTHER",
  ],
  JobLevels: [
    "INTERNSHIP",
    "GRADUATE",
    "ENTRY_LEVEL",
    "EXPERIENCED",
    "MANAGER",
    "EXECUTIVE",
    "SENIOR_EXECUTIVE",
  ],
  Industries: [
    "ACCOUNTING",
    "AIRLINES_AVIATION",
    "ALTERNATIVE_DISPUTE_RESOLUTION",
    "ALTERNATIVE_MEDICINE",
    "ANIMATION",
    "APPAREL_FASHION",
    "ARCHITECHTURE_PLANNING",
    "ARTS_CRAFTS",
    "AUTOMOTIVE",
    "AVIATION_AEROSPACE",
    "BANKING",
    "BIOTECHNOLOGY",
    "BROADCAST_MEDIA",
    "BUILDING_MATERIALS",
    "BUSINESS_SUPPLIES_EQUIPMENT",
    "CAPITAL_MARKETS",
    "CHEMICALS",
    "CIVIC_SOCIAL_ORGANIZATION",
    "CIVIL_ENGINEERING",
    "COMMERICAL_REAL_ESTATE",
    "COMPUTER_NETWORK_SECURITY",
    "COMPUTER_GAMES",
    "COMPUTER_HARDWARE",
    "COMPUTER_NETWORKING",
    "COMPUTER_SOFTWARE",
    "CONSTRUCTION",
    "CONSUMER_ELECTRONICS",
    "CONSUMER_GOODS",
    "CONSUMER_SERVICES",
    "COSMETICS",
    "DAIRY",
    "DEFENCE_SPACE",
    "DESIGN",
    "EDUCATION_MANAGEMENT",
    "E-LEARNING",
    "ELECTRICAL_ELECTRONIC_MANUFACTURING",
    "ENTERTAINMENT",
    "ENVIRONMENT_SERVICES",
    "ENVENTS_SERVICES",
    "EXECUTIVE_OFFICE",
    "FACILITIES_SERVICES",
    "FARMING",
    "FINANCIAL_SERVICES",
    "FINE_ART",
    "FISHERY",
    "FOOD_BEVERAGES",
    "FOOD_PRODUCTION",
    "FUND-RAISING",
    "FURNITURE",
    "GAMBLING_CASINOS",
    "GLASS_CERAMICS_CONCRETE",
    "GOVERNMENT_ADMINISTRATION",
    "GOVERNMENT_RELATIONS",
    "GRAPHIC_DESIGN",
    "HEALTH_WELLNESS_FITNESS",
    "HIGHER_EDUCATION",
    "HOSPITAL_HEALTH_CARE",
    "HOSPITALITY",
    "HUMAN_RESOURCES",
    "IMPORT_EXPORT",
    "INDIVIDUAL_FAMILY_SERVICES",
    "INDUSTRIAL_AUTOMATION",
    "INFORMATION_SERVICES",
    "INFORMATION_TECHNOLOGY_SERVICES",
    "INSURANCE",
    "INTERNATIONAL_AFFAIRS",
    "INTERNATIONAL_TRADE_DEVELOPMENT",
    "INTERNET",
    "INVESTMENT_BANKING",
    "INVESTMENT_MANAGEMENT",
    "JUDICIARY",
    "LAW_ENFORCEMENT",
    "LAW_PRACTICE",
    "LEGAL_SERVICES",
    "LEGISLATIVE_OFFICE",
    "LEISURE_TRAVEL_TOURISM",
    "LIBRARIES",
    "LOGISTICS_SUPPLY_CHAIN",
    "LUXURY_GOODS_JEWELRY",
    "MACHINERY",
    "MANAGEMENT_CONSULTING",
    "MARITIME",
    "MARKET_RESEARCH",
    "MARKETING_ADVERTISING",
    "MECHANICAL_INDUSTRIAL_ENGINEERING",
    "MEDIA_PRODUCTION",
    "MEDICAL_DEVICES",
    "MEDICAL_PRACTICE",
    "MENTAL_HEALTH_CARE",
    "MILITARY",
    "MINING_METALS",
    "MOTION_PICTURES_FILM",
    "MUSEUMS_INSTITUTIONS",
    "MUSIC",
    "NANOTECHNOLOGY",
    "NEWSPAPERS",
    "NON-PROFIT_ORGANIZATION_MANAGEMENT",
    "OIL_ENERGY",
    "ONLINE_MEDIA",
    "OUTSOURCING_OFFSHORING",
    "PACKAGE_FREIGHT_DELIVERY",
    "PACKING_CONTAINERS",
    "PAPER_FOREST_PRODUCTS",
    "PERFORMING_ARTS",
    "PHARMACEUTICALS",
    "PHILANTHROPY",
    "PHOTOGRAPHY",
    "PLASTICS",
    "POLITICAL_ORGANIZATION",
    "PRIMARY_SECONDARY_EDUCATION",
    "PRINTING",
    "PROFESSIONAL_TRAINING_COACHING",
    "PROGRAM_DEVELOPMENT",
    "PUBLIC_POLICY",
    "PUBLIC_RELATIONS_COMMUNICATIONS",
    "PUBLIC_SAFETY",
    "PUBLISHING",
    "RAILROAD_MANUFACTURING",
    "RANCHING",
    "REAL_ESTATE",
    "RECREATIONAL_FACILITIES_SERVICES",
    "RELIGIOUS_INSTITUTIONS",
    "RENEWABLES_ENVIRONMENT",
    "RESEARCH",
    "RESTAURANTS",
    "RETAIL",
    "SECURITY_INVESTIGATIONS",
    "SEMICONDUCTORS",
    "SHIPBUILDING",
    "SPORTING_GOODS",
    "SPORTS",
    "STAFFING_RECRUITING",
    "SUPERMARKETS",
    "TELECOMMUNICATIONS",
    "TEXTILES",
    "THINK_TANKS",
    "TOBACCO",
    "TRANSLATION_LOCALIZATION",
    "TRANSPORTATION_TRUCKING_RAILROAD",
    "UTILITIES",
    "VENTURE_CAPITAL_PRIVATE_EQUITY",
    "VETERNIARY",
    "WAREHOUSING",
    "WHOLESALE",
    "WINE_SPIRITS",
    "WIRELESS",
    "WRITING_EDITING",
    "NOT_FOUND",
  ],
  SalaryCurrencies: [
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "AOA",
    "ARS",
    "AUD",
    "AWG",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BOB",
    "BRL",
    "BSD",
    "BTN",
    "BWP",
    "BYR",
    "BZD",
    "CAD",
    "CDF",
    "CHF",
    "CLP",
    "CNY",
    "COP",
    "CRC",
    "CUP",
    "CVE",
    "CZK",
    "DJF",
    "DKK",
    "DOP",
    "DZD",
    "EEK",
    "EGP",
    "ERN",
    "ETB",
    "EUR",
    "FJD",
    "FKP",
    "GBP",
    "GEL",
    "GGP",
    "GHS",
    "GIP",
    "GMD",
    "GNF",
    "GTQ",
    "GYD",
    "HKD",
    "HNL",
    "HRK",
    "HTG",
    "HUF",
    "IDR",
    "ILS",
    "IMP",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JEP",
    "JMD",
    "JOD",
    "JPY",
    "KES",
    "KGS",
    "KHR",
    "KMF",
    "KPW",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LRD",
    "LSL",
    "LTL",
    "LVL",
    "LYD",
    "MAD",
    "MDL",
    "MGA",
    "MKD",
    "MMK",
    "MNT",
    "MOP",
    "MRO",
    "MUR",
    "MVR",
    "MWK",
    "MXN",
    "MYR",
    "MZN",
    "NAD",
    "NGN",
    "NIO",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PAB",
    "PEN",
    "PGK",
    "PHP",
    "PKR",
    "PLN",
    "PYG",
    "QAR",
    "RON",
    "RSD",
    "RUB",
    "RWF",
    "SAR",
    "SBD",
    "SCR",
    "SDG",
    "SEK",
    "SGD",
    "SHP",
    "SKK",
    "SLL",
    "SOS",
    "SPL",
    "SRD",
    "STD",
    "SVC",
    "SYP",
    "SZL",
    "THB",
    "TJS",
    "TMM",
    "TND",
    "TOP",
    "TRY",
    "TTD",
    "TVD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "USD",
    "UYU",
    "UZS",
    "VEF",
    "VND",
    "VUV",
    "WST",
    "XAF",
    "XAG",
    "XAU",
    "XCD",
    "XDR",
    "XOF",
    "XPD",
    "XPF",
    "XPT",
    "YER",
    "ZAR",
    "ZMK",
    "ZWD",
    "BEF",
    "DEM",
    "ESP",
    "FRF",
    "GRD",
    "IEP",
    "ITL",
    "NLG",
    "RUR",
    "LUF",
    "FIM",
    "ROL",
    "VEB",
    "MTL",
    "CYP",
    "SIT",
  ],
  JobOccupations: [
    "Accounts_Payable_Receivable",
    "Actuarial_Analysis",
    "Audit",
    "Bookkeeping",
    "Collections",
    "Corporate_Finance",
    "Credit_Review_Analysis",
    "Financial_Analysis_Research_Reporting",
    "Financial_Planning_Advising",
    "Fund_Accounting",
    "Investment_Management",
    "General_Other:_Accounting_Finance",
    "Policy_Underwriting",
    "Real_Estate_Appraisal",
    "Risk_Management_Compliance",
    "Securities_Analysis_Research",
    "Financial_Products_Sales_Brokerage",
    "Tax_Accounting",
    "Tax_Assessment_and_Collections",
    "Claims_Review_and_Adjusting",
    "Corporate_Accounting",
    "Financial_Control",
    "Real_Estate_Leasing_Acquisition",
    "Administrative_Support",
    "Data_Entry_Order_Processing",
    "Executive_Support",
    "Filing_Records_Management",
    "Office_Management",
    "General_Other:_Administrative_Clerical",
    "Reception_Switchboard",
    "Secretary_Executive_Assistant",
    "Claims_Processing",
    "Property_Management",
    "Transcription",
    "Classroom_Teaching",
    "Continuing_Adult",
    "Corporate_Development_and_Training",
    "Customer_Training",
    "Early_Childhood_Care_&_Development",
    "Elementary_School",
    "Fitness_&_Sports_Training_Instruction",
    "Junior_High_School",
    "General_Other:_Training_Instruction",
    "School_College_Administration",
    "Software_Web_Training",
    "Special_Education",
    "University",
    "Aeronautic_Avionic_Engineering",
    "Bio-Engineering",
    "CAD_Drafting",
    "Chemical_Engineering",
    "Civil_&_Structural_Engineering",
    "Electrical_Electronics_Engineering",
    "Energy_Nuclear_Engineering",
    "Environmental_and_Geological_Engineering",
    "Industrial_Manufacturing_Engineering",
    "Mechanical_Engineering",
    "Naval_Architecture_Marine_Engineering",
    "General_Other:_Engineering",
    "RF_Wireless_Engineering",
    "Systems_Process_Engineering",
    "Compensation_Benefits_Policy",
    "Diversity_Management_EEO_Compliance",
    "HR_Systems_Administration",
    "General_Other:_Human_Resources",
    "Payroll_and_Benefits_Administration",
    "Recruiting_Sourcing",
    "Academic_Admissions_and_Advising",
    "Attorney",
    "Contracts_Administration",
    "Labor_&_Employment_Law",
    "General_Other:_Legal",
    "Paralegal_&_Legal_Secretary",
    "Patent_IP_Law",
    "Real_Estate_Law",
    "Regulatory_Compliance_Law",
    "Tax_Law",
    "Account_Management_(Commissioned)",
    "Business_Development_New_Accounts",
    "Field_Sales",
    "Fundraising",
    "Insurance_Agent_Broker",
    "International_Sales",
    "Media_and_Advertising_Sales",
    "Merchandise_Planning_and_Buying",
    "General_Other:_Sales_Business_Development",
    "Real_Estate_Agent_Broker",
    "Retail_Counter_Sales_and_Cashier",
    "Sales_Support_Assistance",
    "Store_Branch_Management",
    "Technical_Presales_Support_&_Technical_Sales",
    "Telesales",
    "Travel_Agent_Ticket_Sales",
    "Visual_Display_Merchandising",
    "Wholesale_Reselling_Sales",
    "Other",
    "Work_at_Home",
    "Food_&_Beverage_Serving",
    "Food_Preparation_Cooking",
    "Front_Desk_Reception",
    "Guest_Services_Concierge",
    "Guide_(Tour)",
    "Host_Hostess",
    "General_Other:_Food_Services",
    "Restaurant_Management",
    "Wine_Steward_(Sommelier)",
    "Assembly_Assembly_Line",
    "Audio_Video_Broadcast_&_Postproduction",
    "Equipment_Operations",
    "Hazardous_Materials_Handling",
    "Layout,_Prepress,_Printing,_&_Binding_Operations",
    "Machining_CNC",
    "Metal_Fabrication_and_Welding",
    "Moldmaking_Casting",
    "Operations_Plant_Management",
    "General_Other:_Production_Operations",
    "Production_Operations_Planning",
    "Sewing_and_Tailoring",
    "Telecommunications_Administration_Management",
    "Waste_Pick-up_and_Removal",
    "Laundry_and_Dry-Cleaning_Operations",
    "Scientific_Technical_Production",
    "Carpentry_Framing",
    "Concrete_and_Masonry",
    "Electrician",
    "Flooring_Tiling_Painting_Wallpapering",
    "Heavy_Equipment_Operation",
    "HVAC",
    "Ironwork_Metal_Fabrication",
    "General_Other:_Construction_Skilled_Trades",
    "Plumbing_Pipefitting",
    "Roofing",
    "Sheetrock_Plastering",
    "Site_Superintendent",
    "Surveying",
    "Account_Management_(Non-Commissioned)",
    "Bank_Teller",
    "Call_Center",
    "Flight_Attendant",
    "Hair_Cutting_Styling",
    "General_Other:_Customer_Support_Client_Care",
    "Reservations_Ticketing",
    "Retail_Customer_Service",
    "Technical_Customer_Service",
    "Computer_Electronics_Telecomm_Install_Maintain_Repair",
    "Equipment_Install_Maintain_Repair",
    "Facilities_Management_Maintenance",
    "Janitorial_&_Cleaning",
    "Landscaping",
    "Locksmith",
    "Oil_Rig_&_Pipeline_Install_Maintain_Repair",
    "General_Other:_Installation_Maintenance_Repair",
    "Vehicle_Repair_and_Maintenance",
    "Wire_and_Cable_Install_Maintain_Repair",
    "Airport_Security_and_Screening",
    "Correctional_Officer",
    "Customs_Immigration",
    "Firefighting_and_Rescue",
    "Military_Combat",
    "General_Other:_Security_Protective_Services",
    "Police-Law_Enforcement",
    "Security_Guard",
    "Store_Security_Loss_Prevention",
    "Security_Intelligence_&_Analysis",
    "Credit_Manager",
    "Loan_Officer_Originator",
    "Mortgage_Broker",
    "Title_Officer_Closer",
    "Underwriter",
    "Escrow_Officer_Manager",
    "Biological_Chemical_Research",
    "Clinical_Research",
    "Environmental_Geological_Testing_&_Analysis",
    "Materials_Physical_Research",
    "Mathematical_Statistical_Research",
    "New_Product_R&D",
    "General_Other:_R&D_Science",
    "Pharmaceutical_Research",
    "Computer_Network_Security",
    "Database_Development_Administration",
    "Desktop_Service_and_Support",
    "Enterprise_Software_Implementation_&_Consulting",
    "IT_Project_Management",
    "Network_and_Server_Administration",
    "General_Other:_IT_Software_Development",
    "Software_System_Architecture",
    "Software_Web_Development",
    "Systems_Analysis_-_IT",
    "Usability_Information_Architecture",
    "Web_UI_UX_Design",
    "Business_Analysis_Research",
    "Business_Unit_Management",
    "Franchise-Business_Ownership",
    "Hospital_Clinic_Administration",
    "Managerial_Consulting",
    "Mergers_and_Acquisitions",
    "General_Other:_Business_Strategic_Management",
    "President_Top_Executive",
    "Public_Health_Administration",
    "Strategic_Planning_Intelligence",
    "Town_City_Planning",
    "Hotel_Lodging_Management",
    "Dental_Practitioner",
    "EMT_Paramedic",
    "Healthcare_Aid",
    "Laboratory_Pathology",
    "Medical_Practitioner",
    "Medical_Therapy_Rehab_Services",
    "Dental_Assistant_Hygienist",
    "Mental_Health",
    "Nursing",
    "Nutrition_and_Diet",
    "Optical",
    "General_Other:_Medical_Health",
    "Pharmacy",
    "Medical_Imaging",
    "Social_Service",
    "Sports_Medicine",
    "Veterinary_Animal_Care",
    "Physician_Assistant_Nurse_Practitioner",
    "Digital_Content_Development",
    "Documentation_Technical_Writing",
    "Editing_&_Proofreading",
    "Journalism",
    "General_Other:_Editorial_Writing",
    "Translation_Interpretation",
    "Car,_Van_and_Bus_Driving",
    "Cargo_and_Baggage_Handling",
    "Cost_Estimating",
    "Equipment_Forklift_Crane_Operation",
    "Import_Export_Administration",
    "Inventory_Planning_and_Management",
    "Messenger_Courier",
    "General_Other:_Logistics_Transportation",
    "Piloting:_Air_and_Marine",
    "Purchasing",
    "Shipping_and_Receiving_Warehousing",
    "Supplier_Management_Vendor_Management",
    "Train_or_Rail_Operator",
    "Truck_Driving",
    "Vehicle_Dispatch,_Routing_and_Scheduling",
    "Brand_Product_Marketing",
    "Copy_Writing_Editing",
    "Direct_Marketing_(CRM)",
    "Events_Promotional_Marketing",
    "Investor_and_Public_Media_Relations",
    "Market_Research",
    "Marketing_Communications",
    "Marketing_Production_Traffic",
    "Media_Planning_and_Buying",
    "General_Other:_Marketing_Product",
    "Product_Management",
    "Telemarketing",
    "Event_Planning_Coordination",
    "General_Other:_Project_Program_Management",
    "Program_Management",
    "Project_Management",
    "Advertising_Writing_(Creative)",
    "Architecture_Interior_Design",
    "Occupational_Health_and_Safety",
    "General_Other:_Quality_Assurance_Safety",
    "Production_Quality_Assurance",
    "Six_Sigma_Black_Belt_TQM",
    "Software_Quality_Assurance",
    "Vehicle_Inspection",
    "Agriculture",
  ],
  SalaryFrequencies: [
    "YEARLY",
    "HOURLY",
    "WEEKLY",
    "MONTHLY",
    "DAILY",
    "BIWEEKLY",
    "QUATERLY",
    "PIECE_RATE",
    "OTHER",
  ],
  JobTypes: [
    "FULL_TIME",
    "TEMPORARY_CONTRACT",
    "INTERNSHIP",
    "CONTRACT",
    "VOLUNTEER",
    "CCL_SPONSORED",
    "TEMPORARY",
  ],
  JobCategories: [
    "Accounting/Finance/Insurance",
    "Administrative/Clerical",
    "Education/Training",
    "Engineering",
    "Human Resources",
    "Legal",
    "Sales/Retail/Business Development",
    "Other",
    "Food Services/Hospitality",
    "Manufacturing/Production/Operations",
    "Building Construction/Skilled Trades",
    "Customer Support/Client Care",
    "Installation/Maintenance/Repair",
    "Security/Protective Services",
    "Banking/Real Estate/Mortgage Professionals",
    "Biotech/R&D/Science",
    "IT/Software Development",
    "Business/Strategic Management",
    "Medical/Health",
    "Editorial/Writing",
    "Logistics/Transportation",
    "Marketing/Product",
    "Project/Program Management",
    "Creative/Design",
    "Quality Assurance/Safety",
    "Agriculture",
    "Forestry",
    "& Fishing",
    "Management",
    "Business_And_Financial_Operations",
    "Computer_Mathematics",
    "Architecture_Engineering",
    "LIFE_PHYSICAL_SOCIAL_SCIENCE",
    "COMMUNITIY_SOCIAL_SERVICES",
    "ART_DESIGN_SPORTS_MEDIA_ENTERTAINMENT",
    "HEALTH_CARE_SUPPORT",
    "PROTECTIVE_SERVICE",
    "BUILDING_GROUNDS_CLEANING_MAINTENANCE",
    "PERSONAL_CARE_AND_SERVICE",
    "CONSTRUCTION_AND_EXTRACTION",
    "PRODUCTION",
    "MILITARY_SPECIFIC",
    "AUTOMATIVE",
    "BUSINESS_OPPORTUNITY",
    "DISTRIBUTION_SHIPPING",
    "ADVERTISING",
    "ANALYST",
    "ART_CREATIVE",
    "CONSULTING",
    "PUBLIC_RELATIONS",
    "PURCHASING",
    "FACILITIES",
    "FRANCHISE",
    "GENERAL_LABOUR",
    "GOVERNMENT_FEDERAL",
    "GROCERY",
    "INVENTORY",
    "LEGAL_ADMIN",
    "PHARMACEUTICAL",
    "PROFESSIONALS_SERVICES",
  ],
  CountryCodes: [
    "AD",
    "AE",
    "AF",
    "AG",
    "AI",
    "AL",
    "AM",
    "AN",
    "AO",
    "AQ",
    "AR",
    "AS",
    "AT",
    "AU",
    "AW",
    "AX",
    "AZ",
    "BA",
    "BB",
    "BD",
    "BE",
    "BF",
    "BG",
    "BH",
    "BI",
    "BJ",
    "BM",
    "BN",
    "BO",
    "BR",
    "BS",
    "BT",
    "BV",
    "BW",
    "BY",
    "BZ",
    "CA",
    "CB",
    "CC",
    "CD",
    "CF",
    "CG",
    "CH",
    "CI",
    "CK",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "CS",
    "CU",
    "CV",
    "CX",
    "CY",
    "CZ",
    "DE",
    "DJ",
    "DK",
    "DM",
    "DO",
    "DZ",
    "EC",
    "EE",
    "EG",
    "EH",
    "ER",
    "ES",
    "ET",
    "FI",
    "FJ",
    "FK",
    "FM",
    "FO",
    "FR",
    "FX",
    "GA",
    "GB",
    "GD",
    "GE",
    "GF",
    "GH",
    "GI",
    "GL",
    "GM",
    "GN",
    "GP",
    "GQ",
    "GR",
    "GS",
    "GT",
    "GU",
    "GW",
    "GY",
    "HK",
    "HM",
    "HN",
    "HR",
    "HT",
    "HU",
    "ID",
    "IE",
    "IL",
    "IN",
    "IO",
    "IQ",
    "IR",
    "IS",
    "IT",
    "JM",
    "JO",
    "JP",
    "KE",
    "KG",
    "KH",
    "KI",
    "KM",
    "KN",
    "KP",
    "KR",
    "KW",
    "KY",
    "KZ",
    "LA",
    "LB",
    "LC",
    "LI",
    "LK",
    "LR",
    "LS",
    "LT",
    "LU",
    "LV",
    "LY",
    "MA",
    "MC",
    "MD",
    "MG",
    "MH",
    "MK",
    "ML",
    "MM",
    "MN",
    "MO",
    "MP",
    "MQ",
    "MR",
    "MS",
    "MT",
    "MU",
    "MV",
    "MW",
    "MX",
    "MY",
    "MZ",
    "NA",
    "NC",
    "NE",
    "NF",
    "NG",
    "NI",
    "NL",
    "NO",
    "NP",
    "NR",
    "NU",
    "NZ",
    "OM",
    "OO",
    "PA",
    "PE",
    "PF",
    "PG",
    "PH",
    "PK",
    "PL",
    "PM",
    "PN",
    "PR",
    "PS",
    "PT",
    "PW",
    "PY",
    "QA",
    "RE",
    "RO",
    "RU",
    "RW",
    "SA",
    "SB",
    "SC",
    "SD",
    "SE",
    "SG",
    "SH",
    "SI",
    "SJ",
    "SK",
    "SL",
    "SM",
    "SN",
    "SO",
    "SR",
    "ST",
    "SV",
    "SY",
    "SZ",
    "TC",
    "TD",
    "TF",
    "TG",
    "TH",
    "TJ",
    "TK",
    "TL",
    "TM",
    "TN",
    "TO",
    "TP",
    "TR",
    "TT",
    "TV",
    "TW",
    "TZ",
    "UA",
    "UG",
    "US",
    "UY",
    "UZ",
    "VA",
    "VC",
    "VE",
    "VG",
    "VI",
    "VN",
    "VU",
    "WF",
    "WS",
    "YE",
    "YT",
    "YU",
    "ZA",
    "ZM",
    "ZW",
    "BQ",
    "CW",
    "GG",
    "IM",
    "JE",
    "ME",
    "RS",
    "SX",
    "SS",
    "BL",
    "MF",
    "UK",
    "UM",
  ],
};
