import { combineReducers } from "@reduxjs/toolkit";
import pageNotFoundReducer from "./pageNotFoundReducer";


const initialState =
{
    "JobTitle": "Data science enginner",
    "JobId": "Req1255",
    "Industry": "Information Technology",
    "Skills": "Tensorflow,Java",
    "SalaryMin": 100,
    "SalaryMax": 1000,
    "EmployeeType": "FULLTIME",
    "Requirements": "Data science engineer with atleast 10 years of experience",
    "JobBody": "Data science engineer with experience in tensorflow",
    "SalaryFrequency": "MONTHLY",
    "SalaryCurrency": "USD",
    "CompanyInfo": {
    "Name": "Viren Sharma",
    "CompanyName": "Authentic Solutions",
    "CompanyDescription": "Company with vision of 10 years",
    "StreetAddress": "Sunnyvale",
    "StreetAddress2": "House 21",
    "City": "Los Angeles",
    "State": "US-CA",
    "CountryCode": "US",
    "PostalCode": "90001",
    "Phone": "7877540939",
    "Email": "viren@gmail.com"
    },
    "PhysicalAddress": {
    "StreetAddress": "Sunnyvale",
    "StreetAddress2": "House 21",
    "City": "Los Angeles",
    "State": "US-CA",
    "CountryCode": "US",
    "PostalCode": "90001"
    },
    "JobLocation": {
    "City": "Los Angeles",
    "State": "US-CA",
    "CountryCode": "US",
    "PostalCode": "90001",
    "Country": "US",
    "Address": "Sunnyvale, CA, US"
    }
    };

const postJobReducer = (state=initialState, action)=>{
    switch (action.type) {
       case "postJob":
        return action.payload;
        default:
            return state;
    }
};

const RootReducer = combineReducers({
    jobs: postJobReducer,
    pageNotFound: pageNotFoundReducer,
});

export default RootReducer;
