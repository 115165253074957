import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import JobsPage from "../pages/JobsPage";
import EditJobPage from "../pages/EditJobPage";
import EditJobsPostedBoard from "../pages/EditJobsPostedBoard";
import JobsLogsPage from "../pages/JobsLogsPage";
// import DashboardPage from "../pages/DashboardPage";
import JobBoardsList from "../pages/JobBoardsList";
import AddJobBoard from "../pages/AddJobBoard";
import CustomersList from "../pages/CustomersList";
import AddCustomer from "../pages/AddCustomer";
import EditCustomer from "../pages/EditCustomer";
import EditBoard from "../pages/EditBoard";
import Login from "../pages/Login";
import CustomerLogin from '../pages/customerAuth/Login';
import ForgotPassword from '../pages/customerAuth/ForgotPassword';
import ResetPassword from '../pages/customerAuth/ResetPassword';
import RegisterRegister from "../pages/customerAuth/Register";
import VerifyActivationEmail from '../pages/customerAuth/VerifyEmail';
import RequestDemo from "../pages/public/RequestDemo";
import CustomerDetails from "../pages/CustomerDetails";
import JobDetails from "../pages/JobDetails";
import MainAdmin from '../containers/layouts/admin';
// import Home  from '../pages/public/Home';
import ChangePassword  from '../pages/ChangePassword';

// Start BeanBag New Design Routes
import SSOLogin from "../Frontend/SSOLoginPage";
import PublicJobListingPage from "../Frontend/PublicJobListingPage";
import RequestDemoNew from "../Frontend/RequestDemo";
import ContactUs from "../Frontend/ContactUs";
import Services from "../Frontend/ServicesPage";
import AboutUs from "../Frontend/AboutUsPage";
import NewLandingPage from "../Frontend/NewLandingPage";
import TermsAndPrivacyPage from "../Frontend/TermsAndPrivacyPage";
import DashboardPage from "../Backend/DashboardPage";
import JobsListingPage from "../Backend/JobsListingPage";
import JobLogsPage from "../Backend/JobLogsPage";
import RejectedJobLogsPage from "../Backend/RejectedJobLogsPage";
import JobViewPage from "../Backend/JobViewPage";
import JobBoardsPage from "../Backend/JobBoardsPage";
import BoardsAccessesPage from "../Backend/BoardsAccessesPage";
import AccountSettingPage from "../Backend/AccountSettingPage";
import JobBoardsPerformancePage from "../Backend/JobBoardsPerformancePage";
import { ToastContainer } from "react-toastify";
import KeycloakService from "../keycloak";
import { useDispatch, useSelector } from "react-redux";
import { getPageNotFoundRecode } from "../store/pageNotFoundReducer.js";
const NotFound = React.lazy(() => import("../PageNotFound.jsx"));

// End BeanBag New Design Routes

const AppWrapper = () => {
  const dispatch = useDispatch();
  const pageNotFoundError = useSelector((state) => state.pageNotFound.error);
  React.useEffect(() => {
    if (pageNotFoundError) {
      dispatch(getPageNotFoundRecode(false));
    }
  }, [window.location]);
  return (
      <Router>
        <Routes>
          {/* <Route exact path="/" element={<Home/>}/> */}
          <Route exact path="/" element={<NewLandingPage/>}/>
          <Route exact path="/sso-login" element={
            <AuthGuard >
              <SSOLogin/>
            </AuthGuard>
          }/>
          <Route exact path="/jobs" element={<PublicJobListingPage/>}/>
          <Route exact path="/request-demo" element={<RequestDemoNew/>}/>
          <Route exact path="/contact-us" element={<ContactUs/>}/>
          <Route exact path="/about-us" element={<AboutUs/>}/>
          <Route exact path="/services" element={<Services/>}/>
          <Route path='terms-and-policy' element={< TermsAndPrivacyPage />} />
          <Route exact path="/admin/login" element={<Login/>}/>
          <Route exact path="/login" element={<CustomerLogin/>}/>
          <Route exact path="/forgot-password" element={<ForgotPassword/>}/>
          <Route exact path="/reset-password" element={<ResetPassword/>}/>
          <Route exact path="/register" element={<RegisterRegister/>}/>
          <Route exact path="/email-verify" element={<VerifyActivationEmail/>}/>
          <Route exact path="/request-a-demo" element={<RequestDemo/>}/>
          <Route path="/admin/*" element={
            <React.Suspense fallback="">
            {pageNotFoundError ?
              <NotFound />
              :
              <RequireAuthForLogin redirectTo="/">
                <MainAdmin />
              </RequireAuthForLogin>
            }
            </React.Suspense>
          }>
            {/* Start BeanBag New Design Routes */}
            <Route path='dashboard' exact element={<DashboardPage />} />
            <Route path='jobs' element={< JobsListingPage />} />
            <Route path='job-details/:job_id' element={< JobViewPage />} />
            <Route path='jobs-logs' element={< JobLogsPage />} />
            <Route path='job-logs' element={< RejectedJobLogsPage />} />
            <Route path='job-boards-listing' element={< JobBoardsPage />} />
            <Route path='job-boards-accesses' element={< BoardsAccessesPage />} />
            <Route path='account-setting' element={< AccountSettingPage />} />
            <Route path='job-board-performance' element={< JobBoardsPerformancePage />} />
            {/* End BeanBag New Design Routes */}
            {/* <Route path='jobs' element={< JobsPage />} /> */}
            {/* <Route path='jobs-logs' element={< JobsLogsPage />} /> */}
            {/* <Route path='jobs/:job_id' element={< JobDetails />} /> */}
            <Route path='jobs/edit/:job_id' element={< EditJobPage />} />
            <Route path='job-posted-board/edit/:board_id' element={< EditJobsPostedBoard />} />
            <Route path='customers' element={<CustomersList />} />
            <Route path='add-customer' element={< AddCustomer />} />
            <Route path='customer/edit/:customer_secret' element={< EditCustomer />} />
            <Route path='boards/edit/:board_id' element={< EditBoard />} />
            <Route path='customer' element={<CustomersList />} />
            <Route path='job-boards' element={< JobBoardsList />} />
            <Route path='add-job-board' element={< AddJobBoard />} />
            <Route path='customers/:customer_secret' element={< CustomerDetails />} />
            <Route path='change-password' element={< ChangePassword />} />
            <Route path="*" element={<NotFound showPageNotFoundError={true} />} />
        </Route>
        <Route path="*" element={<NotFound />} />
        </Routes>
        <ToastContainer hideProgressBar position="top-right" autoClose={5000} />
      </Router>
  );
};

class RootRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  render() {
    return <AppWrapper />;
  }
}

export default RootRoutes;

function AuthGuard({ children }) {
  const location = useLocation();
  const path = location.pathname;

  const isAuthenticated = KeycloakService.isLoggedIn();
  if (!isAuthenticated) {
    KeycloakService.doLogin({ redirectUri: `${window.location.origin}${path}${window.location.search}` });
    return (<div></div>);
  }

  return children;
}

function RequireAuthForLogin({ children, redirectTo }) {
  const { search, pathname: path } = useLocation();
  let isAdmin = localStorage.getItem("is_admin") && (localStorage.getItem("is_admin") === true || localStorage.getItem("is_admin") == 'true');
  let isAuthenticated = KeycloakService.isLoggedIn();
  if (path.includes('/sso-login')) {
    KeycloakService.doLogin({ redirectUri: `${window.location.origin}${window.location.pathname}${window.location.search}` });
    return (<div></div>);
  } else if (!isAuthenticated) {
    KeycloakService.doLogin();
    return (<div></div>);
  }
  return (isAuthenticated && !isAdmin) ? <Navigate to={redirectTo} /> : children;
}
