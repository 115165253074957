import React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import ApiCalls from "../api/index";
import Pagination from "@mui/material/Pagination";

export default function PublicJobListingPage() {
  const [jobs, setJobs] = useState([]);

  const [searchJobTitle, setSearchJobTitle] = useState("");

  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    getJobs();
  }, []);

  const getJobs = () => {
    ApiCalls.get(
      `/api/v1/public/jobs?search=${searchJobTitle}&page=${pageNumber}`
    )
      .then((res) => {
        const { data } = res.data.data;
        setJobs(data);
        setPageNumber(res.data.data.page_number);
        setPageCount(res.data.data.page_count);
        setTotalCount(res.data.data.total_count);
        setTotalPage(res.data.data.total_pages);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  return (
    <>
      <div className="dash_content publicJobs">
        <div className="table-wrapper">
          <div className="row job-head">
            <div className="col-md-2">
              <h5 style={{ fontWeight: "bold", fontSize: "20px" }}><span className='heading-text-color'>Jobs</span></h5>
            </div>
            <div className="col-md-10">
              <div className="input_right">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchJobTitle(e.target.value)}
                />
                <a
                  onClick={() => {
                    getJobs(1);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-search"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="job_listing_table">
            <div className="row">
              <div className="col-md-12">
                <div className="table_inner table-responsive">
                  <table className="table dash_table dash_table_2">
                    <thead>
                      <tr className="tr_target">
                        <th scope="col" className="heading-job">
                          Job Title
                        </th>
                        <th scope="col">Posted Date</th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobs?.map((job, index) => {
                        return (
                          <>
                            <tr className="tr_target" key={index}>
                              <td className="th_target">
                                <span className="target_span">
                                  {index + 1}. &nbsp;
                                </span>{" "}
                                {job.job_title}
                              </td>
                              <td className="td_target">
                                {moment(job.request_timestamp).format(
                                  "MMM DD, YYYY"
                                )}
                              </td>
                              <td className="center_heading td_target">
                                <a style={{ cursor: "pointer" }}>
                                  <i>
                                    <img src="/beanbag-assets/img/edit_black.png" />
                                  </i>
                                </a>
                                <a style={{ cursor: "pointer" }}>
                                  <i>
                                    <img
                                      src="/beanbag-assets/img/trash_black.png"
                                      className="black_img"
                                    />
                                  </i>
                                </a>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {totalCount >= 10 && (
            <div className="row">
              <div className="col-md-6">
                <p className="s-p-l-ten">
                  Showing <span className="per-page-count-box">10</span> item
                  per page
                </p>
              </div>
              <div className="col-md-6">
                <div className="pagination">
                  <Pagination
                    count={totalPage}
                    page={pageNumber}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
