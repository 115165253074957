import React from 'react';
import { useEffect } from "react";
import Navbar from "../../pages/Navbar";
import { Outlet } from "react-router-dom";
import Header from '../../layout/Backend/Header';
import Sidebar from '../../layout/Backend/Sidebar';
import {  Grid } from '@mui/material';

export default function AdminPage() {
    console.log('render Main Admin');
    useEffect(() => {
        document.querySelector('body').classList.add('back_end_body');
    }, []);

    return (
        <>
        <Header />
        <Grid className="dashboard-wrapper">
            <Sidebar />
            {/* <Outlet /> */}
        </Grid>

            {/* <Navbar />
            <div class="content-page">
                <div class="content">
                    <div class="container">
                        <Outlet />
                    </div>
                </div>
                <footer class="footer">
                    2021 - 2022 © Beanbag
                </footer>
            </div> */}
        </>
    );
}
