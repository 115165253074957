import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, Container } from "@mui/material";
import HomeImg from "../assets/images/home_img.svg";
import HomeImg2 from "../assets/images/home_img2.svg";
import Careerbuilder from "../assets/images/Careerbuilder-logo.svg";
import Linkedin from "../assets/images/Linkedin-logo.svg";
import Indeed from "../assets/images/Indeed-logo.svg";
import Glassdoor from "../assets/images/Glassdoor-Logo.svg";
import Monster from "../assets/images/Monster-logo.svg";
import SimplifyHire from "../assets/images/SimplifyHire.svg";
import Totaljobs from "../assets/images/Totaljobs_logo.svg";
import cvl from "../assets/images/Cvl_logo.svg";
import Adzuna from "../assets/images/Adzuna_Logo.svg";
import ZipRecruiter from "../assets/images/ZipRecruiter_Logo.svg";

import Jobboards from "../assets/images/Jobboards.svg";
import SmartManagement from "../assets/images/SmartMang.svg";
import Performance from "../assets/images/Performance.svg";
import GrowingNetwork from "../assets/images/GrowingNetwork.svg";

import Pwc from "../assets/images/pwc.svg";
import Ustech from "../assets/images/ustech.svg";
import Tufts from "../assets/images/tufts.svg";
import Dsaas from "../assets/images/dsaas.svg";

import HowItWorks from "../Frontend/HowItWorks";
import NewFooter from "../layout/Frontend/NewFooter";

import Navbar from "../layout/Frontend/NavbarThree";
import { useRef } from "react";
import KeycloakService from "../keycloak";
import Axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import AccessService from "../Services/Access.service";
import Loader from "../Loader";
import { setPlatformTheme } from "../helpers/helper";

export default function NewLandingPage(props) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const homeRef = useRef();
  const howItWorksRef = useRef();
  const ourClients = useRef();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [showHomepage, setShowHomepage] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();
  let baseURL = process.env.REACT_APP_BASE_URL;
  let appOrigin = process.env.REACT_APP_ORIGIN;
  const signInPage = (e) => {
    e.preventDefault();
    window.open("../sso-login", "_self");
  };
  useEffect(() => {
    const asyncOps = async () => {
      try {
        await authorizeSSO();
      } catch (err) {
        console.error('error while sso login backend request ->', err);
      }
    };

    if (KeycloakService.isLoggedIn()) {
      asyncOps();
    } else {
      setShowHomepage(true);
      setIsLoading(false);
      navigate("/");
    }
  }, []);
  const getDetails = async () => {
    let payloads = {};
    let token = localStorage.getItem("token");
    let accessToken = localStorage.getItem("accessToken");
    payloads.idToken = "Bearer " + token;
    payloads.accessToken = accessToken;
    if (localStorage.getItem("enterpriseId")) {
      payloads.enterpriseId = localStorage.getItem("enterpriseId");
    }
    if (localStorage.getItem("appOrigin") == "N") {
      document.documentElement.style.setProperty(
          "--primary-color",
          "#055094",
          "important"
      );
      document.documentElement.style.setProperty(
          "--secondary-color",
          "#EAF4FF",
          "important"
      );
      document.documentElement.style.setProperty(
          "--font-family",
          "Lato",
          "important"
      );
      document.documentElement.style.setProperty(
          "--font-size-10",
          "12px",
          "important"
      );
      document.documentElement.style.setProperty(
          "--font-size-12",
          "14px",
          "important"
      );
      document.documentElement.style.setProperty(
          "--font-size-14",
          "16px",
          "important"
      );
      document.documentElement.style.setProperty(
          "--font-size-16",
          "18px",
          "important"
      );
      document.documentElement.style.setProperty(
          "--font-size-18",
          "20px",
          "important"
      );
      document.documentElement.style.setProperty(
          "--font-size-20",
          "24px",
          "important"
      );
      document.documentElement.style.setProperty(
          "--font-size-24",
          "26px",
          "important"
      );
      document.documentElement.style.setProperty(
          "--font-size-28",
          "30px",
          "important"
      );
      document.documentElement.style.setProperty(
          "--font-size-36",
          "38px",
          "important"
      );
      document.documentElement.style.setProperty(
          "--gray6",
          "#344054",
          "important"
      );
      document.documentElement.style.setProperty(
          "--gray7",
          "#344054",
          "important"
      );
      document.documentElement.style.setProperty(
          "--gray8",
          "#1D2939",
          "important"
      );
      document.documentElement.style.setProperty(
          "--gray9",
          "#1D2939",
          "important"
      );
      document.documentElement.style.setProperty(
          "--gray10",
          "#1D2939",
          "important"
      );
      document.documentElement.style.setProperty(
          "--gray11",
          "#1D2939",
          "important"
      );
    }
    await AccessService.getEnterpriseData(payloads).then((res) => {
      let enterpriseUser = res.data.responsePayload[0];
      setPlatformTheme(enterpriseUser.theme);
      localStorage.setItem("enterprise_name", enterpriseUser.establishmentName);
      localStorage.setItem(
          "user_name",
          enterpriseUser.firstName ? enterpriseUser.firstName : '' + " " + enterpriseUser.lastName ? enterpriseUser.lastName : ''
      );
      localStorage.setItem(
          "profile_image",
          enterpriseUser.logoFileSignedUrl
              ? enterpriseUser.logoFileSignedUrl
              : "https://daas-prog-test.s3.amazonaws.com/default-logo.png"
      );
      localStorage.setItem("email", enterpriseUser.emailAddress);
      if (enterpriseUser.userType === "T" || enterpriseUser.userType === "R" || enterpriseUser.userType === "A" || enterpriseUser.userType === "Z") {
        setShowHomepage(true);
        localStorage.setItem("is_admin", true);
        navigate("/admin/dashboard");
      } else {
        setShowHomepage(false);
        localStorage.setItem("is_admin", false);
        navigate("/");
      }
    });
  };
  const authorizeSSO = () => new Promise((resolve, reject) => {
    document.documentElement.style.setProperty(
        "--loader-bg",
        "#d6d7d9",
        "important"
    );
    document.title = `SSO Login | ${
        appOrigin == "H"
            ? "Direct Health"
            : appOrigin == "N"
                ? "Neurodiversify"
                : "SimplifyHire"
    }`;
    let payload = {
      token: localStorage.getItem("token"),
      accessToken: localStorage.getItem("accessToken")
    };
    if (params.get("enterpriseId") || localStorage.getItem("enterpriseId")) {
      payload["enterpriseId"] = params.get("enterpriseId") ? params.get("enterpriseId") : localStorage.getItem("enterpriseId");
    }
    Axios.post(baseURL + `/api/v1/auth/sso/token`, payload)
        .then(async (res) => {
          const { data } = res.data;
          localStorage.setItem("consumer_id", data.data.user.consumer_id);
          localStorage.setItem("name", data.data.user.name);
          localStorage.setItem("api_access_key", data.data.user.api_access_key);
          localStorage.setItem("mode", data.data.user.mode);
          localStorage.setItem("appOrigin", params.get("appOrigin"));
          if (params.get("enterpriseId")) {
            localStorage.setItem("enterpriseId", params.get("enterpriseId"));
          }
          await getDetails();
          setIsLoading(false);
          // setTimeout(() => {
          //     navigate("/admin/dashboard");
          // }, 1000);
          resolve();
        })
        .catch((err) => {
          // toast.error(err.message)
          if (err.response.status == 401) {
            setTestMode(true);
          }
          setIsLoading(false);
          reject(err);
          // setTimeout(() => {
          //     navigate("/");
          // }, 3000);
        });
  });
  return (
    <>
      {isLoading && <Loader />}
      {showHomepage ? (
          <>
            <Navbar
                homeRef={homeRef}
                howItWorksRef={howItWorksRef}
                ourClients={ourClients}
            />
            <section>
              <Grid
                  container
                  spacing={1}
                  lg={12}
                  md={12}
                  xs={12}
                  sx={{ px: 16 }}
                  id="home"
                  ref={homeRef}
                  className={`${
                      appOrigin === "neurodiversify"
                          ? "mt-60 align-item-center bgImageNeuro posting-job-align img-mobile-height"
                          : "mt-60 align-item-center bgImage posting-job-align img-mobile-height"
                  }`}
              >
                <Grid item lg={6} md={6} xs={12} className="m-0">
                  <Typography
                      variant="h3"
                      className="font-weight-700 f-42"
                      style={{
                        color:
                            process.env.REACT_APP_ORIGIN === "neurodiversify"
                                ? "#055094"
                                : "#000000",
                      }}
                  >
                    Are you tired of manually posting jobs on different platforms, one
                    by one?
                  </Typography>
                  <Typography
                      className="mt-20 f-16 light-text"
                      style={{ color: "#363049" }}
                  >
                    Say goodbye to the time-consuming, repetitive task of posting jobs
                    individually on various platforms. Beanbag is here to
                    revolutionize the way you recruit talent.
                  </Typography>

                  <Typography style={{ marginTop: "40px" }}>
                    <Button
                        type="submit"
                        className="btn primary-button"
                        variant="contained"
                        aria-label="Sign in"
                        disableRipple={true}
                        style={{ padding: "8px 24px" }}
                        onClick={signInPage}
                    >
                      Get Started
                    </Button>
                  </Typography>
                </Grid>
                <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                    className="c-white justify-end d-flex"
                >
                  {appOrigin === "neurodiversify" ? (
                      <img src={HomeImg2} className="bg1-height img-mobile-width"></img>
                  ) : (
                      <img src={HomeImg} className="bg1-height img-mobile-width"></img>
                  )}
                </Grid>
              </Grid>
              <Grid sx={{ px: 11 }} className="posting-job-align">
                <Typography className="f-20 font-weight-500 primary-color mt-45 d-flex">
                  Our Partnered Job Boards
                </Typography>
                <Grid className="mobile-logs">
                  <Grid className="justify-between mt-20 job-boards-justify-between responsive-block">
                    <img alt="" src={Careerbuilder} />
                    <img alt="" src={Linkedin} />
                    <img alt="" src={Indeed} />
                    <img alt="" src={Glassdoor} />
                    <img alt="" src={Monster} />
                  </Grid>
                  <Grid className="justify-between mt-20 job-boards-justify-between responsive-block">
                    <img alt="" src={SimplifyHire} />
                    <img alt="" src={Totaljobs} />
                    <img alt="" src={cvl} />
                    <img alt="" src={Adzuna} />
                    <img alt="" src={ZipRecruiter} />
                  </Grid>
                </Grid>
                <Typography className="mt-30 f-20 font-weight-500 primary-color text-center">
                  <span className="font-weight-700">100+</span>&nbsp;
                  <span>more job boards</span>
                </Typography>
              </Grid>

              <Grid item sx={{ pb: 3, px: 4 }} className="body-container">
                <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    sx={{ px: 15, py: 3, mt: 4 }}
                    className="d-flex justify-center third-sec responsive-third-block"
                >
                  <Grid
                      item
                      lg={10}
                      md={12}
                      xs={12}
                      className="d-flex responsive-block"
                  >
                    <Grid item lg={6} md={12} xs={12} className="p-30">
                      <img
                          src={Jobboards}
                          width="408"
                          height="348"
                          alt="Job boards"
                          className="responsive-img-block"
                      />
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        md={12}
                        xs={12}
                        sx={{ px: 15 }}
                        className="responsive-third-block"
                    >
                      <Typography className="f-30 launch-page-title pt-35">
                        Effortless Posting
                      </Typography>
                      <Grid className="width-100 mt-10 d-flex ">
                        <Grid className="title-border"></Grid>
                      </Grid>
                      <Typography className="pb-15 launch-page-subtext pt-15">
                        With Beanbag, you can effortlessly share your job openings
                        across multiple job boards, search engines, and social media
                        platforms, all at once. No more logging in and out of multiple
                        accounts or spending hours on the same task.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    sx={{ px: 15, py: 3 }}
                    className="d-flex justify-center third-sec responsive-third-block"
                >
                  <Grid
                      item
                      lg={10}
                      md={12}
                      xs={12}
                      className="d-flex responsive-block"
                  >
                    <Grid
                        item
                        lg={6}
                        md={12}
                        xs={12}
                        sx={{ px: 15 }}
                        className="responsive-third-block"
                    >
                      <Typography className="f-30 launch-page-title pt-55">
                        Smart Management
                      </Typography>
                      <Grid className="width-100 mt-10 d-flex ">
                        <Grid className="title-border"></Grid>
                      </Grid>
                      <Typography className="pb-15 launch-page-subtext pt-15">
                        Not only do we make posting easy, but we also help you manage
                        your job listings like a pro. Our intuitive dashboard allows
                        you to track the performance of each posting. Know which
                        platforms are delivering the best candidates and where you
                        need to tweak your strategy.
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12} className="p-30">
                      <img
                          src={SmartManagement}
                          className="responsive-img-block"
                          width="408"
                          height="265"
                          alt="Finding smart management"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    sx={{ px: 15, py: 3, mt: 4 }}
                    className="d-flex justify-center mobile-logs third-sec responsive-third-block"
                >
                  <Grid
                      item
                      lg={10}
                      md={12}
                      xs={12}
                      className="d-flex responsive-block"
                  >
                    <Grid item lg={6} md={12} xs={12} className="p-30">
                      <img
                          src={Performance}
                          width="408"
                          height="265"
                          alt="Performance"
                          className="responsive-img-block"
                      />
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        md={12}
                        xs={12}
                        sx={{ px: 15 }}
                        className="responsive-third-block"
                    >
                      <Typography className="f-30 launch-page-title pt-55 responsive-content ">
                        Performance Insights
                      </Typography>
                      <Grid className="width-100 mt-10 d-flex ">
                        <Grid className="title-border"></Grid>
                      </Grid>
                      <Typography className="pb-15 launch-page-subtext pt-15">
                        Beanbag provides in-depth insights into your job listings'
                        performance for each platform. Make data-driven decisions to
                        optimize your recruitment strategy and get the best results.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    sx={{ px: 15, py: 3 }}
                    className="d-flex justify-center third-sec responsive-third-block"
                >
                  <Grid
                      item
                      lg={10}
                      md={12}
                      xs={12}
                      className="d-flex responsive-block"
                  >
                    <Grid
                        item
                        lg={6}
                        md={12}
                        xs={12}
                        sx={{ px: 15 }}
                        className="responsive-third-block"
                    >
                      <Typography className="f-30 launch-page-title pt-55 responsive-content ">
                        Growing Network
                      </Typography>
                      <Grid className="width-100 mt-10 d-flex ">
                        <Grid className="title-border"></Grid>
                      </Grid>
                      <Typography className="pb-15 launch-page-subtext pt-15">
                        We're not just limited to a few job boards; we've integrated
                        with 100+ job boards, search engines, and social media sites.
                        This means your job posts reach a broader audience, increasing
                        your chances of finding the perfect candidate.
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12} className="p-30">
                      <img
                          src={GrowingNetwork}
                          className="responsive-img-block"
                          width="408"
                          height="265"
                          alt="Finding top talent"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <HowItWorks howItWorksRef={howItWorksRef} />
              <Grid
                  sx={{ px: 15 }}
                  id="ourClients"
                  ref={ourClients}
                  className="posting-job-align theme_border"
              >
                <Typography className="f-20 font-weight-500 primary-color mt-45 d-flex">
                  Our Clients
                </Typography>
                <Typography className="f-30 font-weight-600 mt-15 d-flex">
                  Discover Who We Work With
                </Typography>
                <Grid className="mobile-logs">
                  <Grid className="justify-between mt-80 mb-80 job-boards-justify-between responsive-block">
                    <img alt="" src={Pwc} />
                    <img alt="" src={Ustech} />
                    <img alt="" src={Tufts} />
                    <img
                        alt=""
                        src={`${
                            appOrigin === "neurodiversify"
                                ? "https://daas-prog-test.s3.amazonaws.com/app_logo_neurodiversify.png"
                                : "https://daas-prog-test.s3.amazonaws.com/app_logo_general.png"
                        }`}
                        height={90}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  className={`${
                      appOrigin === "neurodiversify"
                          ? "bgImage4 svg-bg-color"
                          : "bgImage3 svg-bg-color"
                  }`}
              >
                <Container className="mt-10 mb-10 d-flex justify-center align-center width-42">
                  <Grid className="text-center start-line">
                    <Typography
                        variant="h4"
                        className="f-42 font-weight-600 primary-color"
                        style={{ color: "#2D1D61" }}
                    >
                      Want To Post Job On Multiple Job Boards?
                    </Typography>

                    <Typography style={{ marginTop: "40px" }}>
                      <Button
                          type="submit"
                          className="btn primary-button"
                          variant="contained"
                          aria-label="Sign in"
                          disableRipple={true}
                          style={{ padding: "8px 60px" }}
                          onClick={signInPage}
                      >
                        Let's Start
                      </Button>
                    </Typography>
                  </Grid>
                </Container>
              </Grid>
              <NewFooter
                  homeRef={homeRef}
                  howItWorksRef={howItWorksRef}
                  ourClients={ourClients}
              />
            </section>
          </>
      ) : (
          <>
            <div className="first-job-sec container_bb" style={{ height: "500px" }}>
              <h4
                  className="sub_headig"
                  style={{ textAlign: "center", marginTop: "128px" }}
              >
                <img alt="" src="/beanbag-assets/img/Access-denied.svg" />
              </h4>
              <p className="mt-10 f-24 font-weight-800 light-text" style={{ textAlign: "center", letterSpacing: "0.00938em", lineHeight: "1.5" }}>Access Denied</p>
              <p className="mt-30 mb-70 f-16 font-weight-600 light-text" style={{ textAlign: "center" }}>
                You don't have permission to view this page using the credential you have supplied.
              </p>
            </div>
          </>
      )}
    </>
  );
}
