import { useState } from "react";
import ApiCalls from "../api/index";
import "../App.css";

export default function ChangePassword() {
  const userName = localStorage.getItem("username");
  const [user, setUser] = useState({
    username: userName,
    password: "",
    confirm_password: "",
  });
  const [alert, setAlert] = useState({ status: "", message: "" });

  const handleChange = (event, fieldName) => {
    if (fieldName === "password") {
      setUser((prevState) => {
        return { ...prevState, password: event.target.value };
      });
    }
    if (fieldName === "confirm_password") {
      setUser((prevState) => {
        return { ...prevState, confirm_password: event.target.value };
      });
    }
  };

  const changePassword = () => {
    ApiCalls.post(`/api/auth/change-password`, user)
      .then((res) => {
        setAlert({ status: "success", message: res.data.message });
        setUser({ password: "", confirm_password: "" });
      })
      .catch((err) => {
        setAlert({ status: "error", message: err.response.data.error });
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="panel panel-primary">
            <div className="panel-heading">
              <h3 className="panel-title">Change Password</h3>
            </div>
            <div
              style={{ display: "none", marginTop: "10px" }}
              className={`
                      ${
                        alert.status == "success"
                          ? "alert alert-success show"
                          : ""
                      }
                      ${
                        alert.status == "error" ? "alert alert-danger show" : ""
                      }`}
            >
              <span>{alert.message}</span>
            </div>
            <div className="panel-body">
              <form role="form">
                <div className="form-group">
                  <label htmlFor="customer_name">Password</label>
                  <input
                    style={{ maxWidth: "300px" }}
                    type="password"
                    className="form-control"
                    id="customer_password"
                    value={user.password}
                    onChange={(event) => handleChange(event, "password")}
                    placeholder="Enter password"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="customer_name">Confirm Password</label>
                  <input
                    style={{ maxWidth: "300px" }}
                    type="password"
                    className="form-control"
                    id="customer_password"
                    value={user.confirm_password}
                    onChange={(event) =>
                      handleChange(event, "confirm_password")
                    }
                    placeholder="Enter confirm password"
                  />
                </div>
                <br />
                <button
                  type="button"
                  onClick={changePassword}
                  className="btn btn-primary pull-right"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
