import * as React from "react";
import ApiCalls from "../api/index";
import "../App.css";
import moment from "moment";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid";
import XMLViewer from "react-xml-viewer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "400px",
  minWidth: "800px",
  overflowX: "auto !important",
  overflowY: "auto !important",
};

export default function HomePage(props) {
  const [jobBoards, setJobBoards] = React.useState([]);
  const [, updateState] = React.useState();
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false);
  const [deletingId, setDeletingId] = React.useState(null);
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [alert, setAlert] = useState({ status: "", message: "" });

  useEffect(() => {
    ApiCalls.get(`/api/job-boards`).then((res) => {
      if (res) {
        const { data } = res.data;
        setJobBoards(data);
        forceUpdate();
      }
    });
  }, []);

  let navigate = useNavigate();
  const goToAddJobBoard = () => {
    navigate("/admin/add-job-board");
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) navigate("/login");
  }, []);

  const editBoard = (board_id) => {
    navigate(`/admin/boards/edit/${board_id}`);
  };

  const handleDeleteBoard = (board_id) => {
    if (window.confirm("Are you sure want to delete?")) {
      //Logic to delete the jobBoard
      setDeletingId(board_id);
      setIsDeleteLoading(true);
      ApiCalls.delete(`/api/job-boards/${board_id}/delete`).then((res) => {
        console.log(res.status);
        if (res.status === 204) {
          setJobBoards(jobBoards.filter((board) => board.id !== board_id));

          setAlert({
            status: "success",
            message: "Job board deleted successfully!",
          });
          setTimeout(() => {
            setAlert({ status: "", message: "" });
          }, 2000);
        }
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-primary">
            <button
              onClick={goToAddJobBoard}
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "15px",
              }}
              className="btn btn-primary"
            >
              Add Job Board
            </button>
            <div
              style={{ display: "none", marginTop: "10px" }}
              className={`
                        ${
                          alert.status == "success"
                            ? "alert alert-success show"
                            : ""
                        }
                        ${
                          alert.status == "error"
                            ? "alert alert-danger show"
                            : ""
                        }`}
            >
              <span>{alert.message}</span>
            </div>
            <div className="panel-heading">
              <h3 className="panel-title">Job Boards</h3>
            </div>
            <div className="panel-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="table-responsive">
                    <table
                      id="datatable"
                      className="table table-striped table-bordered"
                    >
                      <thead>
                        <tr>
                          <th>Board name</th>
                          <th>Created At</th>
                          <th style={{ display: "none" }}>Api Url</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobBoards.map((jobBoard) => {
                          return (
                            <>
                              <tr>
                                <td style={{ textTransform: "capitalize" }}>
                                  {jobBoard.name}
                                </td>
                                <td>
                                  {moment(jobBoard.created_at).format(
                                    "MMM DD, YYYY"
                                  )}
                                </td>
                                <td style={{ display: "none" }}>
                                  {jobBoard.api_url}
                                </td>
                                <td
                                  className="text-center"
                                  style={{ width: "150px" }}
                                >
                                  <a
                                    onClick={() => editBoard(jobBoard.id)}
                                    style={{
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                    }}
                                    title="Edit"
                                  >
                                    <i className="ti-pencil"></i>
                                  </a>
                                  <a
                                    style={{
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleDeleteBoard(jobBoard.id)
                                    }
                                    title="Delete"
                                  >
                                    <i className="ti-trash"></i>{" "}
                                    {isDeleteLoading &&
                                    deletingId === jobBoard.id
                                      ? "Deleting..."
                                      : ""}
                                  </a>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
