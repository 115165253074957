import React from 'react';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";

import { ReactComponent as SupportIcon } from "../../assets/images/Support.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/menu-icon.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/dash-img.svg";
import { ReactComponent as JoblistingIcon } from "../../assets/images/dashimg2.svg";
import { ReactComponent as ReportsIcon } from "../../assets/images/dashimg3.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/dashimg4.svg";
import LogoutIcon from "../../assets/images/logout-navIcon.svg";
import {
    Tooltip,
    Box,
    Button,
    Grid,
    Typography,
    Container, Card, MenuItem, Menu
} from "@mui/material";
import { useEffect, useCallback, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FeedBackModal from "./FeedBackModal";
import Loader from "../../Loader";
import AccessService from "../../Services/Access.service";
import { getUserType, logout, setPlatformTheme } from "../../helpers/helper";


export default function Header() {
    let navigate = useNavigate();
    const location = useLocation();
    const search = location.search;
    const URLPath = location.pathname;
    const params = new URLSearchParams(location.search);

    const [anchorE2, setAnchorE2] = useState(null);
    const [loading, setLoading] = useState(false);
    const open2 = Boolean(anchorE2);
    const [openfeedback, setOpenFeedback] = useState(false);
    const [enterprisename, setEnterprisename] = useState();


    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl4, setAnchorEl4] = React.useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const handleMenuBar = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const open = Boolean(anchorEl);
    const handleCloseMenu = (event) => {
        setAnchorEl(null);
      };

    const goToDashboard = () => {
        setAnchorEl(null);
        if (
            URLPath === "/admin/dashboard"
        ) {
            window.location.reload(false);
        } else {
            navigate("/admin/dashboard");
        }
    };

    const goToJobs = () => {
        setAnchorEl(null);
        if (URLPath === "/admin/jobs") {
          window.location.reload(false);
        } else {
          navigate("/admin/jobs");
        }
    };

    const goToReports = () => {
        setAnchorEl(null);
        if (URLPath === "/admin/job-board-performance") {
            window.location.reload(false);
        } else {
            navigate("/admin/job-board-performance");
        }
    };

    const goToJobsBoardsAccess = () => {
        setAnchorEl(null);
        if (URLPath === "/admin/job-boards-accesses") {
          window.location.reload(false);
        } else {
          navigate("/admin/job-boards-accesses");
        }
    };

    const goToAccountSetting = () => {
        setAnchorEl(null);
        if (URLPath === "/admin/account-setting") {
          window.location.reload(false);
        } else {
          navigate("/admin/account-setting");
        }
    };

    const getApiDocument = () => {
    let api_documentURL = process.env.REACT_APP_DSAAS_CONDUIT_URL;
    window.open(`${api_documentURL}`);
    };

    useEffect(()=>{
        document.documentElement.style.setProperty("--loader-bg", '#d6d7d9', "important");
        getlocalstorage();
       getDetails();
    }, []);
    const handleClick2 = (event) => {
        setAnchorE2(event.currentTarget);
    };

    const getlocalstorage = useCallback(() => {
        if (localStorage.getItem("email") == null) {
            setLoading(true);
            setTimeout(() => {
                getlocalstorage();
              }, 250);
        } else {
            setLoading(false);
        }
    }, []);

    const handleClose2 = () => {
        setAnchorE2(null);
    };

    const Logout = async () => {
        await logout();
        navigate("/");
    };

    const getDetails = () => {
        setLoading(true);
        let payloads = {};
        let token= localStorage.getItem("token");
        let accessToken= localStorage.getItem("accessToken");
        payloads.idToken = 'Bearer ' + token;
        payloads.accessToken = accessToken;
        if (localStorage.getItem("enterpriseId")) {
            payloads.enterpriseId = localStorage.getItem("enterpriseId");
        }
        AccessService.getEnterpriseData(payloads).then((res) => {
            let enterpriseUser = res.data.responsePayload[0];
            setPlatformTheme(enterpriseUser.theme);
            setEnterprisename(enterpriseUser.establishmentName);
            localStorage.setItem("enterprise_name", enterpriseUser.establishmentName);
            const firstName = enterpriseUser.firstName ?? '';
            const lastName = enterpriseUser.lastName ?? '';
            localStorage.setItem("user_name", `${firstName} ${lastName}`);
            localStorage.setItem(
                "profile_image",
                enterpriseUser.logoFileSignedUrl
                ? enterpriseUser.logoFileSignedUrl
                : "https://daas-prog-test.s3.amazonaws.com/default-logo.png"
            );
            localStorage.setItem("email", enterpriseUser.emailAddress);
            localStorage.setItem("userType", enterpriseUser.userType);
            localStorage.setItem("profileTitle", enterpriseUser.profileTitle);
            if (enterpriseUser.userType === "T" || enterpriseUser.userType === "R" || enterpriseUser.userType === "A" || enterpriseUser.userType === "Z") {
                localStorage.setItem("is_admin", true);
            } else {
                localStorage.setItem("is_admin", false);
            }
            setTimeout(()=>{
                document.documentElement.style.setProperty("--loader-bg", 'rgba(0, 0, 0, 0.2)', "important");
                setLoading(false);
            }, 2000);
        });
    };

    const handleOpenFeedBack = () => {
        setOpenFeedback(true);
      };
      const handleFeebackModalClose = () => {
        setOpenFeedback(false);
      };
    return (
        <>
        {loading && <Loader />}
        {isMobile ? (
            <Grid item lg={12} md={12} xs={12}>
                <header className="top-header" style={{ paddingBottom: "62px" }}>
                    <div className="left-header">
                        <img src="/beanbag-assets/img/beanbag-dashboard.svg" height="60px"/>
                    </div>
                    <div className="right-header" style={{ alignItems: "center", display: "flex", marginTop: "12px" }}>
                            <Box
                                tabIndex={0}
                                onKeyPress={handleOpenFeedBack}
                                className="ml-10 m-r-10  cursor-hover headerIcon"
                                onClick={handleOpenFeedBack}
                            >
                                <Tooltip title={<Typography fontSize={11}>Feedback</Typography>} placement="bottom">
                                    <SupportIcon
                                    style={{ width: "50px" }}
                                    aria-label="Message icon"
                                    className="contrasts"
                                    />
                                </Tooltip>
                        </Box>
                            <img
                                src={(localStorage.getItem("profile_image") != null && localStorage.getItem("profile_image") != "null") ? localStorage.getItem("profile_image") : "https://daas-prog-test.s3.amazonaws.com/default-logo.png"}
                                style={{ height: "40px", width: "40px", borderRadius: "50%", border: "1px solid rgba(105, 104, 113, 0.4)" }}
                                alt='Profile Image'
                            />
                        <Grid item lg={2} md={2} xs={2} className="align-item-right">
                            <Box
                            className="align-between"
                            // sx={{ px: 2 }}
                            >
                            <Box className="d-flex align-center">
                                {/*  <SearchIcon className="gray7 f-25" />*/}
                                <Button
                                variant="text"
                                onClick={handleMenuBar}
                                role="button"
                                aria-label="Menu icon"
                                disableRipple="true"
                                >
                                {" "}
                                <MenuIcon
                                    width="25px"
                                    height="25px"
                                    aria-label="Menu icon"
                                    className="gray9"
                                />
                                </Button>
                            </Box>
                            </Box>
                        </Grid>
                        <Menu
                        className="navbar-responsive sidebar-dropdown"
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        disableScrollLock={true}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        KeepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        open={open}
                        >
                        <Box className="navbar-responsive">
                            <Box sx={{ textAlign: "right" }} className="icon-button">
                            <CloseIcon
                                className="font-25"
                                onClick={handleCloseMenu}
                                onKeyPress={handleCloseMenu}
                                tabIndex={0}
                                aria-label="Close icon"
                            />
                            </Box>
                        </Box>
                        <Grid item className="add-checklist-align">
                            <>
                            <Grid
                                className="mt-70 sideBar-icon cursor-hover"
                                onClick={goToDashboard}
                                onKeyPress={goToDashboard}
                                aria-label="Dashboard icon"
                                tabIndex={0}
                            >
                                {URLPath === "/admin/dashboard" ? (
                                <Typography className="bg-white sideIcon-align">
                                    <DashboardIcon
                                    width="16px"
                                    height="16px"
                                    aria-label="Dashboard icon"
                                    className="svg-icons"
                                    />
                                    <span className="f-18 primary-shadow-color pl-10">
                                        Dashboard
                                    </span>
                                </Typography>
                                ) : (
                                    <Typography className="sideIcon-align">
                                    <DashboardIcon
                                        width="16px"
                                        height="16px"
                                        aria-label="Dashboard icon"
                                    />
                                    <span className="f-18 gray7 pl-10">
                                        Dashboard
                                    </span>
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                className="mt-45 sideBar-icon cursor-hover"
                                onClick={goToJobs}
                                onKeyPress={goToJobs}
                                aria-label="Jobs icon"
                            >
                                {(URLPath === "/admin/jobs" )? (
                                    <Typography className="bg-white sideIcon-align">
                                    <JoblistingIcon
                                        width="16px"
                                        height="16px"
                                        aria-label="Jobs icon"
                                        className="svg-icons"
                                    />
                                    <span className="f-18 primary-shadow-color pl-10">
                                        Job Listing
                                    </span>
                                    </Typography>
                                ) : (
                                    <Typography className="sideIcon-align">
                                    <JoblistingIcon
                                        width="16px"
                                        height="16px"
                                        aria-label="Jobs icon"
                                    />
                                    <span className="f-18 gray7 pl-10">
                                        Job Listing
                                    </span>
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                className="mt-45 sideBar-icon cursor-hover"
                                onClick={goToReports}
                                onKeyPress={goToReports}
                                aria-label="Reports icon"
                            >
                                {(URLPath === "/admin/job-board-performance" )? (
                                    <Typography className="bg-white sideIcon-align">
                                    <ReportsIcon
                                        width="16px"
                                        height="16px"
                                        aria-label="Reports icon"
                                        className="svg-icons"
                                    />
                                    <span className="f-18 primary-shadow-color pl-10">
                                        Reports
                                    </span>
                                    </Typography>
                                ) : (
                                    <Typography className="sideIcon-align">
                                    <ReportsIcon
                                        width="16px"
                                        height="16px"
                                        aria-label="Reports icon"
                                    />
                                    <span className="f-18 gray7 pl-10">
                                        Reports
                                    </span>
                                    </Typography>
                                )}
                            </Grid>
                            <Grid>
                                <Typography className="mt-45 sideBar-icon cursor-hover">
                                    <nav className="nav">
                                        <ul className="nav__menu " tabIndex="0">
                                            <li className={`nav__menu-item justify-center ${(URLPath === '/admin/job-boards-accesses' || URLPath === '/admin/account-setting') ? "active-list" : ""}`}>
                                            {
                                                (URLPath === '/admin/job-boards-accesses' || URLPath === '/admin/account-setting') ?
                                                <a tabIndex="-1" style={{ textDecoration: 'none' }} className="outline-none">
                                                    <Typography className=" pr-10 justify-center outline-none">
                                                    <SettingsIcon width="16px" height="16px" className="fill-svg-icons"></SettingsIcon>
                                                        <span className="f-18 primary-shadow-color pl-10 outline-none">
                                                            Settings
                                                        </span>
                                                    </Typography>
                                                </a> :
                                                <a tabIndex="-1" style={{ textDecoration: 'none' }} className="outline-none">
                                                    <Typography className=" pr-10 justify-center outline-none">
                                                    <SettingsIcon width="16px" height="16px"></SettingsIcon>
                                                        <span className="f-18 gray7 pl-10 outline-none">
                                                            Settings
                                                        </span>
                                                    </Typography>
                                                </a>
                                            }

                                            <ul className="navBar__submenu mt-25 f-14" tabIndex="0">
                                                <li onClick={goToJobsBoardsAccess} className="nav__submenu-item pb-15" style={{ paddingTop: '17px' }}  >
                                                <a className="pl-13" style={{ textDecoration: 'none' }}>
                                                    Configure Job Board
                                                </a>
                                                </li>
                                                <li onClick={goToAccountSetting} className="nav__submenu-item pb-15" style={{ paddingTop: '17px' }} >
                                                <a className="pl-13" style={{ textDecoration: 'none' }}>
                                                    Account Settings
                                                </a>
                                                </li>
                                                <li onClick={() => getApiDocument()} className="nav__submenu-item pb-15" style={{ paddingTop: '17px' }} >
                                                <a className="pl-13" style={{ textDecoration: 'none' }}>
                                                    API Document
                                                </a>
                                                </li>
                                            </ul>

                                            </li>
                                        </ul>
                                        </nav>
                                    {/* </Tooltip> */}

                                </Typography>
                            </Grid>


                            </>
                        </Grid>
                        </Menu>
                    </div>
                    {/* <Button
                            id="basic-button4"
                            // aria-controls="basic-menu2"
                            aria-controls={open2 ? "account-menu" : undefined}
                            aria-expanded={open2 ? "true" : undefined}
                            aria-haspopup="true"
                            className="dropdown-icon dropdown-arrow text-transform-none cursor-hover position-issue"
                            onClick={handleClick2}
                            role="button"
                            aria-label="Profile picture"
                            disableRipple={true}
                            >
                                <ArrowDropDownIcon className="f-20 c-white ml-10 mb-10 inputcontrasts" style={{ marginTop: "44px" }} aria-label="Dropdown icon" />

                        </Button> */}
                        <Menu
                            anchorEl={anchorE2}
                            open={open2}
                            onClose={handleClose2}
                            disableScrollLock={true}
                            MenuListProps={{
                                "aria-labelledby": "basic-button4",
                            }}
                            KeepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                            <MenuItem
                                onClick={() => Logout()}
                                className="danger-color icon-button"
                                aria-label="Logout icon"
                                tabIndex="0"
                            >
                                <span style={{ marginLeft: "-7px" }}>
                                <img
                                    className="mr-6 icon-button"
                                    src={LogoutIcon}
                                    alt="logout"
                                    width="100px"
                                    aria-label="logout"
                                    tabIndex="0"
                                    style={{ width: "20px", paddingRight: "3px" }}
                                />
                                Logout
                                </span>
                            </MenuItem>
                        </Menu>
                </header>
            </Grid>
        ): (
            <header className="top-header" style={{ paddingBottom: "62px" }}>
                <div className="left-header">
                    <img src="/beanbag-assets/img/beanbag-dashboard.svg" height="60px"/>
                </div>
                <div className="right-header" style={{ alignItems: "center", display: "flex", marginTop: "8px" }}>
                        <Box
                            tabIndex={0}
                            onKeyPress={handleOpenFeedBack}
                            className="ml-10 m-r-10 mt-8 cursor-hover headerIcon"
                            onClick={handleOpenFeedBack}
                        >
                            <Tooltip title={<Typography fontSize={11}>Feedback</Typography>} placement="bottom">
                                <SupportIcon
                                style={{ width: "50px" }}
                                aria-label="Message icon"
                                className="contrasts"
                                />
                            </Tooltip>
                      </Box>
                        <img
                            src={(localStorage.getItem("profile_image") != null && localStorage.getItem("profile_image") != "null") ? localStorage.getItem("profile_image") : "https://daas-prog-test.s3.amazonaws.com/default-logo.png"}
                            style={{ height: "40px", width: "40px", borderRadius: "50%", border: "1px solid rgba(105, 104, 113, 0.4)" }}
                            alt='Profile Image'
                        />
                    <Tooltip
                        title={
                            <div style={{ fontSize: "12px" }}>
                                {enterprisename}
                                <br />
                                {localStorage.getItem("user_name")
                                    ? localStorage.getItem("user_name")
                                    : localStorage
                                        .getItem("email")
                                        ?.split("@")[0]}{" "}
                                <br />
                                {localStorage.getItem("profileTitle") && localStorage.getItem("profileTitle") != 'null' && (
                                    <div>
                                        {localStorage.getItem("profileTitle")}
                                        <br/>
                                    </div>
                                )}
                                {getUserType(localStorage.getItem("userType"))}
                                <br />
                                {localStorage.getItem("email") && localStorage.getItem("email") != 'null' && (
                                    <div>
                                        {localStorage.getItem("email")}
                                    </div>
                                )}
                            </div>
                        }
                        placement="bottom"
                    >
                        <a
                            className="cursor-normal"
                            to="/signin"
                        >
                            <Typography
                                variant="h5"
                                className="client-name small-text-font font-weight-500 gray9 textEllipse enterprise-name-width align-left cursor-hover m-r-25"
                                style={{ paddingTop: "0px", paddingLeft: "10px" }}
                            >
                                {enterprisename}
                            </Typography>
                        </a>
                    </Tooltip>
                </div>
                {/* <Button
                        id="basic-button4"
                        // aria-controls="basic-menu2"
                        aria-controls={open2 ? "account-menu" : undefined}
                        aria-expanded={open2 ? "true" : undefined}
                        aria-haspopup="true"
                        className="dropdown-icon dropdown-arrow text-transform-none cursor-hover position-issue"
                        onClick={handleClick2}
                        role="button"
                        aria-label="Profile picture"
                        disableRipple={true}
                        >
                            <ArrowDropDownIcon className="f-20 c-white ml-10 mb-10 inputcontrasts" style={{ marginTop: "44px" }} aria-label="Dropdown icon" />

                    </Button> */}
                    <Menu
                        anchorEl={anchorE2}
                        open={open2}
                        onClose={handleClose2}
                        disableScrollLock={true}
                        MenuListProps={{
                            "aria-labelledby": "basic-button4",
                        }}
                        KeepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                        <MenuItem
                            onClick={() => Logout()}
                            className="danger-color icon-button"
                            aria-label="Logout icon"
                            tabIndex="0"
                        >
                            <span style={{ marginLeft: "-7px" }}>
                            <img
                                className="mr-6 icon-button"
                                src={LogoutIcon}
                                alt="logout"
                                width="100px"
                                aria-label="logout"
                                tabIndex="0"
                                style={{ width: "20px", paddingRight: "3px" }}
                            />
                            Logout
                            </span>
                        </MenuItem>
                    </Menu>
            </header>
        )}
            <FeedBackModal
                open={openfeedback}
                onClose={handleFeebackModalClose}
            />
        </>
    );
}
